import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            textAlign: "left",
        },
        backIcon: {
            color: '#fff', 
            width: 20,
            height: 20,           
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: '4.2%',
            fontSize: 14,
        },
        img: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            height: 200,
            maxWidth: "100%",
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        title1: {
            marginBottom: 0,
            marginLeft: 0,
            color: '#fff',
            position:'absolute',
            bottom:8, 
            left:15,
            fontSize: 16,
            fontWeight: 600,
        },
        title2: {
            marginTop: 12,
            marginLeft: 17,
            fontSize: 14,
            fontWeight: 600,
        },
        editIcon: {
            
            marginLeft: 'auto',
            marginRight: 17,
            marginTop: 12,
            width: 18,
            height: 18,
            color: '#5B9DF3',
        },
        icon: {
            marginLeft: 16,
            marginTop: 10,
            width: 16,
            height: 16,
        },
        title3: {
            wordWrap: 'break-word',
            marginTop: 8,
            marginLeft: 11,
            marginRight: 16,
            fontSize: 12,
            lineSpacing: 0.24,
            color: '#2A312F',
            
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center'
        },
        zeroImageCard: {
            width: '100%',
            height: 182,
            background: 'transparent linear-gradient(180deg, #00000000 0%, #0000004D 100%) 0% 0% no-repeat padding-box'
        },
        imgCard: {
            height: 70,
            width: 200,
            margin: 'auto',
            display: 'block',
            paddingTop: 20
        },
        textThumb: {
            width: 224,
            height: 'auto',
            fontSize: 12,
            color: '#2A312F',
            fontWeight: 500,
            margin: 'auto'
        },
    }),
);