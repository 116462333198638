import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        title1: {
            fontSize: 14,
            color: '#2a312f'
        },
        icon: {
            width: 17,
            height: 17,
            marginLeft: 4,
        },
        dialogbox: {
            
        },
    }),
);