import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Height } from "@material-ui/icons";
import { autoPlay } from "react-swipeable-views-utils";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: '#fff',
        },
        backIcon: {
            color: '#fff',
            fontSize: 30,
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        productName: {
            fontSize: 15,
            marginLeft: 8,
        },
        infoText1: {
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 16,
            marginRight: 16,
            maxLines: 2,
            fontSize: 14,
            fontWeight: 400,
            color: '#2A312F',
        },
        packSizeText: {
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 8,
            position: "relative",
            fontSize: 12,
            fontWeight: 10,
            color: '#7E8184',
        },
        listItemText: {
            fontSize: 12,
            fontWeight: 10,
        },
        listItemTextDisabled: {
            fontSize: 12,
            fontWeight: 10,
            color: "#7E8184",
        },
        stockText: {
            fontSize: 14,
            color: "#7E8184",
        },
        stockText1: {
            fontSize: 14,
            color: "#1CCB2D",
        },
        stockText2: {
            fontSize: 14,
            color: "#E6582D",
        },
        sellingPriceText: {
            marginLeft: 'auto',
            marginTop: 8,
            marginBottom: 8,
            textAlign: 'right',
            marginRight: 16,
            position: "relative",
            fontSize: 12,
            fontWeight: 10,
            color: '#7e8184',
        },
        contentMenu: {
            display: "flex",
            width: "100%",
            height: 28,
            borderBottom: '1px solid #ccc',
        },
        companyName: {
            fontSize: 14,
            color: '#2A312F',
            marginTop: -4,
            marginLeft: 8,
        },
        bottomTextButton: {
            display: "flex",
            width: "100%",
            height: 56,
        },
        bottomText: {
            marginTop: 6,
            marginLeft: 16,
            maxLines: 2,
            fontSize: 14,
        },
        supportButton: {
            width: 124,
            height: 40,
            marginLeft: 21,
            marginRight: 17,
            borderRadius: 30,
            marginTop: 6,
            textTransform: 'capitalize',
            fontSize: 14,
            backgroundColor: '#ffffff',
            color: '#418EF2',
            boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 1px 3px 2px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
        },
        textField: {
            background: '#F3F3F3',
        },
        evenItem: {
            backgroundColor: '#F3F3F3',
        },
        oddItem: {
            backgroundColor: '#FFFFFF',
        },
        nextButton: {
            width: "90%",
            height: 48,
            margin: "auto",
            display: "flex",
            textTransform: 'capitalize',
            background: '#0E4BB4',
            fontSize: 14,
            boxShadow: 'none',
        },
        bottomNavigation: {
            marginTop: 10,
            position: 'fixed',
            maxWidth: 720,
            margin: 'auto',
            left: 0,
            right: 0,
            bottom: 0,
            height: 80,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        bottomTextButton1: {
            display: "inline",
            width: "100%",
            height: 56,
        },
        addNewPackButton: {
            width: "auto",
            height: 40,
            marginRight: 16,
            marginTop: 16,
            display: "flex",
            background: '#418EF2',
            fontSize: 13,
            boxShadow: 'none',
            float: "right",
            color: "#FFFFFF",
            minWidth: 154,
            textTransform: "none"
        },
        wrapperBox: {
            width: '95%',
            height: 104,
            display: "inline"
        },
    })
);
