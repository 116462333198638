import React from 'react'
import Lottie from 'react-lottie';
import  animationData from './loading.json'
 
export default class AppLoadingComponent extends React.Component<{}> {
 
  constructor(props:any) {
    super(props);
    
  }
 
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
 
    return <div style = {{outline: 'none', marginTop: '50%'}}>
      <Lottie options={defaultOptions}
              height={50}
              width={50}
              />
      
    </div>
  }
}