import { DelarAction } from "../action/delar.action";
import { AndroidConnerctor } from "../android.connecter";
import { DelarPhotoType } from "../enum/delar-photo-type.enum";
import { Media } from "../model/media.model";
import { DelarService } from "../service/delar.service";
import { DelarPhotoDeleteDto } from "../service/dto/delar.dto";
import { StorageService } from "../service/storage.service";
import { DelarStore } from "../store/delar.store";

const saveData = (delarId: string, action: any, errorAction: any) => {
    const delarDto = DelarStore.getDelarDto()
    if (delarDto) {
        DelarService.updateDelarById(delarId, delarDto).then(res => {
            action()
        }).catch(err => {
            errorAction()
        })
    }
}

const openDelarInfo = (delarID: string, afterLoad: any) => {
    DelarService.getDelarById(delarID).then((res) => {
        AndroidConnerctor.updateStatus(res.data.status.value)
        DelarStore.upadteDelarInfo(res.data)
        if (afterLoad) {
            afterLoad()
        }
    }).catch(err => {
        console.log(err)
    })
}

const shopImagesUpload = (delarId: string, shopPhotos: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    shopPhotos.map(shopPhoto => {
        promises.push(StorageService.uploadShopImage(shopPhoto.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateShopImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const pesticideImagesUpload = (delarId: string, pesticideImages: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    pesticideImages.map(image => {
        promises.push(StorageService.uploadDocument(image.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateNewPesticideImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const fertiliserImagesUpload = (delarId: string, fertiliserImages: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    fertiliserImages.map(image => {
        promises.push(StorageService.uploadDocument(image.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateNewFertiliserImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const seedImagesUpload = (delarId: string, seedImages: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    seedImages.map(image => {
        promises.push(StorageService.uploadDocument(image.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateNewSeedImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const reInitDelarDto = (delarId: string, action: any, errorAction: any) => {
    DelarService.getDelarById(delarId).then(res => {
        DelarStore.reInitDelarDto(res.data)
        action()
    }).catch(err => {
        errorAction()
    })
}

const getDelarNotifications = () => {
    DelarService.getDelarNotifications(DelarStore.getNotificationDto()).then(res => {
        DelarStore.upadteNotificationList(res.data)
    }).catch(err => {
        console.log(err)
    })
}

const deleteShopPhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_SHOP_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        DelarStore.deleteShopPhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}

const deletePesticidePhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_NEW_PESTICIDE_LISCENCE_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        DelarStore.deleteNewPesticidePhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}


const deleteFertiliserPhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_NEW_FERTILIZER_LISCENCE_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        DelarStore.deleteNewFertiliserPhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}


const deleteSeedPhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_NEW_SEED_LISCENCE_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        DelarStore.deleteNewSeedPhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}


export const DelarActor = (action: any, data: any): any => {
    switch (action.type) {
        case DelarAction.UPDATE_NAME:
            DelarStore.updateName(data.name)
            break;
        case DelarAction.UPDATE_ALTERNATE_NUMBER:
            DelarStore.updateAlternatePhone(data.alternatePhone)
            break;
        case DelarAction.UPDATE_INTRO:
            DelarStore.updateIntro(data.intro)
            break;
        case DelarAction.UPDATE_SHOP_NAME:
            DelarStore.updateShopName(data.shopName)
            break;
        case DelarAction.UPDATE_GST_NUMBER:
            DelarStore.updateGSTNumber(data.gstNumber)
            break;
        case DelarAction.UPDATE_STREET_ADDRESS:
            DelarStore.updateStreetAddress(data.streetLine)
            break;
        case DelarAction.UPDATE_LANDMARK:
            DelarStore.updateLandmark(data.landMark)
            break;
        case DelarAction.UPDATE_STATE:
            DelarStore.updateState(data.state)
            break;
        case DelarAction.UPDATE_DISTRICT:
            DelarStore.updateDistrict(data.district)
            break;
        case DelarAction.UPDATE_SUB_DISTRICT:
            DelarStore.updateSubDistrict(data.subDistrict)
            break;
        case DelarAction.UPDATE_VILLAGE:
            DelarStore.updateVillage(data.village)
            break;
        case DelarAction.UPDATE_PIN_CODE:
            DelarStore.updatePinCode(data.pinCode)
            break;
        case DelarAction.UPDATE_PESTICIDE_LICENCE:
            DelarStore.updateNewPesticideLicenseNumber(data.licenseNumber)
            break;
        case DelarAction.UPDATE_FERTILIZER_LICENCE:
            DelarStore.updateNewFertilizerLicenseNumber(data.licenseNumber)
            break;
        case DelarAction.UPDATE_SEED_LICENCE:
            DelarStore.updateNewSeedLicenseNumber(data.licenseNumber)
            break;
        case DelarAction.UPDATE_STATUS:
            DelarStore.updateStatus(data.value, data.msg)
            break;
        case DelarAction.RE_INIT_DELAR:
            reInitDelarDto(data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.SAVE_DATA:
            saveData(data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.GET_DELAR_INFO:
            openDelarInfo(data.delarID, data.action)
            break;
        case DelarAction.UPLOAD_SHOP_IMAGES:
            shopImagesUpload(data.delarId, data.shopPhotos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.UPLOAD_PESTICIDE_IMAGES:
            pesticideImagesUpload(data.delarId, data.photos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.UPLOAD_FERTILISER_IMAGES:
            fertiliserImagesUpload(data.delarId, data.photos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.UPLOAD_SEED_IMAGES:
            seedImagesUpload(data.delarId, data.photos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.VALIDATE_PERSONAL_DETAIL_PAGE_ERROR:
            DelarStore.checkErrorPersonalDetailPage()
            break;
        case DelarAction.VALIDATE_BUSSINESS_DETAIL_PAGE_ERROR:
            DelarStore.checkErrorBussinessDetailPage()
            break;
        case DelarAction.VALIDATE_DOCUMENT_PROOF_DETAIL_PAGE_ERROR:
            DelarStore.checkErrorDocumentProoofPage()
            break;
        case DelarAction.UPDATE_SHOP_PHOTOS_ERROR:
            DelarStore.updateShopPhotosError(data.flag)
            break;
        case DelarAction.UPDATE_SEED_IMAGES_ERROR:
            DelarStore.updateSeedImagesError(data.flag)
            break;
        case DelarAction.UPDATE_FERTILISER_IMAGES_ERROR:
            DelarStore.updateFertiliserImagesError(data.flag)
            break;
        case DelarAction.UPDATE_PESTICIDE_IMAGES_ERROR:
            DelarStore.updatePesticideImagesError(data.flag)
            break;
        case DelarAction.DELETE_SHOP_PHOTO:
            deleteShopPhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.DELETE_PESTICIDE_PHOTO:
            deletePesticidePhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.DELETE_FERTILISER_PHOTO:
            deleteFertiliserPhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.DELETE_SEED_PHOTO:
            deleteSeedPhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.GET_NOTIFICATION_FEED:
            getDelarNotifications()
            break;
        default:
            break;
    }
}