import { useLocation, useNavigate } from "react-router-dom"

export const useRouteManager = () => {
    const navigate = useNavigate()

    const clearHistory = () => {
        
    }
    const goBack = () => {
        navigate(-1)
    }
    const openCamera = () => {
        navigate('/camera')
    }
    const openAddVillage = () => {
        navigate('/on-board/add-village')
    }
    const openRegister = () => {
        navigate(`/on-board/register`)
    }

    const openOTPValidation = () => {
        navigate(`/on-board/validate-otp`)
    }

    const openIntro = () => {
        navigate(`/on-board/intro`)
    }

    const openDelarDetail = () => {
        navigate(`/on-board/detail`)
    }

    const openDelarEditDetailPage = () => {
        navigate(`/on-board/edit-detail`)
    }

    const openHome = () => {
        navigate(`/home`)
    }

    const openProduct = () => {
        navigate(`/product`)
    }

    const openProductPackSizePage = (productId: string) => {
        navigate(`/product/${productId}/pack-size`)
    }

    const openMultipleProductSelectPage = () => {
        navigate(`/product/add/multi-pack-size`)
    }

    const openProductDetail = (productId: string) => {
        navigate(`/product/${productId}`)
    }

    const openAddProduct = () => {
        navigate(`/product/add`)
    }

    const openNewProductReq = () => {
        navigate(`/product/new-request`)
    }

    const openProfile = () => {
        navigate(`/profile`)
    }

    const openNotification = () => {
        navigate(`/notification`)
    }

    const openSetting = () => {
        navigate(`/settings`)
    }

    const openAboutUs = () => {
        navigate(`/about-us`)
    }

    const openTermsPolicies = () => {
        navigate(`/terms-policies`)
    }
    const openEditVillage = () => {
        navigate(`/edit-village`)
    }

    const openEditPersonalInfo = () => {
        navigate(`/profile/edit-profile`)
    }

    const openEditShopDetail = () => {
        navigate(`/profile/edit-profile/edit-shop`)
    }

    const openOrderTab = () => {
        navigate(`/order`)
    }
    const openEditAddress = () => {
        navigate(`/profile/edit-profile/edit-address`)
    }
    const openEditPesticideLicense = () => {
        navigate(`/profile/edit-profile/edit-pesticide-license`)
    }
    const openEditFertilizerLicense = () => {
        navigate(`/profile/edit-profile/edit-fertilizer-license`)
    }
    const openEditSeedLicense = () => {
        navigate(`/profile/edit-profile/edit-seed-license`)
    }

    const openShipmentDetail = (shipmentId: string, shipmentStatus: number) => {
        navigate(`/shipment/${shipmentId}/${shipmentStatus}`)
    }

    const openReviewPending = () => {
        navigate(`/review-pending`)
    }

    const openAddVillageProfile = () => {
        navigate(`/add-village`)
    }

    const openEditInfo = () => {
        navigate(`/profile/edit-info`)
    }

    return {
        clearHistory: clearHistory,
        openRegister: openRegister,
        openOTPValidation: openOTPValidation,
        openIntro: openIntro,
        openDelarDetail: openDelarDetail,
        openDelarEditDetailPage: openDelarEditDetailPage,
        openHome: openHome,
        openProduct: openProduct,
        openProductDetail: openProductDetail,
        openAddProduct: openAddProduct,
        openNewProductReq: openNewProductReq,
        openProfile: openProfile,
        openNotification: openNotification,
        openSetting: openSetting,
        openAboutUs: openAboutUs,
        openTermsPolicies: openTermsPolicies,
        openCamera: openCamera,
        openMultipleProductSelectPage: openMultipleProductSelectPage,
        openAddVillage: openAddVillage,
        openEditVillage: openEditVillage,
        openProductPackSizePage: openProductPackSizePage,
        openOrderTab: openOrderTab,
        goBack: goBack,
        openEditPersonalInfo: openEditPersonalInfo,
        openEditShopDetail: openEditShopDetail,
        openEditAddress: openEditAddress,
        openEditPesticideLicense: openEditPesticideLicense,
        openEditFertilizerLicense: openEditFertilizerLicense,
        openEditSeedLicense: openEditSeedLicense,
        openShipmentDetail: openShipmentDetail,
        openReviewPending: openReviewPending,
        openAddVillageProfile: openAddVillageProfile,
        openEditInfo: openEditInfo
    }
}