import { OrderAction } from "../action/order.action";
import { UpdateOrderStatusDto } from "../service/dto/order.dto";
import { OrderService } from "../service/order.service";
import { OrderStore } from "../store/order.store";

const getOrderList = (afterLoad?: any) => {
    OrderService.getOrderList(OrderStore.getOrderDto()).then((res) => {
        OrderStore.upadteOrderList(res.data.data)
        OrderStore.upadteOrderDto(res.data.nextApiReq)
        if (res.data.data.length < OrderStore.getOrderDto().limit) {
            afterLoad?.setHasMore(false)
        } else {
            afterLoad?.setHasMore(true)
        }
        afterLoad?.setLoading(false)
    }).catch(err => {
        afterLoad?.setLoading(false)
        console.log(err)
    })
}

const getNexrOrderList = (afterLoad?: any) => {
    OrderService.getOrderList(OrderStore.getOrderDto()).then((res) => {
        OrderStore.upadteOrderList(res.data.data)
        OrderStore.upadteOrderDto(res.data.nextApiReq)
        if (res.data.data.length < OrderStore.getOrderDto().limit) {
            afterLoad?.setHasMore(false)
        } else {
            afterLoad?.setHasMore(true)
        }
        afterLoad?.setLoading(false)
    }).catch(err => {
        afterLoad?.setLoading(false)
        console.log(err)
    })
}

const getShipmentDetail = (shipmentId: string, afterShipmentFetch: any) => {
    OrderService.getShipmentDetail(shipmentId).then((res) => {
        OrderStore.upadteShipmentDetail(res.data)
        if (afterShipmentFetch) {
            afterShipmentFetch()
        }
    }).catch(err => {
        console.log(err)
    })
}

const updateOrderProductStatus = (data: Array<UpdateOrderStatusDto>, afterPackUpdate: any) => {
    OrderService.updateProductStatus(data).then(res => {
        afterPackUpdate()
    }).catch(err => {
        afterPackUpdate()
    })
}

export const OrderActor = (action: any, data: any) => {
    switch (action.type) {
        case OrderAction.GET_LIST:
            OrderStore.reSetOrderDtoAndOrderList()
            OrderStore.upadteOrderDtoStatusList(data.statusList)
            getOrderList(data.action)
            break;
        case OrderAction.GET_NEXT_PAGE_LIST:
            getNexrOrderList(data.action)
            break;
        case OrderAction.GET_SHIPMENT_DETAIL:
            OrderStore.reSetOrderDtoAndOrderList()
            getShipmentDetail(data.shipmentId, data.afterShipmentFetch)
            break;
        case OrderAction.UPDATE_ORDER_PRODUCT_STATUS:
            updateOrderProductStatus(data.updateOrderDtos, data.afterPackUpdate)
            break;
        default:
            break;
    }
}