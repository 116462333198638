import { AppBar, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Modal, Toolbar, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useStyles } from "./edit-deliverable-village.style"
import { SecurityManager } from "../../../../../security/security.manager"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LoadingComponent from "../../../../common/loading/loading.component"
import DeleteIcon from '@material-ui/icons/Delete'
import { AddressAction } from "../../../../../action/address.action"
import { AddressActor } from "../../../../../actor/address.actor"
import { AddressStore } from "../../../../../store/address.store"
import { useObservable } from "../../../../../useSubscriber"
import AddIcon from '@material-ui/icons/Add';
import { DeleteVillageDto } from "../../../../../service/dto/delete-village.dto"
import DialogAlertComponent from "../../../../common/dialog-alert/dialog-alert.component"
import { useTranslation } from "react-i18next"
import { VillageDetail } from "../../../../../service/response/village.response"
import { useRouteManager } from "../../../../../router/route.manager"

export const EditDeliverableVillage = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const routeManager = useRouteManager()


    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const [deletedetails, setDeleteDetails] = useState({} as any);

    const [addressInfo, _] = useObservable(AddressStore.getAddedAddressList() as any, AddressStore.getAddedAddressListObservable())

    useEffect(() => {
        setLoading(true)
        AddressActor({ "type": AddressAction.GET_ADDED_VILLAGE_LIST }, { "delarId": SecurityManager.getUid(), "action": () => { setLoading(false); } })
    }, [])

    const action = () => {
        setOpenDialog(false)
        setLoading(true)
        AddressActor({ "type": AddressAction.GET_ADDED_VILLAGE_LIST }, { "delarId": SecurityManager.getUid(), "action": () => { setLoading(false); } })
    }

    const confirmDelete = () => {
        const data = {
            "deleteDto": {
                "delarId": deletedetails.delarId,
                "villageId": deletedetails.village.id,
            } as DeleteVillageDto,
            "action": action
        }
        AddressActor({ "type": AddressAction.DELETE_ADDED_VILLAGE }, data)
    }

    return (
        <div className={classes.root}>
            <AppBar position="sticky" style={{ boxShadow: 'none' }}>
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick={() => { routeManager.goBack() }} />
                    <Typography variant="h6" className={classes.title}>
                        {t("edit_delivery_villages")}
                    </Typography>

                </Toolbar>
            </AppBar>
            {!loading &&
                <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 40, marginTop: -8, }}>
                    {addressInfo.map((address: VillageDetail, index: number) => {
                        const labelId = `${address.id}`
                        return (
                            <>
                                <ListItem key={index} button className={(index % 2 === 0) ? classes.evenItem : classes.oddItem}>

                                    <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.villageName}>
                                        {address.village.name}, {address.subDistrict.name}, {address.district.name}, {address.state.name}
                                    </Typography>}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => { setDeleteDetails(address); setOpenDialog(true) }}>
                                            <DeleteIcon style={{ fontSize: 20, color: '#E6582D' }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>

                                </ListItem>
                            </>
                        );
                    })}
                    <Divider />
                    <ListItem key={addressInfo.length} button disableGutters
                        onClick={() => routeManager.openAddVillageProfile()}
                    >

                        <ListItemText disableTypography primary={<Typography variant="body2" className={classes.villageName} style={{ textAlign: 'center' }}>
                            <IconButton style={{ marginRight: -8 }}><AddIcon style={{ fontSize: 17 }} /></IconButton>{t("edit_add_new_villages")}
                        </Typography>} />


                    </ListItem>
                    <Divider />
                </List>
            }
            <Modal open={loading} className={classes.modal}><> <LoadingComponent /></></Modal>
            <DialogAlertComponent
                open={openDialog}
                handleClose={() => { setOpenDialog(false) }}
                heading={t("common_delete_village")}
                content={t("edit_delete_village_before")
                    .concat(deletedetails?.village?.name)
                    .concat(", ").concat(deletedetails?.district?.name)
                    .concat(t("edit_delete_village_after"))
                }
                disagree={t("cancel")}
                agree={t("delete")}
                handleAgree={() => { confirmDelete(); setOpenDialog(false) }}
            />
        </div>
    )
}