import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        mainView: {
            maxWidth: 720,
            zIndex: 0,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            textAlign: "left",
        },
        appBar : {
            zIndex: 0.1,
            boxShadow: 'none',
        },
        title: {
            flexGrow: 1,
            align: "left",
            fontSize: 14,
            marginLeft: -8,
        },
        notificationButton: {
        },
        list: {
            width: '80%',
            minWidth: '230px'
        },
        fullList: {
            width: 'auto',
        },
        bottomNavigation: {
            width: '100%',
            maxWidth: 720,
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        profileIcon: {
            width: 25,
            height: 25,
        },
        bottomIcon: {
            height: 24,
            width: 24,
            margin: 4,
            textAlign: 'center'
        },
        menuIcon: {
            height: 24,
            width: 24,
            margin: 4,
            marginBottom: 16,
            textAlign: 'center'
        }
    }),
);