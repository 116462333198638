import { BottomNavigation, Button } from "@material-ui/core"
import { useState } from "react"
import { useStyles } from './deliverable-village-add.style'
import { Village } from "../../../../service/response/village.response"
import { useTranslation } from "react-i18next"
import { SecurityManager } from "../../../../security/security.manager"
import { useRouteManager } from "../../../../router/route.manager"
import { AddVillageDto } from "../../../../service/dto/add-village.dto"
import { CommonAddVillageComponent } from "../../../common/add-village/add-village-common.component"
import { AddressActor } from "../../../../actor/address.actor"
import { AddressAction } from "../../../../action/address.action"
import { toast } from "react-toastify"
import { DELAR_VILLAGE_UPPER_CAP } from "../../../../constant/base.constant"
import { RegistrationAction } from "../../../../action/registration.action"
import { RegistrationActor } from "../../../../actor/registration.actor"

export const OnboardAddVillageComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const [loading, setLoading] = useState(false)
    const [selectedVillageData, setSelectedVillageData] = useState<Array<Village>>([])

    const action = () => {
        setLoading(false)
        routeManager.openReviewPending()
    }
    const saveVillages = () => {
        if (selectedVillageData.length == 0) {
            routeManager.openReviewPending()
        }

        if (selectedVillageData.length > DELAR_VILLAGE_UPPER_CAP) {
            toast(`A Maximum of ${DELAR_VILLAGE_UPPER_CAP} villages can be selected!`)
            return;
        }

        const addDtoList: AddVillageDto[] = []
        setLoading(true)
        selectedVillageData.map((village) => {
            const addDto = {} as AddVillageDto
            addDto.delarId = SecurityManager.getUid()
            addDto.villageId = village.id
            addDtoList.push(addDto)
        })

        const data = {
            "addListDto": [...addDtoList],
            "action": action,
        } as any

        AddressActor({ "type": AddressAction.ADD_VILLAGE_LIST }, data)
    }

    const skipClick = () => {
        setLoading(true)
        RegistrationActor({ "type": RegistrationAction.CHECK_STATUS }, {
            "action": () => {
                setLoading(false)
                routeManager.openReviewPending()
            }
        })
    }

    const saveClick = () => {
        setLoading(true)
        RegistrationActor({ "type": RegistrationAction.CHECK_STATUS }, {
            "action": () => {
                setLoading(false)
                saveVillages()
            }
        })
    }

    return (
        <>
            <CommonAddVillageComponent loading={loading} setLoading={setLoading} selectedVillageData={selectedVillageData} setSelectedVillageData={setSelectedVillageData} />

            <BottomNavigation showLabels className={classes.bottomNavigation}>
                <Button className={classes.skipButton} onClick={() => { skipClick() }} variant="outlined">{t("skip")}</Button>
                <Button className={classes.nextButton} variant="contained" color="primary" onClick={saveClick}>
                    {t("save")}
                </Button>
            </BottomNavigation>


        </>

    )
}