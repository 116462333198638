import { Button, Divider, Link, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderAction } from "../../../action/order.action";
import { OrderActor } from "../../../actor/order.actor";
import { AndroidConnerctor } from "../../../android.connecter";
import { OrderStatus } from "../../../enum/order-status.enum";
import { OrderAddress } from "../../../model/address.model";
import { useRouteManager } from "../../../router/route.manager";
import { UpdateOrderStatusDto } from "../../../service/dto/order.dto";
import { Order } from "../../../service/response/order.response";
import { OrderProductMini } from "../../../service/response/product.response";
import { OrderStore } from "../../../store/order.store";
import { useObservable } from "../../../useSubscriber";
import LoadingComponent from "../../common/loading/loading.component";
import { useStyles } from "./order.style";
import { CancelledOrderComponent } from "./views/cancelled/cancelled-order.component";
import { CompletedOrderComponent } from "./views/completed/completed-order.component";
import { DeclinedOrderComponent } from "./views/declined/declined.component";
import { NewOrderComponent } from "./views/new/new-order.component";
import { PendingOrderComponent } from "./views/pending/pending-order.component";
import { ReturnPendingComponent } from "./views/return-pending/return-pending.component";
import { ReturnPickedComponent } from "./views/return-picked/return-picked.component";
import InfinitScroll from 'react-infinite-scroll-component';
import { OutForDeliveryrderComponent } from "./views/out-for-delivery/out-for-delivery.component";

export const OrderComponent = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const { t } = useTranslation();

    const routeManager = useRouteManager();

    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)

    const [orderList, _] = useObservable(OrderStore.getOrderList(), OrderStore.getOrderListObservable())

    const afterOrderFetch = {
        setLoading: setLoading,
        setHasMore: setHasMore
    }

    const initView = () => {
        setLoading(true)
        setHasMore(true)
        if (value === 1) {
            OrderActor({ "type": OrderAction.GET_LIST }, { "statusList": [OrderStatus.ORDER_ACCEPTED_BY_ADMIN], "action": afterOrderFetch })
        }
        else if (value === 2) {
            OrderActor({ "type": OrderAction.GET_LIST }, { "statusList": [OrderStatus.ORDER_ACCEPTED_BY_DELAR, OrderStatus.RETURN_ACCEPTED_BY_ADMIN, OrderStatus.ORDER_OUT_FOR_DELIVERY], "action": afterOrderFetch })
        }
        else if (value === 3) {
            OrderActor({ "type": OrderAction.GET_LIST }, { "statusList": [OrderStatus.ORDER_CANCELLED_BY_FARMER, OrderStatus.ORDER_REJECTED_BY_DELAR], "action": afterOrderFetch })
        }
        else if (value === 4) {
            OrderActor({ "type": OrderAction.GET_LIST }, { "statusList": [OrderStatus.RETURN_PICKED, OrderStatus.ORDER_DELIVERED], "action": afterOrderFetch })
        } else {
            OrderActor({ "type": OrderAction.GET_LIST }, { "statusList": [], "action": afterOrderFetch })
        }
    }

    useEffect(() => {
        initView()
    }, [value])

    const handleItemClick = (shipmentId: string, shipmentStatus: number) => {
        routeManager.openShipmentDetail(shipmentId, shipmentStatus)
    }

    const afterPackUpdate = () => {
        initView()
    }

    const markAsDeliver = (orderProducts: Array<OrderProductMini>) => {
        const updateOrderDtos: UpdateOrderStatusDto[] = []
        orderProducts.map((orderProduct: OrderProductMini) => {
            updateOrderDtos.push({
                "userId": orderProduct.delarId,
                "userType": 3,
                "status": {
                    "value": OrderStatus.ORDER_DELIVERED,
                    "msg": "Order delivered by Delar"
                },
                "orderProductId": orderProduct.id,
            } as UpdateOrderStatusDto)
        });

        OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": updateOrderDtos, "afterPackUpdate": afterPackUpdate })
    }

    const returnPick = (orderProducts: Array<OrderProductMini>) => {
        const updateOrderDtos: UpdateOrderStatusDto[] = []
        orderProducts.map((orderProduct: OrderProductMini) => {
            updateOrderDtos.push({
                "userId": orderProduct.delarId,
                "userType": 3,
                "status": {
                    "value": OrderStatus.RETURN_PICKED,
                    "msg": "Return Picked by Delar"
                },
                "orderProductId": orderProduct.id,
            } as UpdateOrderStatusDto)
        });

        OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": updateOrderDtos, "afterPackUpdate": afterPackUpdate })
    }

    const contactFarmer = (oderAddress: OrderAddress) => {
        AndroidConnerctor.openSupportDialog(oderAddress.phone)
    }

    const handlePagination = () => {
        OrderActor({ "type": OrderAction.GET_NEXT_PAGE_LIST }, { "action": afterOrderFetch })
    }

    return (
        <div className={classes.root}>
            <div className={classes.buttonContainer}>
                <Link color="primary" style={{ marginLeft: 16, color: '#0e4bb4', fontSize: 14, fontWeight: 700, marginTop: 4, marginBottom: 4, }} onClick={() => setValue(0)}>{t("order_all")}</Link>
                <Link color="primary" style={{ color: '#0e4bb4', fontSize: 14, fontWeight: 700, marginTop: 4, marginBottom: 4, }} onClick={() => setValue(1)}>{t("common_new")}</Link>
                <Link color="primary" style={{ color: '#0e4bb4', fontSize: 14, fontWeight: 700, marginTop: 4, marginBottom: 4, }} onClick={() => setValue(2)}>{t("order_pending")}</Link>
                <Link color="primary" style={{ color: '#0e4bb4', fontSize: 14, fontWeight: 700, marginTop: 4, marginBottom: 4, }} onClick={() => setValue(3)}>{t("order_cancelled")}</Link>
                <Link color="primary" style={{ marginRight: 16, color: '#0e4bb4', fontSize: 14, fontWeight: 700, marginTop: 4, marginBottom: 4, }} onClick={() => setValue(4)}>{t("order_completed")}</Link>
            </div>
            <Divider style={{ marginBottom: 4, }} />
            <InfinitScroll
                dataLength={orderList.length}
                next={handlePagination}
                hasMore={hasMore}
                loader={<LoadingComponent />}
            >
                {orderList.map((order: Order, index: any) => {
                    return (
                        <React.Fragment>
                            {order.status.value === OrderStatus.ORDER_ACCEPTED_BY_ADMIN && (
                                <NewOrderComponent order={order}
                                    handleItemClick={handleItemClick} />
                            )}
                            {order.status.value === OrderStatus.ORDER_ACCEPTED_BY_DELAR  && (
                                <PendingOrderComponent order={order} markAsDeliver={markAsDeliver} contactFarmer={contactFarmer}
                                    handleItemClick={handleItemClick} />
                            )}
                            {order.status.value === OrderStatus.ORDER_OUT_FOR_DELIVERY && (
                                <OutForDeliveryrderComponent order={order}
                                    handleItemClick={handleItemClick} />
                            )}
                            {order.status.value === OrderStatus.ORDER_CANCELLED_BY_FARMER && (
                                <CancelledOrderComponent order={order}
                                    handleItemClick={handleItemClick} />
                            )}
                            {order.status.value === OrderStatus.ORDER_DELIVERED && (
                                <CompletedOrderComponent order={order}
                                    handleItemClick={handleItemClick} />
                            )}
                            {order.status.value === OrderStatus.ORDER_REJECTED_BY_DELAR && (
                                <DeclinedOrderComponent order={order}
                                    handleItemClick={handleItemClick} />
                            )}
                            {(order.status.value === OrderStatus.RETURN_ACCEPTED_BY_ADMIN || order.status.value === OrderStatus.RETURN_ACCEPTED_BY_DELAR) && (
                                <ReturnPendingComponent order={order} returnPick={returnPick} contactFarmer={contactFarmer}
                                    handleItemClick={handleItemClick} />
                            )}
                            {order.status.value === OrderStatus.RETURN_PICKED && (
                                <ReturnPickedComponent order={order}
                                    handleItemClick={handleItemClick} />
                            )}
                        </React.Fragment>
                    );
                })}
            </InfinitScroll>
            {orderList && orderList.length === 0 && !loading && (
                <React.Fragment>
                    <div >
                        <img src='/asset/empty order list.svg' className={classes.imgNoOrder} />
                        <Typography variant="body2" className={classes.description}>
                            {t("orders_page_no_orders_description")}
                        </Typography>
                        <Button onClick={() => AndroidConnerctor.recommendedToFriends("Hello Testing")} variant="contained" color="primary" className={classes.shareButton} >
                            <img style={{ width: 27, height: 27 }} src='/asset/Whatsapp.svg' />
                            <Typography style={{ opacity: 0.8, fontSize: 12 }}>
                                {t("share")}
                            </Typography>
                        </Button>
                    </div>
                </React.Fragment>
            )}
            <Modal open={loading} className={classes.modal}><LoadingComponent /></Modal>
        </div>
    )
}