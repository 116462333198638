import { Button, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core"
import React from "react";
import { useTranslation } from "react-i18next";
import { Media } from "../../../model/media.model";
import { useRouteManager } from "../../../router/route.manager";
import { DelarProductInfoRes } from "../../../service/response/product.response";
import { useStyles } from "./product.style";

const ProductItemComponentBase: React.FC<{ product: DelarProductInfoRes }> = ({ product }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const routeManager = useRouteManager();

    const labelId = `${product.id}`
    let availablePacks = 0
    if (product.packs) {
        availablePacks = product.packs.filter(pack => {
            return pack.enabled
        }).length
    }
    const photo = new Map(Object.entries(product.photos)).values().next().value as Media

    const handleProductClick = (id: string) => {
        routeManager.openProductDetail(id)
    };

    const handleAddPackClick = (id: string, event: any) => {
        event.stopPropagation()
        routeManager.openProductPackSizePage(id)
    };

    return (
        <React.Fragment key={product.id}>
            <ListItem button onClick={(event: any) => handleProductClick(product.id)}>
                <ListItemAvatar >
                    <img
                        alt={`Avatar ${product.id}`}
                        src={new Map(Object.entries(photo.urls)).get('main') as string}
                        style={{ width: 60, height: 60, marginTop: 8, marginBottom: 8, }}
                    />
                </ListItemAvatar>
                <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" style={{ fontSize: 14, marginTop: -6 }} className={classes.productName}>
                    <b>{product.name}</b>
                </Typography>}
                    secondary={<><Typography variant="subtitle1" className={classes.productDescription}>
                        {product.company.name}
                    </Typography>
                        {availablePacks > 0 && <Typography variant="body2" className={classes.productName}>
                            {`${availablePacks} `.concat(t("pack_size_available"))}
                        </Typography>}
                        {availablePacks === 0 && <><Typography variant="body2" className={classes.productName}>
                            {`0 `.concat(t("pack_size"))}
                        </Typography>
                            <ListItemSecondaryAction style={{ marginTop: 10 }}
                                onClick={(event: any) => handleAddPackClick(product.id, event)}>
                                <Button variant="outlined" color="primary" className={classes.addPackButton}>
                                    {t("product_add_pack_size")}
                                </Button>
                            </ListItemSecondaryAction></>
                        }
                    </>}
                />
            </ListItem>
            <Divider />
        </React.Fragment>
    );
}

export const ProductItemComponent = React.memo(ProductItemComponentBase);