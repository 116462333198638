import { Button, Modal, Container, IconButton, Step, StepLabel, Stepper, Typography, BottomNavigation } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import { BusinessDetailComponent } from "./business-detail/businees-detail.componet";
import { useStyles } from "./delar-detail.style"
import { DocumetProofComponent } from "./document-proof/document-proof.component";
import { PersonalDetailComponent } from "./personal-detail/personal-detail.component";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';
import { DelarActor } from "../../../actor/delar.actor";
import { DelarAction } from "../../../action/delar.action";
import { SecurityManager } from "../../../security/security.manager";
import LoadingComponent from "../../common/loading/loading.component";
import { useRouteManager } from "../../../router/route.manager";
import { useObservable } from "../../../useSubscriber";
import { DelarStore } from "../../../store/delar.store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DelarStatus } from "../../../enum/delar-status.enum";

export const DelarDetailComponent = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const routeManager = useRouteManager()
    const classes = useStyles()

    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(false)

    const [delarDtoError, delarDtoErrorObservable] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable() as any)
    const [delarDto, dtoe] = useObservable(DelarStore.getDelarDto(), DelarStore.getDelarDtoObservable())

    const [shopImageFirstTime, setShopImageFirstTime] = useState(true)
    const _activeStep = useRef(1)
    const [activeStep, setActiveStep] = useState(_activeStep.current);

    const steps = [t("onboarding_sign_up"), t("onboarding_personal_details"), t("onboarding_business_details"), t("onboarding_document_details_proof")];

    const goToNext = () => {
        setLoading(false)
        if (activeStep == 3) {
            routeManager.openReviewPending()
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleUpdateError = () => {
        setLoading(false)
    }

    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    const handleUpload = () => {
        setImages([])
    }

    const handleNext = (): any => {
        if (validateData(activeStep)) {
            switch (activeStep) {
                case 1:
                    setLoading(true)

                    DelarActor({ "type": DelarAction.SAVE_DATA }, {
                        "delarId": SecurityManager.getUid(),
                        "action": goToNext,
                        "errorAction": handleUpdateError
                    })
                    break
                case 2:
                    setLoading(true)

                    if (delarDto?.shopAddress?.geoAddress?.district?.eCommerceEnable === false) {
                        setLoading(false)
                        navigate('/on-board/not-deliverable')
                    }
                    DelarActor({ "type": DelarAction.UPLOAD_SHOP_IMAGES }, {
                        "delarId": SecurityManager.getUid(),
                        "shopPhotos": images,
                        "onUploaded": handleUpload,
                        "action": goToNext,
                        "errorAction": handleUpdateError
                    })
                    break
                case 3:
                    setLoading(true)
                    DelarActor({ "type": DelarAction.UPDATE_STATUS }, {
                        "value": DelarStatus.Pending,
                        "msg": "Init request for verification"
                    })
                    DelarActor({ "type": DelarAction.SAVE_DATA }, {
                        "delarId": SecurityManager.getUid(),
                        "action": goToNext,
                        "errorAction": handleUpdateError
                    })
                    break
            }
        }
    };

    useEffect(() => {
        DelarActor({ "type": DelarAction.UPDATE_SHOP_PHOTOS_ERROR }, { "flag": (images.length === 0 && (delarDto?.shopPhotos === null)) })
    }, [images])

    const validateData = (step: number): boolean => {
        switch (step) {
            case 1:
                DelarActor({ "type": DelarAction.VALIDATE_PERSONAL_DETAIL_PAGE_ERROR }, {})

                return !(delarDtoError.name.error || (delarDtoError.alternatePhone.error && delarDto.alternatePhone !== "") || delarDtoError.intro.error)
            case 2:
                setShopImageFirstTime(false)
                DelarActor({ "type": DelarAction.VALIDATE_BUSSINESS_DETAIL_PAGE_ERROR }, {})
                return !(delarDtoError.shopName.error || delarDtoError.gstDetail.error || delarDtoError.shopAddress.error || delarDtoError.state.error ||
                    delarDtoError.district.error || delarDtoError.city.error || delarDtoError.village.error || delarDtoError.pinCodeError.error)
            case 3:
                DelarActor({ "type": DelarAction.VALIDATE_DOCUMENT_PROOF_DETAIL_PAGE_ERROR }, {})
                return !(delarDtoError.shopName.error || delarDtoError.gstDetail.error || delarDtoError.shopAddress.error || delarDtoError.state.error ||
                    delarDtoError.district.error || delarDtoError.city.error || delarDtoError.village.error || delarDtoError.pinCodeError.error || delarDtoError.pesticidePhoto.error
                    || delarDtoError.pesticideLiscence.error || delarDtoError.fertilizerLiscence.error || delarDtoError.fertilizerPhoto.error ||
                    delarDtoError.seedPhoto.error || delarDtoError.seedLiscence.error)
                break;
        }
        return true
    }

    const handleBack = () => {
        if (activeStep == 1) {
            routeManager.clearHistory()
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    useEffect(() => {
        setLoading(true)
        DelarActor({ "type": DelarAction.RE_INIT_DELAR }, {
            "delarId": SecurityManager.getUid(),
            "action": () => { setLoading(false); handleUpdateError() },
            "errorAction": handleUpdateError
        })
    }, [])
    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 1:
                return <PersonalDetailComponent />
            case 2:
                return <BusinessDetailComponent flag={shopImageFirstTime} handleUpload={(value: any) => {
                    setImages(value)
                }} startLoading={startLoading} stopLoading={stopLoading} />
            case 3:
                return <DocumetProofComponent setLoading={(value: any) => { setLoading(value) }}
                    startLoading={startLoading} stopLoading={stopLoading} />
            default:
                return <PersonalDetailComponent />
        }
    }
    const ColorlibConnector = withStyles({
        active: {
            '& $line': {
                background: '#1976d2',

            },
        },
        completed: {
            '& $line': {
                background: '#1976d2',
            },
        },
        line: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
        },
    })(StepConnector);

    return (
        <div className={classes.root}>
            {activeStep > 0 && (
                <IconButton edge="end"
                    onClick={() => { handleBack() }}
                    color="inherit" aria-label="menu">
                    <ArrowBackIcon style={{ color: 'black', fontSize: 30, }} />
                </IconButton>
            )}
            <Modal
                open={loading}
                className={classes.modal}
            >
                {
                    <LoadingComponent />
                }
            </Modal>
            {!loading && <><Stepper style={{ padding: 0 }} activeStep={activeStep} connector={<ColorlibConnector />} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            classes={{
                                iconContainer: classes.iconContainer,
                            }}><Typography variant="body2" style={{ fontSize: 10, color: activeStep === index ? '#2A312F' : '#7E8184' }}>{label}</Typography></StepLabel>
                    </Step>
                ))}
            </Stepper>
                <Container maxWidth="sm" style={{ marginTop: 49, paddingRight: 12, }}>
                    {getStepContent(activeStep)}
                </Container>

                <BottomNavigation showLabels className={classes.bottomNavigation}>
                    <Button className={classes.nextButton} variant="contained" color="primary" onClick={() => {
                        handleNext()
                    }}>
                        {t("onboarding_save")}
                    </Button>
                </BottomNavigation>
            </>}

        </div>
    );
}