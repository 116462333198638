import { Button, Card, CardContent, CardHeader, Collapse, IconButton, TextField, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useStyles } from "./document-proof.style"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DelarStore } from "../../../../store/delar.store"
import { DelarActor } from "../../../../actor/delar.actor";
import { DelarAction } from "../../../../action/delar.action";
import { SecurityManager } from "../../../../security/security.manager";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { GalleryPickerComponent } from "../../../Image_Picker/gallery-picker.component"
import { ExpandLess } from "@material-ui/icons"
import { useObservable } from "../../../../useSubscriber"
import { useTranslation } from "react-i18next"

export const DocumetProofComponent = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())
    const [delarDto] = useObservable(DelarStore.getDelarDto(), DelarStore.getDelarDtoObservable())

    const [expanded1, setExpanded1] = React.useState(true);
    const [expanded2, setExpanded2] = React.useState(false);
    const [expanded3, setExpanded3] = React.useState(false);

    const [pesticideImages, setpesticideImages] = useState([]);
    const [fertiliserImages, setfertiliserImages] = useState([]);
    const [seedImages, setseedImages] = useState([]);

    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const handleChangePesticideLicense = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        DelarActor({ "type": DelarAction.UPDATE_PESTICIDE_LICENCE }, { "licenseNumber": e.target.value })
    };
    const handleChangeFertilizerLicense = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        DelarActor({ "type": DelarAction.UPDATE_FERTILIZER_LICENCE }, { "licenseNumber": e.target.value })
    };
    const handleChangeSeedLicense = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        DelarActor({ "type": DelarAction.UPDATE_SEED_LICENCE }, { "licenseNumber": e.target.value })
    };

    const handlepesticideImages = (value: any) => {
        setpesticideImages(value);
    };
    const handlefertiliserImages = (value: any) => {
        setfertiliserImages(value);
    };
    const handleseedImages = (value: any) => {
        setseedImages(value);

    };
    const afterUpload1 = () => {
        props.setLoading(false);
        setExpanded1(false);
    }
    const afterUpload2 = () => {
        props.setLoading(false);
        setExpanded2(false);
    }
    const afterUpload3 = () => {
        props.setLoading(false);
        setExpanded3(false);
    }
    const handleUpload1 = () => {
        props.setLoading(true);

        DelarActor({ "type": DelarAction.UPLOAD_PESTICIDE_IMAGES }, { "delarId": SecurityManager.getUid(), "onUploaded": afterUpload1, "photos": pesticideImages, "action": () => { props.setLoading(false) }, "errorAction": () => { props.setLoading(false) } })
    };
    const handleUpload2 = () => {
        props.setLoading(true);
        DelarActor({ "type": DelarAction.UPLOAD_FERTILISER_IMAGES }, { "delarId": SecurityManager.getUid(), "onUploaded": afterUpload2, "photos": fertiliserImages, "action": () => { props.setLoading(false) }, "errorAction": () => { props.setLoading(false) } })
    };
    const handleUpload3 = () => {
        props.setLoading(true);

        DelarActor({ "type": DelarAction.UPLOAD_SEED_IMAGES }, { "delarId": SecurityManager.getUid(), "onUploaded": afterUpload3, "photos": seedImages, "action": () => { props.setLoading(false) }, "errorAction": () => { props.setLoading(false) } })
    };
    const deleteUploadedElement1 = (key: string) => {
        props.startLoading()
        DelarActor({ "type": DelarAction.DELETE_PESTICIDE_PHOTO }, {
            "key": key, "delarId": SecurityManager.getUid(),
            "action": props.stopLoading,
            "errorAction": props.stopLoading
        })
    }
    const deleteUploadedElement2 = (key: string) => {
        props.startLoading()
        DelarActor({ "type": DelarAction.DELETE_FERTILISER_PHOTO }, {
            "key": key, "delarId": SecurityManager.getUid(),
            "action": props.stopLoading,
            "errorAction": props.stopLoading
        })
    }
    const deleteUploadedElement3 = (key: string) => {
        props.startLoading()
        DelarActor({ "type": DelarAction.DELETE_SEED_PHOTO }, {
            "key": key, "delarId": SecurityManager.getUid(),
            "action": props.stopLoading,
            "errorAction": props.stopLoading
        })
    }

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.heading} >
                <b>{t("onboarding_document_details_proof")}</b>
            </Typography>
            <Card className={classes.subRoot} >
                <CardHeader onClick={handleExpandClick1}
                    title={<Typography variant="body2" style={{ fontSize: 14, fontWeight: 'bold' }}>{t("pesticide_license")}</Typography>}
                    action={
                        <IconButton
                            className={classes.expand}

                            aria-expanded={expanded1}
                            aria-label="show more"
                        >
                            {expanded1 ? <ExpandLess /> : <ExpandMoreIcon />}
                        </IconButton>
                    }
                />
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body1" className={classes.title1} >
                            {t("onboarding_document_details_license_number")}
                        </Typography>
                        <form className={classes.formField} noValidate autoComplete="off">
                            <TextField
                                id="outlined-basic"
                                value={delarDto?.pesticideLiscence?.newLicence?.number}
                                className={classes.textField1}
                                variant="outlined"
                                classes={{
                                    root: delarDtoError?.pesticideLiscence.error ? classes.TextFieldRoot : classes.noStyle,
                                }}
                                inputProps={{
                                    style: {
                                        height: 11,
                                    }
                                }
                                }
                                onChange={handleChangePesticideLicense} />
                            {delarDtoError?.pesticideLiscence.error && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{t("onboarding_license_number_error")}
                            </Typography>}
                        </form>
                        <Typography variant="body1" className={classes.title3} >
                            {t("upload_license")}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <GalleryPickerComponent
                                handleUpload={handlepesticideImages}
                                uploaded={delarDto?.pesticideLiscence?.newLicence?.photos}
                                deleteUploadedElement={deleteUploadedElement1}
                            />
                        </div>
                        {delarDtoError?.pesticidePhoto.error && <Typography variant="body1" className={classes.errorText}>
                            <ErrorOutlineIcon className={classes.errorIcon} />{t("onboarding_document_proof_license_image_required")}
                        </Typography>}
                        <Button variant="outlined" color="primary" className={classes.uploadButton} onClick={() => {
                            handleUpload1()
                        }
                        }>
                            {t("onboarding_document_details_upload_details")}
                        </Button>
                    </CardContent>
                </Collapse>
            </Card>
            <Card className={classes.subRoot} >
                <CardHeader onClick={handleExpandClick2}
                    title={<Typography variant="body2" style={{ fontSize: 14, fontWeight: 'bold' }}>{t("fertiliser_license")}</Typography>}
                    action={
                        <IconButton
                            className={classes.expand}

                            aria-expanded={expanded2}
                            aria-label="show more"
                        >
                            {expanded2 ? <ExpandLess /> : <ExpandMoreIcon />}
                        </IconButton>
                    }
                />
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body1" className={classes.title1} >
                            {t("onboarding_document_details_fertiliser_number")}
                        </Typography>
                        <form className={classes.formField} noValidate autoComplete="off">
                            <TextField
                                id="outlined-basic"
                                value={delarDto?.fertilizerLiscence?.newLicence?.number}
                                className={classes.textField1}
                                variant="outlined"
                                classes={{
                                    root: delarDtoError?.fertilizerLiscence.error ? classes.TextFieldRoot : classes.noStyle,
                                }}
                                inputProps={{
                                    style: {
                                        height: 11,
                                    }
                                }
                                }
                                onChange={handleChangeFertilizerLicense} />
                            {delarDtoError?.fertilizerLiscence.error && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{t("onboarding_license_number_error")}
                            </Typography>}
                        </form>
                        <Typography variant="body1" className={classes.title3} >
                            {t("upload_license")}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <GalleryPickerComponent
                                handleUpload={handlefertiliserImages}
                                uploaded={delarDto?.fertilizerLiscence?.newLicence?.photos}
                                deleteUploadedElement={deleteUploadedElement2}
                            />
                        </div>
                        {delarDtoError?.fertilizerPhoto.error && <Typography variant="body1" className={classes.errorText}>
                            <ErrorOutlineIcon className={classes.errorIcon} />{t("onboarding_document_proof_license_image_required")}
                        </Typography>}
                        <Button variant="outlined" color="primary" className={classes.uploadButton} onClick={() => {
                            handleUpload2();
                        }
                        }>
                            {t("onboarding_document_details_upload_details")}
                        </Button>
                    </CardContent>
                </Collapse>
            </Card>
            <Card className={classes.subRoot} style={{ marginBottom: 90, }} >
                <CardHeader onClick={handleExpandClick3}
                    title={<Typography variant="body2" style={{ fontSize: 14, fontWeight: 'bold' }}>{t("seed_license")}</Typography>}
                    action={
                        <IconButton
                            className={classes.expand}

                            aria-expanded={expanded3}
                            aria-label="show more"
                        >
                            {expanded3 ? <ExpandLess /> : <ExpandMoreIcon />}
                        </IconButton>
                    }
                />
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body1" className={classes.title1} >
                            {t("onboarding_document_details_seed_number")}
                        </Typography>
                        <form className={classes.formField} noValidate autoComplete="off">
                            <TextField
                                id="outlined-basic"

                                value={delarDto?.seedLiscence?.newLicence?.number}
                                className={classes.textField1}
                                variant="outlined"
                                classes={{
                                    root: delarDtoError?.seedLiscence.error ? classes.TextFieldRoot : classes.noStyle,
                                }}
                                inputProps={{
                                    style: {
                                        height: 11,
                                    }
                                }
                                }
                                onChange={handleChangeSeedLicense} />
                            {delarDtoError?.seedLiscence.error && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{t("onboarding_license_number_error")}
                            </Typography>}
                        </form>
                        <Typography variant="body1" className={classes.title3} >
                            {t("upload_license")}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <GalleryPickerComponent
                                handleUpload={handleseedImages}
                                uploaded={delarDto?.seedLiscence?.newLicence?.photos}
                                deleteUploadedElement={deleteUploadedElement3}
                            />
                        </div>
                        {delarDtoError?.seedPhoto.error && <Typography variant="body1" className={classes.errorText}>
                            <ErrorOutlineIcon className={classes.errorIcon} />{t("onboarding_document_proof_license_image_required")}
                        </Typography>}
                        <Button variant="outlined" color="primary" className={classes.uploadButton} onClick={() => {
                            handleUpload3();
                        }
                        }>
                            {t("onboarding_document_details_upload_details")}
                        </Button>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
}
