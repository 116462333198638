export enum ImageType {
    UNKNOWN_MEDIA = 0,
    AUDIO = 1,
    VIDEO = 2,
    IMAGE = 3
}

export enum ImageUseType {
    NORMAL_MEDIA = 0,
    ICON_MEDIA = 1,
    BACKGROUND_MEDIA = 2,
    DP_MEDIA = 3
}