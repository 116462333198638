import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            justifyContent: 'center',
        },
        imageHolder: {
            width: 120,
            height: 120,
            margin: 'auto',
            borderRadius: 150,
            backgroundColor: '#ccc',
        },
        image: {
            width: '100%',
            height: '100%',
        },
        description: {
            width: 244,
            height: 67,
            marginTop: 16,
            marginBottom: 12,
            margin: 'auto',
            textAlign: 'center',
            fontSize: '1.1428571428571428em',
            
            fontWeight: 500,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '1em',
            justifyContent: 'space-between',
            padding: 10,
        },
        button: {
            width: '45%',
            height: 38,
            textTransform: "none",
            fontSize: '0.9285714285714286em',
            borderRadius: 30,
        },
        
    })
)