import Axios from "axios"
import { SERVER_URL } from "../constant/base.constant";
import { useRouteManager } from "../router/route.manager";
import { SecurityManager } from "../security/security.manager";

export const useHttpIntercepter = () => {

  Axios.defaults.baseURL = SERVER_URL

  Axios.interceptors.request.use(config => {
    const token = SecurityManager.getToken()
    if (token && token.length > 0) {
      const httpOptions = {
        'Authorization': token
      }
      const defaultParams = {
        "lang": SecurityManager.getLanguage()
      }
      config.params = {...config.params, ...defaultParams}
      Object.assign(config.headers, httpOptions)
    }
    return config
  })

  Axios.interceptors.response.use(response => {
    return response
  }, error => {
    if (error && error.response && error.response.status === 401) {
      SecurityManager.logout()
    }
    return Promise.reject(error)
  })
}