import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom: 10,
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            fontSize: 24,
        },
        title: {
            flexGrow: 1,
            fontSize: 14,
            marginLeft: theme.spacing(2),
        },
        title3: {
            wordWrap: 'break-word',
            marginTop: 8,
            marginLeft: 11,
            marginRight: 16,
            fontSize: 12,
            lineSpacing: 0.24,
            color: '#2A312F',
            border: 0,
            padding: 12,
            outline: 0,
            background: 'transparent',
            borderBottom: '1 solid black'

        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            justifyContent: 'center'
        },
        icon: {
            marginLeft: 16,
            marginTop: 10,
            width: 16,
            height: 16,
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
    }),
);