import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStyles } from './dialog-alert.style';
import { Typography } from '@material-ui/core';

export default function DialogAlertComponent(props: any) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{<Typography variant="body2" className={classes.dialogHeading}>{props.heading}</Typography>}</DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <DialogContentText id="alert-dialog-description">
          {<Typography variant="body2" className={classes.dialogContent}>{props.content}</Typography>}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Button onClick={props.handleClose} className={classes.dialogButtonDisagree} color="primary">
          {props.disagree}
        </Button>
        <Button onClick={props.handleAgree} className={classes.dialogButtonAgree} color="primary" autoFocus>
          <b>{props.agree}</b>
        </Button>
      </DialogActions>
    </Dialog>

  );
}