import { Avatar, Button, Card, CardContent, Container, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "./pending.style"
export const PendingComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Card className = {classes.root}>
            <CardContent>
                <img src = '../asset/pending.svg' className = {classes.img}/>
            </CardContent>
            <Typography variant = "body2" className = {classes.heading}>
                {t("application_status")}
            </Typography>
            <Typography variant = "body2" className = {classes.pending}>
                {t("pending")}
            </Typography>
            <Typography variant = "body2" className = {classes.description}>
                {t("application_pending_text")}
            </Typography>
            <Button variant="outlined" color="primary" className={classes.button} style = {{color: '#0e4bb4', fontWeight: 700, borderWidth: 1,}}>
                    {t("edit_form")}
                </Button>
        </Card>
    );
}