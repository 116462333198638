import { Button, MobileStepper, Typography } from "@material-ui/core"
import React from "react"
import { useStyles } from './intro.style'
import SwipeableViews from 'react-swipeable-views'
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../router/route.manager"



export const IntroComponent = () => {
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);


    const slideContent = [
        {
            id: '1',
            title: t("intro_slide_1_title"),
            description: t("intro_slide_1_description"),
            imagePath: '../asset/intro 1.svg',
        },
        {
            id: '2',
            title: t("intro_slide_2_title"),
            description: t("intro_slide_2_description"),
            imagePath: '../asset/intro 2.svg',
        },
        {
            id: '3',
            title: t("intro_slide_3_title"),
            description: t("intro_slide_3_description"),
            imagePath: '../asset/intro 3.svg',
        },
    ];

    const maxSteps = slideContent.length;
    const handleNext = () => {
        if (activeStep == 2) {
            routeManager.openDelarDetail()
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const changeTab: (activeStep: any) => void = updatedTab => {
        setActiveStep(updatedTab);
    };

    const handleStepChange = (step: React.SetStateAction<number>) => {
        setActiveStep(step);
    };

    return (
        <>
            <div className={classes.root}>
                <SwipeableViews
                    axis="x"
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {slideContent.map((step, index) => (
                        <div key={step.id} className={classes.container}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img className={classes.img} src={slideContent[activeStep].imagePath} alt={step.id} />
                            ) : null}
                            <Typography variant='h4' className={classes.titleEdit}>
                                {slideContent[activeStep].title}
                            </Typography>
                            <Typography className={classes.descEdit}>
                                {slideContent[activeStep].description}
                            </Typography>
                        </div>
                    ))}
                </SwipeableViews>

                {activeStep !== slideContent.length - 1 && (
                    <MobileStepper
                        steps={maxSteps}
                        classes={{
                            positionStatic: classes.positionStatic,
                            dot: classes.dot,
                            dotActive: classes.dotActive
                        }}
                        position='static'
                        activeStep={activeStep}
                        onChange={changeTab}
                        nextButton={
                            <Button variant="contained" color="primary" className={classes.nextButton} onClick={handleNext}>
                                {t("next")}
                            </Button>
                        }
                        backButton={<Button color="primary" onClick={() => { routeManager.openDelarDetail() }} disabled={activeStep === maxSteps - 1} className={classes.backButton}>
                            {t("skip")}
                        </Button>
                        }
                    />
                )}

                {activeStep == slideContent.length - 1 && (
                    <MobileStepper
                        steps={maxSteps}
                        classes={{
                            positionStatic: classes.positionStatic,
                            dot: classes.dot2,
                            dotActive: classes.dotActive2
                        }}
                        position='static'
                        activeStep={activeStep}
                        onChange={changeTab}
                        nextButton={<Button variant="contained" color="primary" className={classes.nextButton} style={{ width: '90%', boxShadow: 'none', marginRight: '6.5%' }} onClick={handleNext}>
                            {t("next")}
                        </Button>
                        }
                        backButton={
                            <></>
                        }
                    />
                )}
            </div>
        </>
    )
}