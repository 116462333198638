import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            fontSize: 30,
            marginRight: 10,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: '#fff',
            height: 40,
            marginRight: theme.spacing(1),
            marginLeft: 0,
            width: '95%',

        },
        searchIcon: {
            padding: theme.spacing(0, 1),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#000',
            opacity: 0.5,
        },
        inputRoot: {
            color: '#000',
            width: '100%'
        },
        inputInput: {
            padding: theme.spacing(1.75, 2, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),


            fontSize: 12,
        },
        topButtonCard: {
            width: 'auto',
            height: 48,
            position: 'static',
            paddingLeft: 16,
            overflowX: 'scroll',
            overFlowY: 'hidden',
            whiteSpace: 'nowrap',

            display: 'flex',
        },
        chip: {
            marginRight: 12,
            marginBottom: 4,
            backgroundColor: '#fff',
            border: '0.5px solid #0E4BB4',
            borderRadius: 2,
            fontSize: 13,
            marginTop: 8,
        },
        productImage: {
            width: 60, height: 60,
            marginTop: 8,
            marginBottom: 8,
        },
        title: {
            flexGrow: 1,
            align: "left",
            fontSize: 14,
            marginLeft: theme.spacing(1),
        },
        productName: {
            fontSize: 14,
            fontWeight: 700,
            marginLeft: 8,
        },
        productDescription: {
            fontSize: 14,
            marginLeft: 8,
        },
        topMenu: {
            display: "flex",
            width: "auto",
            height: 48,
            borderBottom: '1px solid #ccc',
        },
        buttonStyle: {
            textTransform: 'capitalize',
            width: 'auto',
            marginLeft: 12,
            marginTop: 0,
            marginRight: 16,
            fontSize: 12,
            letterSpacing: 0,
            whiteSpace: 'nowrap',
            fontWeight: 500,
        },
        nextButton: {
            width: "90%",
            margin: "auto",
            display: "flex",
            textTransform: 'capitalize',
            background: '#0E4BB4',
            fontSize: 14,
            height: 48,
            boxShadow: 'none',
        },
        img: {
            width: 274,
            height: 192,
            margin: "auto",
            marginBottom: 8,
            marginTop: "20%"
        },
        heading: {
            position: 'static',
            fontSize: 14,
            marginLeft: 124,
            fontWeight: 700,
        },
        description: {
            fontSize: 14,
            marginLeft: 36,
            marginRight: 36,
            marginTop: 8,
            maxLines: 3,
            textAlign: 'center',
            color: '#4B514F',
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            maxWidth: 720,
            margin: 'auto',
            height: 80,
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            justifyContent: 'center',
            opacity: 0.5,
        },
    }),
);