import { AppBar, BottomNavigation, Button, FormControl, InputAdornment, Modal, OutlinedInput, TextField, Toolbar, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from "./edit-profile.style"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useObservable } from "../../../../useSubscriber";
import { EditProfileStore } from "../../../../store/edit-profile.store";
import { EditProfileActor } from "../../../../actor/edit-profile.actor";
import { EditProfileAction } from "../../../../action/edit-profile.action";
import { GalleryPickerComponent } from "../../../Image_Picker/gallery-picker.component";
import { SecurityManager } from "../../../../security/security.manager";
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../../router/route.manager";
import LoadingComponent from "../../../common/loading/loading.component";

export const EditProfileComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager()

    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(EditProfileStore.getDelarDtoError(), EditProfileStore.getDelarDtoErrorObservable())

    const [loading, setLoading] = useState(false)
    const [pageInit, setPageInit] = useState(false)

    const [imagesAdded, setImagesAdded] = useState([])

    const handleChangeName = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_NAME }, { "name": e.target.value })
    };

    const handleChangeAlternatePhone = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_ALTERNATE_PHONE }, { "alternatePhone": unformatter(e.target.value) })
    };

    const handleChangeIntro = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_INTRO }, { "intro": e.target.value })
    };

    const handleChangeShopName = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_SHOP_NAME }, { "newShopName": e.target.value })
    };

    const handleChangePesticideLicense = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_PESTICIDE_LICENSE }, { "pesticideLicenseNumber": e.target.value })
    };

    const handleChangeFertilizerLicense = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_FERTILIZER_LICENSE }, { "pesticideLicenseNumber": e.target.value })
    };

    const handleChangeSeedLicense = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_SEED_LICENSE }, { "pesticideLicenseNumber": e.target.value })
    };

    const handleUpdate = () => {
        if (delarDtoError.name.error || delarDtoError.alternatePhone.error)
            return
        setLoading(true)
        EditProfileActor({ "type": EditProfileAction.UPLOAD_NEW_SHOP_IMAGES }, { "delarId": SecurityManager.getUid(), "shopPhotos": imagesAdded, "action": () => { EditProfileActor({ "type": EditProfileAction.UPDATE_DATA }, { "delarId": SecurityManager.getUid(), "action": () => { setLoading(false); routeManager.goBack(); } }) } })
    };

    useEffect(() => {
        setLoading(true)
        EditProfileActor({ "type": EditProfileAction.RE_INIT_DELAR }, {
            "delarId": SecurityManager.getUid(),
            "action": () => {
                setLoading(false)
                setPageInit(true)
            },
            "errorAction": () => { }
        })
    }, [])

    const deleteUploadedElement = (key: string) => {
        EditProfileActor({ "type": EditProfileAction.DELETE_SHOP_PHOTO }, { "key": key })
    }

    useEffect(() => {
        var obj = delarDto?.shopPhotos
        var flag = ((imagesAdded.length === 0) && (obj && Object.keys(obj).length === 0 && obj.constructor === Object))
        EditProfileActor({ "type": EditProfileAction.UPDATE_SHOP_IMAGES_ERROR }, { "flag": false })
    }, [imagesAdded, delarDto])

    const unformatter = (value: string) => {
        let phoneValue = "";
        for (var i = 0; i < value.length; i++) {
            if (value[i] === ' ')
                continue
            phoneValue += value[i];
        }
        return phoneValue
    }
    const formatter = (value: string) => {
        let formattedValue = "";
        let count = 0;
        for (var i = 0; i < value.length; i++) {
            if ((count === 3 && value[i] !== ' ') || (count === 6 && value[i] !== ' ')) {
                formattedValue += " "
            }
            if (value[i] === ' ')
                continue
            formattedValue += value[i];
            count++;

        }
        return formattedValue
    }

    return (
        <>
            {pageInit && (
                <div className={classes.root}>
                    <AppBar position="sticky" style={{ boxShadow: 'none' }}>
                        <Toolbar>
                            <ArrowBackIcon
                                onClick={() => routeManager.goBack()}
                                className={classes.backIcon} />
                            <Typography variant="h6" className={classes.title}>
                                {t("edit_personal_details")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {!loading && <> <Typography variant="subtitle1" className={classes.title1} >
                        {t("edit_shop_picture")}
                    </Typography>
                        <div style={{ marginTop: 4, marginLeft: 16, width: '91%', padding: 0, }}>
                            <GalleryPickerComponent
                                uploaded={delarDto?.shopPhotos}
                                deleteUploadedElement={deleteUploadedElement}
                                handleUpload={(images: any) => { setImagesAdded([...images]) }}
                            />
                            {delarDtoError?.shopPhotos.error && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.shopPhotos?.msg}
                            </Typography>}
                        </div>

                        <Typography variant="subtitle1" className={classes.nameTitle}>
                            {t("edit_name")}
                        </Typography>
                        <form className={classes.formField} noValidate autoComplete="off">
                            <TextField
                                id="outlined-basic"
                                value={delarDto?.name}
                                InputProps={{
                                    classes: {
                                        input: classes.font12,
                                    },
                                }}
                                className={classes.textField1}
                                variant="outlined"
                                onChange={handleChangeName}
                            />

                            {delarDtoError?.name.error && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{t('edit_profile_name_error')}
                            </Typography>}
                        </form>
                        <Typography variant="subtitle1" className={classes.title3} >
                            {t("edit_alternate_contact_number")}
                        </Typography>
                        <FormControl className={classes.formField} variant="outlined">
                            <OutlinedInput
                                value={formatter(delarDto?.alternatePhone)}
                                classes={{
                                    adornedStart: classes.adornedStart,
                                    input: classes.input,
                                }}
                                startAdornment={<InputAdornment position="start" ><Typography variant="body1" style={{ fontSize: 12 }} >+91</Typography></InputAdornment>}
                                onChange={handleChangeAlternatePhone}
                            />
                            {delarDtoError?.alternatePhone.error && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{t("mobile_no_should_have_ten_digits")}
                            </Typography>}
                        </FormControl>
                        {/*<Typography variant="subtitle1" className={classes.title3} >
                    <b>{t("edit_intro")}</b>
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField
                        value={delarDto?.intro}
                        className={classes.textField1}
                        id="outlined-multiline-static"
                        multiline
                        InputProps={{
                            classes: {
                                input: classes.font12,
                            },
                        }}
                        rows={4}
                        placeholder="Default Value"
                        variant="outlined"
                        onChange={handleChangeIntro}
                    />
                    {delarDtoError?.intro.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.intro?.msg}
                    </Typography>}
                </form>
                <Typography variant="subtitle1" className={classes.title3} >
                    <b>{t("shop_name")}</b>
                </Typography>
                <FormControl className={classes.formField} variant="outlined">
                    <InputLabel htmlFor="shopName" style={{ fontSize: 12, }}>Type your shop name</InputLabel>
                    <OutlinedInput
                        id="shopName"
                        disabled
                        onClick={() => { routeManager.openEditShopDetail() }}
                        value={delarDto?.shopName?.newName}
                        className={classes.textField1}
                        onChange={handleChangeShopName}
                        endAdornment={
                            <InputAdornment position="end">
                                <EditIcon className={classes.editIcon} />
                            </InputAdornment>
                        }
                        labelWidth={170}
                    />
                </FormControl>
                <Typography variant="subtitle1" className={classes.title3} >
                    <b>{t("address")}</b>
                </Typography>
                <FormControl className={classes.formField} variant="outlined">
                    <InputLabel htmlFor="shopName" style={{ fontSize: 12, }}>{t("type_address")}</InputLabel>
                    <OutlinedInput
                        id="talukaName"
                        value={
                            `${delarDto?.shopAddress?.geoAddress?.village?.name}, ${delarDto?.shopAddress?.geoAddress?.subDistrict?.name}, ${delarDto?.shopAddress?.geoAddress?.district?.name}, ${delarDto?.shopAddress?.geoAddress?.state?.name}`
                        }
                        disabled
                        onClick={() => { routeManager.openEditAddress() }}
                        className={classes.textField1}
                        endAdornment={
                            <InputAdornment position="end">
                                <EditIcon className={classes.editIcon} />
                            </InputAdornment>
                        }
                        labelWidth={170}
                    />
                </FormControl>
                <Typography variant="subtitle1" className={classes.title3} >
                    <b>{t("pesticide_license")}</b>
                </Typography>
                <FormControl className={classes.formField} variant="outlined">
                    <InputLabel htmlFor="shopName" style={{ fontSize: 12, }}>{t("type_license_number")}</InputLabel>
                    <OutlinedInput
                        id="talukaName"
                        value={delarDto?.pesticideLiscence?.licence?.number}
                        className={classes.textField1}
                        disabled
                        onClick={() => { routeManager.openEditPesticideLicense() }}
                        onChange={handleChangePesticideLicense}
                        endAdornment={
                            <InputAdornment position="end">
                                <EditIcon className={classes.editIcon} />
                            </InputAdornment>
                        }
                        labelWidth={170}
                    />

                </FormControl>
                <Typography variant="subtitle1" className={classes.title3} >
                    <b>{t("fertiliser_license")}</b>
                </Typography>
                <FormControl className={classes.formField} variant="outlined">
                    <InputLabel htmlFor="shopName" style={{ fontSize: 12, }}>{t("type_license_number")}</InputLabel>
                    <OutlinedInput
                        id="fertilizerLicense"
                        value={delarDto?.fertilizerLiscence?.licence?.number}
                        className={classes.textField1}
                        disabled
                        onClick={() => { routeManager.openEditFertilizerLicense() }}
                        onChange={handleChangeFertilizerLicense}
                        endAdornment={
                            <InputAdornment position="end">
                                <EditIcon className={classes.editIcon} />
                            </InputAdornment>
                        }
                        labelWidth={170}
                    />
                </FormControl>
                <Typography variant="subtitle1" className={classes.title3} >
                    <b>{t("seed_license")}</b>
                </Typography>
                <FormControl className={classes.formField} variant="outlined">
                    <InputLabel htmlFor="shopName" style={{ fontSize: 12, }}>{t("type_license_number")}</InputLabel>
                    <OutlinedInput
                        id="seedLicense"
                        value={delarDto?.seedLiscence?.licence?.number}
                        className={classes.textField1}
                        disabled
                        onClick={() => { routeManager.openEditSeedLicense() }}
                        onChange={handleChangeSeedLicense}
                        endAdornment={
                            <InputAdornment position="end">
                                <EditIcon className={classes.editIcon} />
                            </InputAdornment>
                        }
                        labelWidth={170}
                    />
                    </FormControl>*/}
                        <BottomNavigation className={classes.bottomNavigation}>
                            <Button variant="contained" className={classes.saveButton} onClick={handleUpdate} >
                                {t("save")}
                            </Button>
                        </BottomNavigation></>}
                </div>
            )}
            <Modal open={loading} className={classes.modal}><LoadingComponent /></Modal>
        </>
    )
}