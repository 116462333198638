import { List, ListItem, ListItemText, Typography, ListItemAvatar, Button, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "./declined.style";
import GetAppIcon from '@material-ui/icons/GetApp';
import { DelarOrderProduct, toPackString } from "../../../../../../service/response/shipement.response";
import { Media } from "../../../../../../model/media.model";
import { useTranslation } from "react-i18next";


export const DeclinedOrderDetailComponent: React.FC<{ productList: Array<DelarOrderProduct> }> = ({ productList }) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        let _totalAmount = 0
        productList.map((product) => {
            _totalAmount = _totalAmount + product.pack.sellingPrice * product.pack.quantity
        })
        setTotalAmount(_totalAmount/100)
    }, [productList])


    return (
        <React.Fragment>
            <Typography variant = "h6" className = {classes.topHeading}>
                <b>{t("common_product_declined")}</b>
            </Typography>
            <Divider/>
            <List dense style={{ width: '100%', backgroundColor: 'background.paper' }}>
                {productList.map((product: DelarOrderProduct, index: any) => {
                    const labelId = `${index}`
                    let photo = new Map(Object.entries(product.product.photos)).values().next().value as Media
                    return (
                        <>
                        <ListItem style = {{position: 'relative', paddingLeft: 16}}>   
                        <ListItemAvatar style = {{ top:0,width: 60, height: 60  }}>
                            <img style = {{width: 60, height: 60,}}
                                alt={`Avatar ${photo.fid}`}
                                src={new Map(Object.entries(photo.urls)).get('main') as string}
                            />
                        </ListItemAvatar>
                        <ListItemText disableTypography id={labelId} primary={
                            <Typography variant = "body2" className = {classes.heading}>
                                <b>{product.product.name} ({toPackString(product.pack)})</b>
                            </Typography>
                            } 
                            secondary={<div className = {classes.secondaryHolder}>

                                <div className = {classes.secondaryContainer}>
                                    <Typography variant = "subtitle1" className = {classes.description}>
                                        {product.product.company.name}
                                    </Typography>
                                </div>
                                <div className = {classes.secondaryContainer}>
                                    <Typography variant = "subtitle1" className = {classes.info}>
                                        <b>{t("common_qty")}: {product.pack.quantity}({(product.pack.sellingPrice/100).toFixed(0)})</b>
                                    </Typography>
                                    <Typography variant = "subtitle1" className = {classes.info}>
                                        <b>{(product.pack.quantity * product.pack.sellingPrice/100).toFixed(0)}</b>
                                    </Typography>
                                </div>
                                </div>
                            }/>
                        
                        </ListItem>
                        <Divider />
                        </>
                    );
                    })}
            </List>
            <div className = {classes.secondaryContainer}>
                <Typography variant = "subtitle1" className = {classes.totalText}>
                    <b>{t("common_grand_total")}</b>
                </Typography>
                <Typography variant = "subtitle1" className = {classes.priceText}>
                    <b>{totalAmount.toFixed(0)}</b>
                </Typography>
            </div>
        </React.Fragment>
    )
}