import { AppBar, Button, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MobileStepper, Modal, Switch, Toolbar, Typography, useTheme } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import SwipeableViews from 'react-swipeable-views'
import { useStyles } from './product-info.style'
import { useObservable } from "../../../../useSubscriber"
import { ProductStore } from "../../../../store/product/product.store"
import { ProductActor } from "../../../../actor/product/product.actor"
import { ProductAction } from "../../../../action/product/product.action"
import { Media } from "../../../../model/media.model"
import { useRouteManager } from "../../../../router/route.manager"
import { useNavigate, useParams } from "react-router-dom"
import LoadingComponent from "../../../common/loading/loading.component"
import { useTranslation } from "react-i18next"
import { AddProductAction } from "../../../../action/product/add-product.action"
import { AddProductActor } from "../../../../actor/product/add-product.actor"
import { ProductPack } from "../../../../model/product-pack.model"
import { DelarProductPackDto } from "../../../../service/dto/product.dto"
import { DelarProductInfoRes } from "../../../../service/response/product.response"

export const ProductInfoComponent = () => {

    const { productId } = useParams()

    const routeManager = useRouteManager();
    const navigate = useNavigate()
    const classes = useStyles();
    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const _productInfo = useRef<DelarProductInfoRes>()

    const [productInfo, setProductInfo] = useState({ ..._productInfo.current })

    const [newProductInfo, _] = useObservable(ProductStore.getDelarProductInfo(), ProductStore.getDelarProductInfoObservable())
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        if (newProductInfo) {
            _productInfo.current = newProductInfo
            setProductInfo({ ...newProductInfo })
        }
    }, [newProductInfo])

    useEffect(() => {
        ProductActor({ "type": ProductAction.GET_PRODUCT_INFO }, { "delarProductID": productId })
    }, [])

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const handlePackUpdate = () => {
        routeManager.openProductPackSizePage(productId)
    };

    const afterPackUpdate = () => {
        setLoading(false)
    }

    const handleInStock = (e: any, newPack: ProductPack) => {
        setLoading(true)

        _productInfo.current.packs.map(pack => {
            if (pack.size == newPack.size && pack.unit == newPack.unit) {
                pack.inStock = e.target.checked
                newPack.inStock = e.target.checked
            }
        })

        setProductInfo({ ..._productInfo.current })

        const delarPackDto = {
            delarId: _productInfo.current.delarId,
            packs: _productInfo.current.packs,
            productId: _productInfo.current.productId,
            active: true
        } as DelarProductPackDto

        AddProductActor({ "type": AddProductAction.UPDATE_PACK_DATA }, { "delarPackDto": delarPackDto, "afterPackUpdate": afterPackUpdate })
    }

    return (
        <>
            {productInfo && productInfo?.id?.length > 0 && (
                <React.Fragment>
                    <AppBar position="sticky" style={{ boxShadow: 'none' }}>
                        <Toolbar>
                            <ArrowBackIcon className={classes.backIcon} onClick={() => { navigate(-1) }} />
                            <Typography variant="h6" className={classes.title}>
                                {productInfo.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {productInfo?.name && (
                        <React.Fragment>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {productInfo.photoList.map((media, index) => (
                                    <div key={`media_${index}`} style={{ width: '100%', position: 'relative', }}>
                                        {Math.abs(activeStep - index) <= 2 ? (

                                            <img className={classes.img} src={new Map(Object.entries((new Map(Object.entries(productInfo.photos)).values().next().value as Media).urls)).get('main') as string} alt={`media_${index}`} />
                                        ) : null}
                                        <div className={classes.iconHolder}>
                                            <DeleteIcon style={{ width: 20, height: 20, opacity: 0.5, }} />
                                        </div>
                                        <div className={classes.iconHolder} style={{ top: 64 }}>
                                            <img src={'/asset/share icon.svg'} style={{ height: 20, width: 20 }}></img>
                                        </div>
                                    </div>
                                ))}
                            </SwipeableViews>
                        </React.Fragment>
                    )}
                    <MobileStepper
                        steps={productInfo.photoList.length}
                        position="static"
                        variant="dots"
                        activeStep={activeStep}
                        nextButton={null}
                        backButton={null}
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff' }} />

                    {/* <Divider style={{opacity: 0.5}}/>
            <div style={{ display: "flex", width: "100%", }}>
                <img alt={'graphIcon'} src={'../asset/graph.svg'} className={classes.graphIcon} />
                <Typography variant="subtitle1" className={classes.title1}>
                    <b> 10000 Product Views </b>
                </Typography>
            </div> */}
                    <Divider style={{ marginTop: 8, marginBottom: 12, opacity: 0.5 }} />
                    {productInfo?.name && productInfo?.name.length > 0 && (
                        <React.Fragment>
                            <Typography variant="h6" className={classes.title2}>
                                <b> {productInfo.name} </b>
                            </Typography>
                        </React.Fragment>
                    )}
                    {productInfo?.company?.name && productInfo?.company?.name.length > 0 && (
                        <React.Fragment>
                            <Typography variant="subtitle1" className={classes.title3}>
                                {productInfo.company.name}
                            </Typography>
                        </React.Fragment>
                    )}
                    <Typography variant="h6" className={classes.title2}>
                        <b> {t("chemical")} </b>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.title3}>
                        {productInfo?.chemical?.name}
                    </Typography>
                    <Typography variant="h6" className={classes.title2}>
                        <b> {t("about")} </b>
                    </Typography>
                    {productInfo?.description && productInfo?.description.length > 0 && (
                        <React.Fragment>
                            <Typography paragraph variant="subtitle1" className={classes.title4}>
                                <div dangerouslySetInnerHTML={{__html: productInfo.description}}/>
                            </Typography>
                        </React.Fragment>
                    )}
                    <Typography variant="h6" className={classes.title2}>
                        <b> {t("dosage")} </b>
                    </Typography>
                    {productInfo?.application && productInfo?.application.length > 0 && (
                        <React.Fragment>
                            <Typography variant="subtitle1" className={classes.title3}>
                                {productInfo.application}
                            </Typography>
                        </React.Fragment>
                    )}
                    <Divider />
                    {productInfo && productInfo.packs && productInfo.packs.filter(pack => {
                        return pack.enabled
                    }).length > 0 && (
                            <React.Fragment>
                                <Typography variant="h6" className={classes.packTitle}>
                                    <b>{t("your_pack_sizes")}</b>
                                </Typography>
                                <Divider />
                            </React.Fragment>
                        )}

                    <Grid container spacing={0} style={{ backgroundColor: '#F4F4F4' }}>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" className={classes.packSizeText}>
                                {t("product_pack_size")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" className={classes.packSizeText}>
                                {t("product_pack_selling_price")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" className={classes.packSizeText}>
                                {t("product_pack_mrp")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" className={classes.packSizeText}>
                                {t("product_pack_stock")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />

                    {productInfo && productInfo.packs && productInfo.packs.length > 0 && (
                        <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 50, paddingTop: 0 }}>
                            {productInfo.packs.filter(pack => {
                                return pack.enabled
                            }).map((pack, index) => {
                                const labelId = "" + pack.unit;
                                let si: string
                                let cgs: string
                                if (pack.unit === 1) {
                                    si = "kg"
                                    cgs = "gm"
                                }
                                else if (pack.unit === 2) {
                                    si = "L"
                                    cgs = "ml"
                                }
                                else {
                                    si = "unit"
                                    cgs = "unit"
                                }
                                return (
                                    <>
                                        <Grid container spacing={0}>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" className={classes.packSizeText}>
                                                    {pack.size >= 1000 ? (pack.size) / 1000 : (pack.size)} {pack.size >= 1000 ? si : cgs}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" className={classes.packSizeText}>
                                                    ₹ {pack.sellingPrice / 100}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" className={classes.packSizeText} style={{ textDecoration: "line-through" }}>
                                                    ₹ {pack.mrp / 100}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {pack.inStock && (
                                                    <Typography variant="subtitle1" className={classes.stockText}>
                                                        {t("in_stock")}
                                                    </Typography>
                                                )}
                                                {!pack.inStock && (
                                                    <Typography variant="subtitle1" className={classes.stockText2}>
                                                        {t("out_of_stock")}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </>
                                );
                            })}
                        </List>
                    )}
                    {productInfo && productInfo.packs && productInfo.packs.length > 0 && (
                        <React.Fragment>
                            <div className={classes.bottomTextButton}>
                                <Typography variant="body2" className={classes.bottomText}>
                                    {t("add_or_edit_all_available_pack_size")}
                                </Typography>
                                <Button variant="contained" color="primary" className={classes.manageButton} onClick={handlePackUpdate} >
                                    <Typography style={{ opacity: 0.8, fontSize: 12 }}>
                                        {t("manage_pack_size")}
                                    </Typography>
                                </Button>
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Modal open={!productInfo || productInfo?.id?.length <= 0} className={classes.modal}><LoadingComponent /></Modal>
        </>
    )
}