import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        heading: {
            fontSize: 13,
            fontWeight: 600,

        },
        description: {
            fontSize: 12,
            opacity: 0.7,
        },
        time: {
            
            fontSize: 10,
            opacity: 0.6,
        },
        primaryContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
        },
        secondaryHolder: {
            display: 'flex',
            flexDirection: 'column',
        },
        bigImageHolder: {
            display: 'flex',
            flexDirection: 'column',
        },
        bigImage: {
            position: 'relative',
            height: 214,
            display: 'block',
            overflow: 'hidden',
            width: '100%',
            zIndex: 0.8,
        },
        secondaryContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        buttonHolder: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 16,
            marginBottom: 16,
            
            
        },
        button: {
            
            textTransform: 'capitalize',
            padding: 10,
            fontSize: 12,
            opacity: 0.8,
            width: 132,
            height: 34,
            marginRight: 9,
            
            fontWeight: 500,
            borderColor: '#0e4bb4',
        },
    }),
);