import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginBottom: 16,
        },
        buttonContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
            marginTop:8,
            marginBottom:8,
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        supportButton: {
            width:124, 
            height: 40,
            marginLeft:'20%',
            borderRadius: 30,
            textTransform: 'capitalize',
            fontSize:14,
            backgroundColor: '#ffffff',
            color:'#418EF2',
            boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 1px 3px 2px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
        },
        statusHeadingNew: {
            marginLeft: 16,
            marginTop: 12,
            marginBottom: 12,
            fontSize: 16,
            fontWeight: 600,
            color: '#1CCB2D',
        },
        statusHeadingPending: {
            marginLeft: 16,
            marginTop: 12,
            marginBottom: 12,
            fontSize: 16,
            fontWeight: 600,
            color: '#D6D000',
        },
        statusHeadingReturned: {
            marginLeft: 16,
            marginTop: 12,
            marginBottom: 12,
            fontSize: 16,
            fontWeight: 600,
            color: '#E6582D',
        },
        time: { 
            marginRight: 16,
            marginTop: 12,
            marginBottom: 12,
            fontSize: 12,
            opacity: 0.6,
        },
        primaryContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
        },
        deliveryVillage: {
            marginLeft: 16,
            marginTop: 12,
            marginBottom: -4,
            fontSize: 14,
        },
        orderTotal: {
            marginLeft: 16,
            marginTop: 12,
            fontSize: 14,
        },
        shippingAddress: {
            marginLeft: 16,
            marginTop: 12,
            fontSize: 16,
            fontWeight: 600,
        },
        shippingAddressName: {
            marginLeft: 16,
            marginTop: 8,
            fontSize: 14,
        },
        shippingAddressLine1: {
            marginLeft: 16,
            marginTop: 4,
            fontSize: 14,
        },
        shippingAddressLine2: {
            marginLeft: 16,
            marginTop: 4,
            marginBottom: 12,
            fontSize: 14,
        },
        shareDiv: {
            display:"flex",
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',   
        },
        detailDiv: {
            display:"flex",  
            flexDirection: 'column', 
        },
        shareButton: {
            width:152, 
            height: 30,
            marginTop: 12, 
            marginRight: 16,
            backgroundColor: '#D1DAFF',
            borderRadius: 15,
            textTransform: 'capitalize',
            fontSize:13,
        },
        contactDetails: {
            marginLeft: 16,
            marginTop: 12,
            fontSize: 14,
        },
        deliveryTime: {
            marginLeft: 16,
            marginTop: 4,
            fontSize: 14,
        },
        nextButton: {
            width: "90%",
            maxWidth: 400,
            margin: "auto",
            display: "flex",
            textTransform: 'capitalize',
            background: '#0E4BB4',
            fontSize: 14,
            height: 49,
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            maxWidth: 720,
            height: 79,
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            opacity: 0.5,
        }
    }),
);