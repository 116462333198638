import { AndroidConnerctor } from "../android.connecter"
import { DELAR_APP_LANG_CODE, DELAR_APP_SESSION } from "../constant/base.constant"
import { DelarStatus } from "../enum/delar-status.enum"
import { Session } from "../model/session.model"

const Android = (window as any).Android

const getSession = (): Session => {
    if (Android) {
        try {
            let session: Session = JSON.parse(`${AndroidConnerctor.getSession()}`)
            const newSession = JSON.parse(String(session))
            return newSession
        } catch {
            const newSession = JSON.parse('{}') as Session
            return newSession
        }
    } else {
        const session = JSON.parse(localStorage.getItem(DELAR_APP_SESSION) || '{}') as Session
        return session
    }
   
}

export const SecurityManager = {

    loggedIn: (): boolean => {
        let session = getSession()

        if (session && session.token && session.token.length > 0) {
            return true
        } else {
            return false
        }
    },

    setSession(session: Session) {
        if (Android) {
            AndroidConnerctor.setSession(session)
        } else {
            localStorage.setItem(DELAR_APP_SESSION, JSON.stringify(session))
        }
    },

    setLanguage(lang: string) {
        if (Android) {
            AndroidConnerctor.setLanguage(lang)
        } else {
            localStorage.setItem(DELAR_APP_LANG_CODE, lang)
        }
    },

    getToken: (): string => {
        let session = getSession()
        
        if (session && session.token && session.token.length > 0) {
            return session.token
        } else {
            return ''
        }
    },

    getUid: (): string => {
        let session = getSession()

        if (session && session.id && session.id.length > 0) {
            return session.id
        } else {
            return ''
        }
    },

    getVerified: (): number => {
        let session = getSession()
        if (session && session.id && session.id.length > 0) {
            return session.verified
        } else {
            return DelarStatus.NotInit
        }
    },

    logout() {
        try {
            localStorage.removeItem(DELAR_APP_SESSION)
            AndroidConnerctor.removeSession()
            window.location.reload()
        } catch (err) {
            console.error(err)
        }
    },
    getLanguage: (): string => {
        if (Android) {
            return AndroidConnerctor.getLanguage()
        } else {
            const lang = localStorage.getItem(DELAR_APP_LANG_CODE)
            if(lang && lang.length > 0) {
                return lang
            }
            return "en"
        }
    },
}