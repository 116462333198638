import { AppBar, Avatar, BottomNavigation, Button, Card, Checkbox, Chip, Divider, InputBase, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Modal, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useStyles } from './add-village-common.style'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { Village, VillageData } from "../../../service/response/village.response"
import { useNavigate } from "react-router"
import CloseIcon from '@material-ui/icons/Close';
import LoadingComponent from "../loading/loading.component"
import { SecurityManager } from "../../../security/security.manager"
import { DelarService } from "../../../service/delar.service"
import { AddressService } from "../../../service/address.service"
import { VillageSearchDto } from '../../../service/dto/village-search.dto'
import { useRouteManager } from "../../../router/route.manager"


export const CommonAddVillageComponent = (props: any) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const [villageData, setVillageData] = useState<Array<VillageData>>([])
    useEffect(()=>{
        props.setLoading(true)
        DelarService.getDelarById(SecurityManager.getUid()).then((res) => {
            AddressService.getVillageBySubDistrictCode(res.data.shopAddress.geoAddress.subDistrict.code).then((result) => {
                setVillageData([...result.data])
                props.setLoading(false)
            })
        })
    }, [])
    const handleSearch = (e: any) => {
        let searchDto = {limit: 500} as VillageSearchDto
        searchDto.name = e.target.value
        AddressService.getVillageBySearch(searchDto).then((res) => {
            if(res.data)
            setVillageData([...res.data])
            else
            setVillageData([])
        })
    }
    const checkSelect = (villageId: any): boolean => {
        let flag = false;
        props.selectedVillageData.map((village: Village)=> {
            if(village.id === villageId)
            {
                flag = true;
                return;
            }
        })
        return flag;
    }
    const handleToggle = (event: any, village: VillageData) => {
        if(event.target.checked)
        {
            props.selectedVillageData.push(village)
            props.setSelectedVillageData([...props.selectedVillageData])
        }
        else
        {
            let idx = -1;
            props.selectedVillageData.map((selectVillage: Village, index: number) => {
                if(village.id === selectVillage.id)
                {
                    idx = index
                    return;
                }
            })
            props.selectedVillageData.splice(idx, 1)
            props.setSelectedVillageData([...props.selectedVillageData])
        }
    }
    const handleDelete = (village: Village) => {
        let idx = -1;
            props.selectedVillageData.map((selectVillage: Village, index: number) => {
                if(village.id === selectVillage.id)
                {
                    idx = index
                    return;
                }
            })
            props.selectedVillageData.splice(idx, 1)
            props.setSelectedVillageData([...props.selectedVillageData])
    }
    return(
        <div className = {classes.root}>
            <AppBar position="sticky" style={{boxShadow:'none'}}>
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e: any) => { handleSearch(e) }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            
            { props.selectedVillageData && props.selectedVillageData.length > 0 && (
                <Card className={classes.topButtonCard} variant="outlined">
                {props.selectedVillageData && props.selectedVillageData.length > 0 && (
                    <div className = {classes.chipHolder}>
                        {props.selectedVillageData.map((selectedVillage: any, index: number) => {
                            return (
                                <Chip
                                    key={selectedVillage.id}
                                    label={selectedVillage.name}
                                    onDelete={() =>{handleDelete(selectedVillage)}}
                                    className = {classes.chip}
                                    deleteIcon = {<CloseIcon style = {{color: '#000', width: 15,height: 15,}}/>}
                                />
                            );
                        })}
                    </div>
                )}
                </Card>
            )}
            
            {!props.loading &&
            <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 40, marginTop: -8, }}>
                {villageData.map((village, index) => {
                    const labelId = `${village.id}`
                    let found = checkSelect(village.id)
                    return (
                        <>
                        <ListItem className = {index%2 === 0? classes.evenItem: classes.oddItem}key={index} button>
                                <ListItemIcon>
                                { (
                                    <Checkbox
                                        edge="end"
                                        onChange={(e: any) => { handleToggle(e, village) }}
                                        checked={found}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        color="primary"
                                        style={{marginLeft:-8,}}
                                    />
                                )}
                                </ListItemIcon>
                            <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.villageName}>
                                {village.name}, {village.subDistrict.name}, {village.district.name}, {village.state.name}
                            </Typography>}
                                 />
                            
                        </ListItem>
                        </>
                    );
                })}
            </List>
            }
            <Modal open={props.loading} className={classes.modal}><> <LoadingComponent /></></Modal>
            </div>
    )
}