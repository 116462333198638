import { AppBar, Avatar, Button, Card, Chip, Divider, Fab, InputBase, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Modal, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useStyles } from './product.style'
import SearchIcon from '@material-ui/icons/Search'
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../router/route.manager";
import { useObservable } from "../../../useSubscriber";
import { ProductStore } from "../../../store/product/product.store";
import { ProductActor } from "../../../actor/product/product.actor";
import { ProductAction } from "../../../action/product/product.action";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FilterComponent } from './filter-page/filter.component';
import InfinitScroll from 'react-infinite-scroll-component'
import LoadingComponent from "../../common/loading/loading.component";
import { ShopCompany } from "../../../service/response/shop-company.response"
import { Media } from "../../../model/media.model"
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { AddProductZeroComponent } from "../home/zero condition cards/add-product-zero.component"
import { ProductItemComponent } from "./product-item.component"
import { AndroidConnerctor } from "../../../android.connecter"
import { toast } from "react-toastify"
export let idSelected: string;

export const ProductComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const routeManager = useRouteManager();

    const [delarProductList, _] = useObservable(ProductStore.getDelarProductList(), ProductStore.getDelarProductListObservable())
    const [filterCompanies, __] = useObservable(ProductStore.getFilterCompanies(), ProductStore.getFilterCompaniesObservable())
    const [productSearchDto] = useObservable(ProductStore.getDelarProductSearchDto(), ProductStore.getDelarProductSearchDtoObservable())

    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(false)

    const [companyLoading, setCompanyLoading] = useState(false)

    const [showAddPackBanner, setShowAddPackBanner] = useState(false)

    const timeOut = useRef<any>(0)

    const afterDataFetch = () => {
        setLoading(false)
    }

    const afterCompanyFetch = () => {
        setCompanyLoading(false)
    }

    const handleSearch = (e: any) => {
        if (e.target.value.length === 0 && productSearchDto.searchText && productSearchDto.searchText.length == 0) {
            return
        }

        setLoading(true)
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
            ProductActor({ "type": ProductAction.UPDATE_SEARCH_TEXT }, { "searchText": e.target.value, "afterDataFetch": afterDataFetch })
        }, 500);
    }

    const handlePagination = () => {
        ProductActor({ "type": ProductAction.GET_LIST }, { "setHasMore": setHasMore })
    };

    useEffect(() => {
        setLoading(true)
        ProductActor({ "type": ProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST }, {})
        ProductActor({ "type": ProductAction.GET_LIST }, { "afterDataFetch": afterDataFetch, "setHasMore": setHasMore })
    }, [])

    const [openFilter, setOpenFilter] = useState(false);

    const applyCompaniesFiler = (companies: Array<ShopCompany>) => {
        setLoading(true)
        setCompanyLoading(true)
        ProductActor({ "type": ProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST }, {})
        ProductActor({ "type": ProductAction.UPDATE_FILTER_COMPANY }, { "companies": companies, "afterDataFetch": afterCompanyFetch })
        ProductActor({ "type": ProductAction.GET_LIST }, { "afterDataFetch": afterDataFetch })
    };

    const handleCompanyDelete = (company: ShopCompany) => {
        setLoading(true)
        ProductActor({ "type": ProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST }, {})
        ProductActor({ "type": ProductAction.REMOVE_FILTER_COMPANY }, { "company": company })
        ProductActor({ "type": ProductAction.GET_LIST }, { "afterDataFetch": afterDataFetch })
    };

    return (
        <React.Fragment>
            {((delarProductList && delarProductList.length > 0) || (productSearchDto.searchText && productSearchDto.searchText.length > 0) || (productSearchDto.companyIds && productSearchDto.companyIds.length > 0)) && (
                <AppBar position="static" className={classes.appbar}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={t("common_search_product_or_company")}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            defaultValue={productSearchDto.searchText}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e: any) => { handleSearch(e) }}
                        />
                    </div>
                </AppBar>
            )}

            {!loading && (
                <>
                    {((delarProductList && delarProductList.length > 0) || (productSearchDto.searchText && productSearchDto.searchText.length > 0) || (productSearchDto.companyIds && productSearchDto.companyIds.length > 0)) && (
                        <>
                            {openFilter && (
                                <FilterComponent loading={companyLoading} open={openFilter} handleClose={() => setOpenFilter(false)} applyFilter={(companies: Array<ShopCompany>) => { applyCompaniesFiler(companies) }} />
                            )}
                            <div className={classes.topMenu}>
                                <Card className={classes.topButtonCard} style={{ width: '60%' }} variant="outlined">
                                    {filterCompanies && filterCompanies.length > 0 && (
                                        <React.Fragment>
                                            {filterCompanies.map((company, index) => {
                                                return (
                                                    <Chip
                                                        key={company.id}
                                                        label={company.name}
                                                        onDelete={(event: any) => handleCompanyDelete(company)}
                                                        className={classes.chip}
                                                        deleteIcon={<CloseIcon style={{ color: '#000', width: 16, height: 16 }} />}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </Card>


                                <Button color='inherit' className={classes.buttonStyle} onClick={() => setOpenFilter(true)}>
                                    <img src={"/asset/filter-solid.svg"} style={{ marginRight: 10, height: 12, width: 12 }}></img>
                                    {t("common_filter_company")}
                                </Button>

                            </div>
                        </>
                    )}
                    {showAddPackBanner && (
                        <div className={classes.fillPackNoti}>
                            <span >{t("product_fill_selected_pack_sizes")}</span>
                            <ArrowForwardIosIcon style={{ position: 'relative', marginTop: 6, right: 13, fontSize: 10, }} />
                        </div>
                    )}
                    {delarProductList && delarProductList.length > 0 && (
                        <InfinitScroll
                            dataLength={delarProductList.length}
                            next={handlePagination}
                            hasMore={hasMore}
                            loader={<LoadingComponent />}
                        >
                            <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginTop: -5, marginBottom: 50 }}>

                                {delarProductList.map((product, index) => {
                                    return <ProductItemComponent product={product} />
                                })}
                            </List>
                        </InfinitScroll>
                    )}
                    {delarProductList && delarProductList.length === 0 && (!productSearchDto.searchText || productSearchDto.searchText.length == 0) && (!productSearchDto.companyIds || productSearchDto.companyIds.length == 0) && (
                        <div style={{ "marginTop": 120 }}>
                            <AddProductZeroComponent />
                        </div>
                    )}
                    {((delarProductList && delarProductList.length === 0) && (productSearchDto.searchText && productSearchDto.searchText.length > 0) || (productSearchDto.companyIds && productSearchDto.companyIds.length > 0)) && (
                        <div style={{ "marginTop": 120 }}>
                            <React.Fragment>
                                <div style={{ display: "inline-grid" }}>
                                    <img src='/asset/product unavailable.svg' className={classes.img} />
                                    <Typography variant="body2" className={classes.heading}>
                                        <b>{t("add_product_product_unavailable")}</b>
                                    </Typography>
                                    <Typography variant="body2" className={classes.description}>
                                        {t("add_products_not_available_description")}
                                    </Typography>
                                </div>
                            </React.Fragment>
                        </div>
                    )}
                </>
            )}
            {loading && (
                <LoadingComponent />
            )}
            {delarProductList && delarProductList.length > 0 && (
                <Fab variant="extended" color="primary" className={classes.floatingIcon}
                    onClick={() => { routeManager.openAddProduct() }}>
                    <AddIcon style={{ fontSize: 20, marginRight: 9.75 }} />{t("add_product")}
                </Fab>
            )}
        </React.Fragment>
    )
}