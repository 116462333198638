import { AppBar, BottomNavigation, Button, Card, Checkbox, Chip, Divider, InputBase, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Modal, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useStyles } from './add-product.style'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { AddProductActor } from "../../../../actor/product/add-product.actor"
import { AddProductAction } from "../../../../action/product/add-product.action"
import { AddProductStore } from "../../../../store/product/add-product.store"
import { useObservable } from "../../../../useSubscriber"
import { DelarProductSearchResult } from "../../../../service/response/product.response"
import { Media } from "../../../../model/media.model"
import CloseIcon from '@material-ui/icons/Close';
import InfinitScroll from 'react-infinite-scroll-component';
import LoadingComponent from "../../../common/loading/loading.component"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../../router/route.manager"
import { ShopCompany } from "../../../../service/response/shop-company.response"
import { FilterComponent } from "../filter-page/filter.component"
import { ProductItemView } from "./product-item.view"

export const AddProductComponent = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const [productList, _] = useObservable(AddProductStore.getProductList(), AddProductStore.getProductListObservable())
    const [filterCompanies, __] = useObservable(AddProductStore.getFilterCompanies(), AddProductStore.getFilterCompaniesObservable())
    const [selectedProductList, ___] = useObservable(AddProductStore.getSelectedProductList(), AddProductStore.getSelectedProductListObservable())
    // const selectedProductList = AddProductStore.getSelectedProductList()
    const [addedProductList, ____] = useObservable(AddProductStore.getAddedProductList() as any, AddProductStore.getAddedProductListObservable() as any)

    const [productSearchDto] = useObservable(AddProductStore.getProductSearchDto(), AddProductStore.getProductSearchDtoObservable())

    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(false)
    const [companyLoading, setCompanyLoading] = useState(false)
    const [productSaving, setProductSaving] = useState(false);

    useEffect(() => {
        if (addedProductList && productSaving) {
            if (addedProductList.length === 1) {
                setProductSaving(false)
                routeManager.openProductPackSizePage(addedProductList[0].id)
            }
            else if (addedProductList.length > 1) {
                setProductSaving(false)
                routeManager.openMultipleProductSelectPage()
            } else {
                setProductSaving(false)
            }
        }
    }, [addedProductList])

    const handleDelete = (product: DelarProductSearchResult) => () => {
        AddProductActor({ "type": AddProductAction.DELETE_DELAR_PRODUCT_DTO }, { selectedProduct: product })
    };

    const handleUpdate = () => {
        setProductSaving(true)
        AddProductActor({ "type": AddProductAction.SAVE_DATA }, {})
    };

    const timeOut = useRef<any>(0)

    const afterDataFetch = () => {
        setLoading(false)
    }

    const afterCompanyFetch = () => {
        setCompanyLoading(false)
    }

    const handleSearch = (e: any) => {
        if (e.target.value.length === 0 && productSearchDto.searchText && productSearchDto.searchText.length == 0) {
            return
        }

        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
            AddProductActor({ "type": AddProductAction.UPDATE_SEARCH_TEXT }, { "searchText": e.target.value, "afterDataFetch": afterDataFetch })
        }, 500);
    }

    const handlePagination = () => {
        AddProductActor({ "type": AddProductAction.GET_LIST }, { "setHasMore": setHasMore })
    };

    useEffect(() => {
        setLoading(true)
        AddProductActor({ "type": AddProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST }, {})
        AddProductActor({ "type": AddProductAction.GET_LIST }, { "afterDataFetch": afterDataFetch })
    }, [])

    const [openFilter, setOpenFilter] = useState(false);

    const applyCompaniesFiler = (companies: Array<ShopCompany>) => {
        setLoading(true)
        setCompanyLoading(true)
        AddProductActor({ "type": AddProductAction.UPDATE_FILTER_COMPANY }, { "companies": companies, "afterDataFetch": afterCompanyFetch })
        AddProductActor({ "type": AddProductAction.GET_LIST }, { "afterDataFetch": afterDataFetch })
    };


    const handleCompanyDelete = (company: ShopCompany) => () => {
        setLoading(true)
        AddProductActor({ "type": AddProductAction.REMOVE_FILTER_COMPANY }, { "company": company })
        AddProductActor({ "type": AddProductAction.GET_LIST }, { "afterDataFetch": afterDataFetch })
    };

    return (
        <>
            {!loading && !productSaving && (
                <div className={classes.root}>
                    <AppBar position="sticky" style={{ boxShadow: 'none' }}>
                        <Toolbar style={{ maxWidth: '90%', }}>
                            <ArrowBackIcon className={classes.backIcon} onClick={() => { routeManager.goBack(); }} />
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t("common_search_product_or_company")}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e: any) => { handleSearch(e) }}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>

                    {openFilter && (
                        <FilterComponent loading={companyLoading} open={openFilter} handleClose={() => setOpenFilter(false)} applyFilter={(companies: Array<ShopCompany>) => { applyCompaniesFiler(companies) }} />
                    )}
                    <div className={classes.topMenu}>
                        <Card className={classes.topButtonCard} style={{ width: '60%' }} variant="outlined">
                            {filterCompanies && filterCompanies.length > 0 && (
                                <React.Fragment>
                                    {filterCompanies.map((company, index) => {
                                        return (
                                            <Chip
                                                key={company.id}
                                                label={company.name}
                                                onDelete={handleCompanyDelete(company)}
                                                className={classes.chip}
                                                deleteIcon={<CloseIcon style={{ color: '#4b514f', width: 16, height: 16 }} />}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </Card>
                        <Button color='inherit' className={classes.buttonStyle} onClick={() => setOpenFilter(true)}>
                            <img src={"/asset/filter-solid.svg"} style={{ marginRight: 10, height: 12, width: 12 }}></img>
                            {t("common_filter_company")}
                        </Button>
                    </div>
                    {selectedProductList && selectedProductList.length > 0 && (
                        <React.Fragment>
                            <Card className={classes.topButtonCard} variant="outlined">
                                {selectedProductList.map((product, index) => {
                                    return (
                                        <Chip
                                            key={product.id}
                                            label={product.name}
                                            onDelete={handleDelete(product)}
                                            className={classes.chip}
                                            style={{ borderRadius: 15, }}
                                            deleteIcon={<CloseIcon style={{ color: '#4b514f', width: 16, height: 16, }} />}
                                        />
                                    );
                                })}
                            </Card>
                        </React.Fragment>
                    )}
                    {productList && productList.length > 0 && (
                        <InfinitScroll
                            dataLength={productList.length}
                            next={handlePagination}
                            hasMore={hasMore}
                            loader={<LoadingComponent />}
                        >
                            <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 40, marginTop: -5 }}>
                                {productList.map((product, index) => {
                                   return <ProductItemView key={product.id} product={product}/>
                                })}
                            </List>
                        </InfinitScroll>
                    )}
                    {productList && productList.length === 0 && (
                        <React.Fragment>
                            <div style={{display: "inline-grid"}}>
                                <img src='/asset/product unavailable.svg' className={classes.img} />
                                <Typography variant="body2" className={classes.heading}>
                                    <b>{t("add_product_product_unavailable")}</b>
                                </Typography>
                                <Typography variant="body2" className={classes.description}>
                                    {t("add_products_not_available_description")}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}
                    <BottomNavigation showLabels className={classes.bottomNavigation}>
                        {selectedProductList.length > 0 && <Button className={classes.nextButton} variant="contained" color="primary" onClick={handleUpdate}>
                            {t("products_to_shop_start")} {selectedProductList?.length} {t("products_to_shop_end")}
                        </Button>}
                        {selectedProductList.length === 0 && <Button disabled className={classes.nextButton} style={{ backgroundColor: '#b1b1b1', color: '#ffffff' }} variant="contained" color="primary" >
                            {t("products_to_shop_start")} {t("products_to_shop_end")}
                        </Button>}
                    </BottomNavigation>
                </div>
            )
            }
            <Modal open={loading || productSaving} className={classes.modal}><LoadingComponent /></Modal>
        </>
    )
}