import { FormControl, InputAdornment, OutlinedInput, TextField, Typography } from "@material-ui/core"
import React, {useEffect, useState} from "react"
import { DelarAction } from "../../../../action/delar.action";
import { DelarActor } from "../../../../actor/delar.actor";
import { DelarStore } from "../../../../store/delar.store";
import { useObservable } from "../../../../useSubscriber";
import { useStyles } from "./personal-detail.style"
import { useTranslation } from "react-i18next"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
export const PersonalDetailComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [delarDto, dtoe] = useObservable(DelarStore.getDelarDto(), DelarStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())

    const handleChangeName = (e: any) => {
        DelarActor({"type": DelarAction.UPDATE_NAME}, {"name": e.target.value})
    };

    const handleChangeAlternatePhone = (event: any) => {
        DelarActor({"type": DelarAction.UPDATE_ALTERNATE_NUMBER}, {"alternatePhone": unformatter(event.target.value)})
    };

    const handleChangeIntro = (e: any) => {
        DelarActor({"type": DelarAction.UPDATE_INTRO}, {"intro": e.target.value})
    };
    const unformatter = (value: string) => {
        let phoneValue = "";
        for(var i = 0; i < value.length; i ++)
        {
            if(value[i] === ' ')
            continue
            phoneValue += value[i];
        }
        return phoneValue
    }
    const formatter = (value: string) => {
        let formattedValue = "";
        let count = 0;
        for(var i = 0; i < value?.length; i ++)
        {
            if((count === 3 && value[i] !== ' ')|| (count === 6 && value[i] !== ' ')){
                formattedValue += " "
            }
            if(value[i] === ' ')
            continue
            formattedValue += value[i];
            count ++;

        }
        return formattedValue
    } 
    return (
        <div className={classes.root}>
            <Typography variant="h4" className={ classes.title1 } >
                <b>{t("onboarding_personal_details")}</b>
            </Typography>
            
                <React.Fragment>
                    <Typography variant="body1" className={ classes.title2 } style = {{marginTop: 12,}}>
                        <b>{t("onboarding_personal_details_name")}</b>
                    </Typography>
                    <form className={classes.formField} noValidate autoComplete="off">
                        <TextField 
                            value={delarDto?.name} 
                            id="outlined-basic" 
                            className={classes.textField1} 
                            label={delarDto?.name !== ""? "": <Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type your Name</Typography>} 
                            variant="outlined" 
                            classes = {{
                                root: delarDtoError?.name.error?classes.TextFieldRoot: classes.noStyle,
                            
                            }}
                            inputProps ={{
                                style:{
                                    height: 11
                                }
                            }
                            }
                            InputLabelProps = {{
                                shrink: false,
                            }}
                            onChange = {handleChangeName}/>
                        {delarDtoError?.name.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("onboarding_personal_name_error")}
                    </Typography> }
                    </form>
                    
                </React.Fragment>
            
            
                <React.Fragment>
                    <Typography variant="body1" className={ classes.title2 } >
                        <b>{t("onboarding_personal_details_alternate_phone_no")}</b>
                    </Typography>
                    <FormControl  className={classes.formField} variant="outlined">
                        <OutlinedInput
                            onChange={handleChangeAlternatePhone}
                            value ={formatter(delarDto?.alternatePhone)}
                            classes = {{
                                adornedStart: classes.adornedStart,
                                input: classes.input,
                                root: delarDtoError?.alternatePhone.error?classes.TextFieldRoot: classes.noStyle,
                                
                            }}
                            inputProps ={{
                                style: {
                                    height: 11,
                                }
                            }
                            }
                           startAdornment={<InputAdornment position="start" ><Typography variant = "body1" style = {{fontSize: 12}} >+91</Typography></InputAdornment>}
                        />
                        {delarDtoError?.alternatePhone.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("mobile_no_should_have_ten_digits")}
                        </Typography> }
                    </FormControl>
                    
                </React.Fragment>
            
            
                {/* <React.Fragment>
                    <Typography variant="body1" className={ classes.title2 } >
                        <b>{t("onboarding_personal_details_introduction")}</b>
                    </Typography>
                    <form className={classes.formField} style = {{paddingBottom: 100}} noValidate autoComplete="off">
                        <TextField
                            className={classes.textField1}
                            id="outlined-multiline-static"
                            multiline
                            label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Give your introduction</Typography>}
                            rows={4}
                            value={delarDto?.intro}
                            classes = {{
                                root: delarDtoError?.intro.error?classes.TextFieldRoot: classes.noStyle,
                            }}
                            InputLabelProps = {{
                                shrink: delarDto?.intro !== "",
                            }}
                            onChange={handleChangeIntro}
                            variant="outlined"
                        />  
                        {delarDtoError?.intro.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.intro?.msg}
                        </Typography> }
                    </form>
                </React.Fragment> */}
            
        </div>
    )
}