import { BehaviorSubject, Observable } from "rxjs"
import { ShopCompanyDto } from "../service/dto/shop-company.dto"
import { ShopCompany } from "../service/response/shop-company.response"

let _shopCompanyList: Array<ShopCompany> = []
let _shopCompanyListSubject = new BehaviorSubject<Array<ShopCompany> | null>(null)

let _shopCompanyDto: ShopCompanyDto = {limit:50 ,pageNumber: 0, orderType: -1} as ShopCompanyDto 
let _shopCompanyDtoSubject = new BehaviorSubject<ShopCompanyDto | null>(null)

let _selectedCompanies: Array<ShopCompany>

export const FilterStore = {
    reSetSearchDtoAndShopCompanyList: () => {
        _shopCompanyList = []
        _shopCompanyListSubject.next([..._shopCompanyList])

        _shopCompanyDto = {limit:50 ,pageNumber: 0, orderType: -1} as ShopCompanyDto 
        _shopCompanyDtoSubject.next(_shopCompanyDto)
    },

    upadteShopCompanyList: (newShopCompanyList: Array<ShopCompany>) => {
        newShopCompanyList.forEach(shopCompany => {
            if (_shopCompanyList.indexOf(shopCompany) == -1) {
                _shopCompanyList.push(shopCompany)
            }
        })
        _shopCompanyListSubject.next([..._shopCompanyList])
    },
    getShopCompanyList: (): Array<ShopCompany> | null => {
        return _shopCompanyList
    },
    getShopCompanyListObservable: (): Observable<Array<ShopCompany> | null> => {
        return _shopCompanyListSubject.asObservable()
    },

    upadteShopCompanyDto: (newShopCompanyDto: ShopCompanyDto) => {
        _shopCompanyDto = newShopCompanyDto
        _shopCompanyDtoSubject.next({ ..._shopCompanyDto })
    },
    updateSearchText: (searchText: string) => {
        _shopCompanyDto.searchText = searchText
        _shopCompanyDtoSubject.next({ ..._shopCompanyDto })
    },
    updatePageNumber: () => {
        _shopCompanyDto.pageNumber = _shopCompanyDto.pageNumber + 1
        _shopCompanyDtoSubject.next({ ..._shopCompanyDto })
    },
    getShopCompanyDto: (): ShopCompanyDto => {
        return _shopCompanyDto
    },
    
    getShopCompanyDtoObservable: (): Observable<ShopCompanyDto | null> => {
        return _shopCompanyDtoSubject.asObservable()
    },
    getSelectedCompanies: (): Array<ShopCompany> | null => {
        return _selectedCompanies 
    }
}