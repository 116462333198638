import firebase from "firebase"
import { ImageType, ImageUseType } from "../enum/image-type.enum"
import { Media } from "../model/media.model"

const metadata = {
    contentType: 'image/jpeg',
  };

  
export const StorageService = {
    uploadShopImage: (imageFile: File): Promise<Media> => {
        return new Promise<Media>(async (resolve, reject) => {
            const size = {
                width: 0,
                height: 0
            }

            let dummyImage = new Image()
            var binaryData = []
            binaryData.push(imageFile)
            dummyImage.src = window.URL.createObjectURL(new Blob(binaryData, {type: "image/*"}))

            dummyImage.onload = () => {
                size.width = dummyImage.width
                size.height = dummyImage.height
            }

            const imageMedia = {
                fid: '',
                reference: '',
                urls: new Map(),
                size: size,
                type: ImageType.IMAGE,
                useType: ImageUseType.NORMAL_MEDIA,
                createdOn: new Date(),
                modifiedOn: new Date()
            } as Media
            const storage = firebase.storage()
            const doc_ref = firebase.firestore().collection('shop').doc()

            imageMedia.fid = doc_ref.id
            imageMedia.reference = `shop/images/${doc_ref.id}`

            await storage.ref(`shop/images/${doc_ref.id}`).put(imageFile, metadata)

            const url = await storage.ref(`shop/images/${doc_ref.id}`).getDownloadURL()

            if (url.length <= 0) {
                reject("Url Failed")
            }
            imageMedia.urls = {
                'main': url,
                'thumb': url
            }

            resolve(imageMedia)
        });
    },
    uploadDocument: (imageFile: File): Promise<Media> => {
        return new Promise<Media>(async (resolve, reject) => {
            const size = {
                width: 0,
                height: 0
            }
            const imageMedia = {
                fid: '',
                reference: '',
                urls: new Map(),
                size: size,
                type: ImageType.IMAGE,
                useType: ImageUseType.NORMAL_MEDIA,
                createdOn: new Date(),
                modifiedOn: new Date()
            } as Media
            const storage = firebase.storage()
            const doc_ref = firebase.firestore().collection('delar-doc').doc()

            imageMedia.fid = doc_ref.id
            imageMedia.reference = `delar-doc/images/${doc_ref.id}`

            await storage.ref(`delar-doc/images/${doc_ref.id}`).put(imageFile, metadata)

            const url = await storage.ref(`delar-doc/images/${doc_ref.id}`).getDownloadURL()

            if (url.length <= 0) {
                reject("Url Failed")
            }
            imageMedia.urls = {
                'main': url,
                'thumb': url
            }

            resolve(imageMedia)
        });
    }
}

function StorageMetadata(contentType: any, arg1: string): firebase.storage.UploadMetadata {
    throw new Error("Function not implemented.");
}
