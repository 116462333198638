import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { AddressAction } from '../../../action/address.action';
import { AddressActor } from '../../../actor/address.actor';
import { SubDistrict } from '../../../service/response/sub-district.response';
import { AddressStore } from '../../../store/address.store';
import { useObservable } from '../../../useSubscriber';
import { useStyles } from './ui.style';
import { useTranslation } from "react-i18next"

export const SubDistrictPickerComponent = (props: any) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [subDistrictList, subDistrictListError] = useObservable(AddressStore.getSubDistrictList(), AddressStore.getSubDistrictListObservable())
    const [delarDto] = useObservable((props.Store).getDelarDto(), (props.Store).getDelarDtoObservable())

    const [subDistrictData, setSubDistrictData] = useState<Array<SubDistrict>>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        AddressActor({ "type": AddressAction.GET_SUB_DISTRICT_LIST }, { "districtCode": props.districtCode })
    }, [])

    const handleSubDistrictPick = (subDistrict: string | SubDistrict) => {
        props.setSubDistrict(subDistrict)
        if (subDistrict) {
            AddressActor({ "type": AddressAction.GET_VILLAGE_LIST }, { "subDistrictCode": (subDistrict as SubDistrict).code })
        }
    }

    useEffect(() => {
        if (!subDistrictListError.error && subDistrictList) {
            var newSubDistricts: Array<SubDistrict> = Object.keys(subDistrictList).map(function (index: any) {
                let subDistrict = (subDistrictList as SubDistrict[])[index]
                return subDistrict;
            });
            setSubDistrictData(newSubDistricts)
        }
        setLoading(false)
    }, [subDistrictList])

    return (
        !loading && <Autocomplete
            id="combo-box-demo"
            options={subDistrictData}
            defaultValue={delarDto?.shopAddress?.geoAddress?.subDistrict}
            getOptionLabel={(option: SubDistrict) => option.name}
            onChange={(event, subDistrict) => handleSubDistrictPick(subDistrict)}
            fullWidth={true}
            loading={subDistrictData.length > 0 ? true : false}
            classes={{ input: classes.input }}
            style={{ marginTop: 8, }}
            renderInput={(params: any) => <TextField {...params}
                defaultValue={delarDto?.shopAddress?.geoAddress?.subDistrict?.name}
                // classes = {{
                //     root: delarDtoError?.city.error?classes.TextFieldRoot: classes.noStyle,
                // }}
                label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">{t("common_type_city")}</Typography>} variant="outlined" />}
        />
    );
}