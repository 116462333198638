import Axios, { AxiosResponse } from "axios"
import { OrderDto, UpdateOrderStatusDto } from "./dto/order.dto"
import { OrderRes } from "./response/order.response"
import { ShipmentDetail } from "./response/shipement.response"

export const OrderService = {
    getOrderList: (orderDto: OrderDto): Promise<AxiosResponse<OrderRes>> => {
        return Axios.post<OrderRes>(`/delar-shipment-product`, orderDto)
    },
    getShipmentDetail: (shipmentId: string): Promise<AxiosResponse<ShipmentDetail>> => {
        return Axios.get<ShipmentDetail>(`/shipment/${shipmentId}/details`)
    },
    updateProductStatus: (updateOrderStatusDto: Array<UpdateOrderStatusDto>): Promise<AxiosResponse<any>> => {
        return Axios.put<any>(`/product-status`, updateOrderStatusDto)
    }
}