import { createStyles, makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogHeading: {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 700,
        },
        dialogContent: {
            fontSize: 14,
            opacity:1,
            marginTop: 0,
            color: '#000000'
        },
        dialogButtonAgree: {
            textTransform: 'capitalize',
            color: '#4A78C7',
            fontSize: 14,
            fontWeight: 600,
            marginTop: 0,
            marginBottom: 16,
        },
        dialogButtonDisagree: {
            textTransform: 'capitalize',
            color: '#4A78C7',
            fontSize: 14,
            marginTop: 0,
            marginBottom: 16,
        },
    })
)