import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            font: 'normal normal normal 18px/24px Karma',
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            
        },
        title1: {
            marginLeft: 20,
            marginTop: 15,
            maxLines: 2,
            
            marginRight: 73,
        },
        title2: {
            marginLeft: 20,
            marginTop: 8,
            maxLines: 30,
            
            marginRight: 17,
        },
        title3: {
            marginLeft: 20,
            marginTop: 16,
            maxLines: 20,
            fontSize: 18,
            marginRight: 17,
        },
    }),
);