import { AppBar, BottomNavigation, Button, Modal, TextField, Toolbar, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './edit-license.style'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { EditProfileActor } from "../../../../../actor/edit-profile.actor"
import { EditProfileAction } from "../../../../../action/edit-profile.action"
import { useObservable } from "../../../../../useSubscriber"
import { EditProfileStore } from "../../../../../store/edit-profile.store"
import { SecurityManager } from "../../../../../security/security.manager"
import { GalleryPickerComponent } from "../../../../Image_Picker/gallery-picker.component"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../../../router/route.manager"
import LoadingComponent from "../../../../common/loading/loading.component"

export const EditFertilizerLicenseComponent = (props: any) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager()
    const [loading, setLoading] = useState(false)
    const [imagesAdded, setImagesAdded] = useState([])
    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(EditProfileStore.getDelarDtoError(), EditProfileStore.getDelarDtoErrorObservable())
    const handleChangeFertilizerLicense = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_FERTILIZER_LICENSE }, { "fertilizerLicenseNumber": e.target.value })
    };

    const handleUpdate = () => {
        EditProfileActor({ "type": EditProfileAction.VALIDATE_FERTILIZER_LICENCE_ERROR }, {})

        if ((delarDtoError.fertilizerPhoto.error && imagesAdded.length <= 0) || delarDtoError.fertilizerLiscence.error)
            return

        setLoading(true)
        EditProfileActor({ "type": EditProfileAction.UPLOAD_NEW_FERTILIZER_IMAGES }, { "delarId": SecurityManager.getUid(), "fertilizerPhotos": imagesAdded, "action": () => { setLoading(false); routeManager.goBack(); } })
    };
    const deleteUploadedElement = (key: string) => {
        setLoading(true)
        EditProfileActor({ "type": EditProfileAction.DELETE_FERTILIZER_PHOTO }, { "key": key, "delarId": SecurityManager.getUid(), "onUploaded": () => { setLoading(false) }, "action": () => { setLoading(false) }, "errorAction": () => { setLoading(false) } })
    }
    useEffect(() => {
        var obj = delarDto?.fertilizerLiscence?.newLicence?.photos
        EditProfileActor({ "type": EditProfileAction.UPDATE_FERTILIZER_IMAGES_ERROR }, {
            "flag": (imagesAdded.length === 0 && (obj && Object.keys(obj).length === 0 && obj.constructor === Object))
        })
    }, [imagesAdded, delarDto])

    return (

        <div className={classes.root}>
            {!loading && <><AppBar position="sticky">
                <Toolbar>
                    <ArrowBackIcon
                        onClick={() => routeManager.goBack()} className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("change_license_number")}
                    </Typography>
                </Toolbar>
            </AppBar>
                <div className={classes.imgContainer}>
                    <img alt={'editLicenseIllustration'} src={'/asset/Edit License Illustration.svg'} />
                </div>

                <Typography variant="subtitle1" className={classes.title1} align={'center'}>
                    {t("change_requirement_license_card_no")}
                </Typography>
                <Typography variant="body1" className={classes.title2} >
                    <b>{t("fertiliser_license")}</b>
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField
                        id="outlined-basic"
                        value={delarDto?.fertilizerLiscence?.newLicence?.number}
                        className={classes.textField1}
                        label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">{t("type_license_number")}</Typography>}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                input: classes.font12,
                            },
                        }}
                        onChange={handleChangeFertilizerLicense}
                    />
                    {delarDtoError?.fertilizerLiscence.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("edit_profile_license_number_error")}
                    </Typography>}
                </form>
                <Typography variant="body1" className={classes.title2} >
                    <b>{t("upload_license")}</b>
                </Typography>
                <div style={{ marginLeft: 10, width: '91%', padding: 0, }}>
                    <GalleryPickerComponent
                        uploaded={delarDto?.fertilizerLiscence?.newLicence?.photos}
                        deleteUploadedElement={deleteUploadedElement}
                        handleUpload={(images: any) => { setImagesAdded([...images]) }}
                    />
                    {delarDtoError?.fertilizerPhoto.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("edit_profile_license_image_required")}
                    </Typography>}
                </div>
                <BottomNavigation
                    showLabels
                    className={classes.bottomNavigation}
                >
                    <Button variant="contained" color="primary" className={classes.requestButton} onClick={handleUpdate}>
                        {t("send_request")}
                    </Button>
                </BottomNavigation></>}
            <Modal open={loading} className={classes.modal}><LoadingComponent /></Modal>
        </div>
    )
}