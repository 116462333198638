import { Avatar, Button, Card, CardContent, Collapse, Container, Divider, IconButton, Link, List, ListItem, ListItemSecondaryAction, ListItemText, Modal, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { DelarAction } from "../../../../action/delar.action";
import { DelarActor } from "../../../../actor/delar.actor";
import { Media } from "../../../../model/media.model";
import { SecurityManager } from "../../../../security/security.manager";
import { DelarStore } from "../../../../store/delar.store";
import { useObservable } from "../../../../useSubscriber";
import { PendingComponent } from "../../profile/application-cards/pending/pending.component";
import { RejectedComponent } from "../../profile/application-cards/rejected/rejected.component";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from "./homeB.style"
import { useTranslation } from "react-i18next"
import { DelarStatus } from "../../../../enum/delar-status.enum";
import { OrderStatus } from "../../../../enum/order-status.enum";
import { CancelledOrderComponent } from "../../order/views/cancelled/cancelled-order.component";
import { CompletedOrderComponent } from "../../order/views/completed/completed-order.component";
import { NewOrderComponent } from "../../order/views/new/new-order.component";
import { PendingOrderComponent } from "../../order/views/pending/pending-order.component";
import { AddressActor } from "../../../../actor/address.actor";
import { AddressAction } from "../../../../action/address.action";
import { AddressStore } from "../../../../store/address.store";
import { ExpandLess } from "@material-ui/icons";
import { DeclinedOrderComponent } from "../../order/views/declined/declined.component";
import { ReturnPendingComponent } from "../../order/views/return-pending/return-pending.component";
import { ReturnPickedComponent } from "../../order/views/return-picked/return-picked.component";
import { OrderAction } from "../../../../action/order.action";
import { OrderActor } from "../../../../actor/order.actor";
import { OrderStore } from "../../../../store/order.store";
import { Order } from "../../../../service/response/order.response";
import { AndroidConnerctor } from "../../../../android.connecter";
import { useRouteManager } from "../../../../router/route.manager";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddVillageZeroComponent } from "../zero condition cards/add-village-zero.component";
import { AddProductZeroComponent } from "../zero condition cards/add-product-zero.component";
import { ProductStore } from "../../../../store/product/product.store";
import { OrderAddress } from "../../../../model/address.model";
import { UpdateOrderStatusDto } from "../../../../service/dto/order.dto";
import { OrderProductMini } from "../../../../service/response/product.response";
import LoadingComponent from "../../../common/loading/loading.component";
import { ProductAction } from "../../../../action/product/product.action";
import { ProductActor } from "../../../../actor/product/product.actor";

toast.configure()

export const HomeBComponent = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const [loading, setLoading] = useState(false)
    const [villageLoading, setVillageLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)

    const [delarInfo] = useObservable(DelarStore.getDelarInfo(), DelarStore.getDelarInfoObservable())
    const [delarProductList, _] = useObservable(ProductStore.getDelarProductList(), ProductStore.getDelarProductListObservable())
    const [addressInfo, __] = useObservable(AddressStore.getAddedAddressList() as any, AddressStore.getAddedAddressListObservable())
    const [orderList] = useObservable(OrderStore.getOrderList(), OrderStore.getOrderListObservable())

    const [expanded, setExpanded] = React.useState(false);

    const [imageUrl, setImageUrl] = useState("")

    const afterOrderFetch = {
        setLoading: setOrderLoading,
        setHasMore: () => { }
    }

    useEffect(() => {
        setLoading(true)
        // setVillageLoading(true)
        DelarActor({ "type": DelarAction.GET_DELAR_INFO }, { "delarID": SecurityManager.getUid(), "action": () => { setLoading(false); } })
        // AddressActor({ "type": AddressAction.GET_ADDED_VILLAGE_LIST }, { "delarId": SecurityManager.getUid(), "action": () => { setVillageLoading(false); } })

        ProductActor({ "type": ProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST }, {})
        ProductActor({ "type": ProductAction.GET_LIST }, { "afterDataFetch": () => { setLoading(false); }, "setHasMore": () => { } })
    }, [])

    useEffect(() => {
        initView()
    }, [])

    useEffect(() => {
        if (delarInfo) {
            setLoading(false)
        }
        if (delarInfo && delarInfo.status.value == DelarStatus.Pending) {
            routeManager.openReviewPending()
        } else if (delarInfo && delarInfo.status.value != DelarStatus.Verified) {
            SecurityManager.logout()
        }

        if (delarInfo && delarInfo.shopPhotos) {
            const image = new Map(Object.entries(delarInfo.shopPhotos)).values().next().value as Media
            if (image) {
                const _imageUrl = new Map(Object.entries((image.urls))).get('main')
                if (_imageUrl) {
                    console.log(_imageUrl)
                    setImageUrl(_imageUrl)
                }
            }
        }
    }, [delarInfo])

    const initView = () => {
        setOrderLoading(true)
        OrderActor({ "type": OrderAction.GET_LIST }, { "statusList": [OrderStatus.ORDER_ACCEPTED_BY_ADMIN], "action": afterOrderFetch } )
    }

    const markAsDeliver = (orderProducts: Array<OrderProductMini>) => {
        const updateOrderDtos: UpdateOrderStatusDto[] = []
        orderProducts.map((orderProduct: OrderProductMini) => {
            updateOrderDtos.push({
                "userId": orderProduct.delarId,
                "userType": 3,
                "status": {
                    "value": OrderStatus.ORDER_DELIVERED,
                    "msg": "Order delivered by Delar"
                },
                "orderProductId": orderProduct.id,
            } as UpdateOrderStatusDto)
        });

        OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": updateOrderDtos, "afterPackUpdate": initView })
    }

    const returnPick = (orderProducts: Array<OrderProductMini>) => {
        const updateOrderDtos: UpdateOrderStatusDto[] = []
        orderProducts.map((orderProduct: OrderProductMini) => {
            updateOrderDtos.push({
                "userId": orderProduct.delarId,
                "userType": 3,
                "status": {
                    "value": OrderStatus.RETURN_PICKED,
                    "msg": "Return Picked by Delar"
                },
                "orderProductId": orderProduct.id,
            } as UpdateOrderStatusDto)
        });

        OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": updateOrderDtos, "afterPackUpdate": initView })
    }

    const contactFarmer = (oderAddress: OrderAddress) => {
        alert(`${oderAddress.phone}`)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleItemClick = (shipmentId: string, shipmentStatus: number) => {
        routeManager.openShipmentDetail(shipmentId, shipmentStatus)
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText('https://dealer.khetipoint.com/')
        toast('Copied to clipboard')
    };

    return (
        <>
            {!loading && !villageLoading && !orderLoading && (
                <div className={classes.root}>
                    {delarInfo?.shopPhotos && imageUrl.length > 0 && (
                        <React.Fragment>
                            <div style={{ flex: 1, alignItems: 'stretch', position: 'relative' }}>
                                <img src={imageUrl} alt={'shopImage'} className={classes.img} />
                                <Typography variant="h6" className={classes.title1}>
                                    {delarInfo.shopName.newName}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}
                    {!delarInfo?.shopPhotos || imageUrl.length == 0 && (
                        <React.Fragment>
                            <div onClick={() => routeManager.openEditPersonalInfo()} className={classes.zeroImageCard} style={{ flex: 1, alignItems: 'stretch', position: 'relative' }}>
                                <img src='../asset/home_thumb.svg' alt={'shopImage'} className={classes.imgCard} />
                                <Typography variant="body1" className={classes.textThumb}>
                                    {t("add_image_hint")}
                                </Typography>
                                <Typography variant="h6" className={classes.title1}>
                                    {delarInfo.shopName.newName}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}

                    {delarInfo?.status?.value == DelarStatus.Rejected && (
                        <React.Fragment>
                            <RejectedComponent />
                        </React.Fragment>
                    )}
                    {delarInfo?.status?.value == DelarStatus.Pending && (
                        <React.Fragment>
                            <PendingComponent />
                        </React.Fragment>
                    )}
                    {delarInfo?.status?.value == DelarStatus.Verified && (
                        <React.Fragment>
                            <Card className={classes.cardEdit1}>
                                <CardContent>
                                    <Typography variant="body1" className={classes.title2}>
                                        {t("home_advice_1")}
                                    </Typography>
                                    <Typography paragraph variant="subtitle1" className={classes.title3} >
                                        {t("home_advice_2")}
                                    </Typography>
                                    <div className={classes.shareDiv}>
                                        <img alt="editIcon" src={'/asset/Copy Icon.svg'} className={classes.icon} onClick={handleCopyLink} />
                                        <Link display="inline" className={classes.title4} style={{ color: '#1078FF', }} noWrap onClick={handleCopyLink}>
                                            https://dealer.khetipoint.com/
                                        </Link>

                                        <Button onClick={() => AndroidConnerctor.recommendedToFriends("Hello Testing")} variant="contained" color="primary" className={classes.shareButton} >
                                            <img style={{ width: 27, height: 27 }} src='../asset/Whatsapp.svg' />
                                            <Typography style={{ opacity: 0.8, fontSize: 12 }}>
                                                {t("share")}
                                            </Typography>
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )}
                    {orderList.length > 0 && (
                        <React.Fragment>
                            <Card className={classes.cardEdit2} >
                                <CardContent>
                                    <Typography variant="body1" className={classes.productCardTitle}>
                                        <b> {t("my_orders")} </b>
                                    </Typography>
                                    <Divider style={{ width: '120%', marginLeft: -16, }} />
                                    {orderList.map((order: Order, index: any) => {
                                        return (
                                            <React.Fragment>
                                                {order.status.value === OrderStatus.ORDER_ACCEPTED_BY_ADMIN && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <NewOrderComponent order={order}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                                {order.status.value === OrderStatus.ORDER_ACCEPTED_BY_DELAR && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <PendingOrderComponent order={order} markAsDeliver={markAsDeliver} contactFarmer={contactFarmer}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                                {order.status.value === OrderStatus.ORDER_CANCELLED_BY_FARMER && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <CancelledOrderComponent order={order}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                                {order.status.value === OrderStatus.ORDER_DELIVERED && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <CompletedOrderComponent order={order}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                                {order.status.value === OrderStatus.ORDER_REJECTED_BY_DELAR && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <DeclinedOrderComponent order={order}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                                {(order.status.value === OrderStatus.RETURN_ACCEPTED_BY_ADMIN || order.status.value === OrderStatus.RETURN_ACCEPTED_BY_DELAR) && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <ReturnPendingComponent order={order} returnPick={returnPick} contactFarmer={contactFarmer}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                                {order.status.value === OrderStatus.RETURN_PICKED && (
                                                    <div style={{ marginLeft: -24, marginTop: -8, }}>
                                                        <ReturnPickedComponent order={order}
                                                            handleItemClick={handleItemClick} />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )}
                    {false && (
                        <React.Fragment>
                            <Card className={classes.cardEdit2} >
                                <CardContent>
                                    <Typography variant="body1" className={classes.title5}>
                                        <b> {t("shop_overview")} </b>
                                    </Typography>
                                    <div style={{ display: "flex", marginRight: -8, }}>
                                        <Card className={classes.cardEdit3} style={{ marginLeft: -8, }}>
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1}>
                                                    {t("total_shop_view")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 8 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        <Card className={classes.cardEdit3} >
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1}>
                                                    {t("today_shop_view")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 0 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div style={{ display: "flex", marginRight: -8, }}>
                                        <Card className={classes.cardEdit3} style={{ marginLeft: -8, }}>
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1}>
                                                    {t("total_call")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 9 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        <Card className={classes.cardEdit3}>
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1}>
                                                    {t("product_view")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 23 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )}
                    {false && (
                        <React.Fragment>
                            <Card className={classes.cardEdit2}>
                                <CardContent>
                                    <Typography variant="body1" className={classes.title5}>
                                        <b> {t("product_overview")} </b>
                                    </Typography>
                                    <div style={{ display: "flex", marginRight: -8, }}>
                                        <Card className={classes.cardEdit3} style={{ marginLeft: -8, }}>
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1}>
                                                    {t("total_product")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 1 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        <Card className={classes.cardEdit3} >
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1} >
                                                    {t("enable_product")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 4 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div style={{ display: "flex", marginRight: -8, }}>
                                        <Card className={classes.cardEdit3} style={{ marginLeft: -8, }} >
                                            <CardContent>
                                                <Typography variant="subtitle1" className={classes.title5_1}>
                                                    {t("disable_product")}
                                                </Typography>
                                                <Typography variant="h6" className={classes.title6}>
                                                    <b> 6 </b>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )}

                    {delarProductList && delarProductList.length === 0 && (
                        <React.Fragment>
                            <AddProductZeroComponent />
                        </React.Fragment>
                    )}

                    {/* {addressInfo && addressInfo.length > 0 && ( */}
                    {false && (
                        <React.Fragment>
                            <Card className={classes.cardEdit2} >
                                <CardContent>
                                    <Typography variant="body1" className={classes.villageCardTitle}>
                                        <b> {t("home_delivery_villages")} </b>
                                    </Typography>
                                    <List dense style={{ width: '110%', backgroundColor: 'background.paper', marginLeft: -16, }} >
                                        {addressInfo.map((address: any, index: number) => {
                                            if (index <= 3) {
                                                const labelId = `${address.id}`
                                                return (
                                                    <>
                                                        <ListItem className={index % 2 === 0 ? classes.evenItem : classes.oddItem} key={index}>
                                                            <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.villageName}>
                                                                {address.village.name}, {address.subDistrict.name}, {address.district.name}, {address.state.name}
                                                            </Typography>} />
                                                        </ListItem>
                                                    </>
                                                );
                                            }
                                        })}
                                    </List>
                                </CardContent>
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        <List dense style={{ width: '110%', backgroundColor: 'background.paper', marginTop: -50, marginLeft: -16, }} >
                                            {addressInfo.map((address: any, index: number) => {
                                                if (index > 3) {
                                                    const labelId = `${address.id}`
                                                    return (
                                                        <>
                                                            <ListItem className={index % 2 === 0 ? classes.evenItem : classes.oddItem} key={index}>
                                                                <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.villageName}>
                                                                    {address.village.name}, {address.subDistrict.name}, {address.district.name}, {address.state.name}
                                                                </Typography>} />
                                                            </ListItem>
                                                        </>
                                                    );
                                                }
                                            })}
                                        </List>
                                    </CardContent>
                                </Collapse>
                                <CardContent onClick={handleExpandClick}>
                                    <List dense style={{ width: '110%', backgroundColor: 'background.paper', marginTop: -50, marginLeft: -16, }} >
                                        <ListItem className={classes.expandItem} >
                                            <ListItemText disableTypography primary={<Typography variant="body2" className={classes.expandText}>{expanded ? "See less" : t("home_see_all")}</Typography>} />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    className={classes.expand}
                                                    aria-expanded={expanded}
                                                    aria-label="show more"
                                                >
                                                    {expanded ? <ExpandLess /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )}
                    {/* {addressInfo && addressInfo.length === 0 && ( */}
                    {false && (
                        <React.Fragment>
                            <AddVillageZeroComponent />
                        </React.Fragment>
                    )}
                </div>
            )}
            <Modal open={loading || villageLoading || orderLoading} className={classes.modal}><LoadingComponent /></Modal>
        </>
    )
}