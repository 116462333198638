import { Button, Modal } from "@material-ui/core"
import { useStyles } from './review-pending.style'
import { useRouteManager } from "../../../router/route.manager"
import { DelarStatus } from "../../../enum/delar-status.enum";
import { useEffect, useState } from "react";
import { useObservable } from "../../../useSubscriber";
import { RegistrationAction } from "../../../action/registration.action";
import { RegistrationActor } from "../../../actor/registration.actor";
import LoadingComponent from "../../common/loading/loading.component";
import { RegistrationStore } from "../../../store/registration.store";
import { ValidateOTPRes } from "../../../service/response/registration.response";
import { SecurityManager } from "../../../security/security.manager";

export const ReviewPendingComponent = () => {
    const classes = useStyles();
    const routeManager = useRouteManager();
    const [loading, setLoading] = useState(false)

    const [checkStatusRes, checkStatusResError] = useObservable(RegistrationStore.getCheckStatusRes(), RegistrationStore.getCheckStatusResObservable())

    useEffect(() => {
        setLoading(true)
        RegistrationActor({ "type": RegistrationAction.CHECK_STATUS }, {
            "action": () => { setLoading(false) }
        })
    }, [])

    useEffect(() => {
        if (!SecurityManager.loggedIn() || SecurityManager.getVerified() != DelarStatus.Pending) {
            routeManager.openHome()
        }
        if (!checkStatusResError.error && checkStatusRes) {
            var _checkStatusRes = checkStatusRes as ValidateOTPRes
            if (_checkStatusRes.delar.status.value == DelarStatus.NotInit) {
                routeManager.openDelarDetail()
            } else if (_checkStatusRes.delar.status.value == DelarStatus.Verified) {
                routeManager.openHome()
            }
        }

    }, [checkStatusRes, checkStatusResError])

    const logoutUser = () => {
        SecurityManager.logout()
    }

    return (

        <div className={classes.root}>
            <div>
                <div className={classes.imageHolder}>
                    <img className={classes.image} src="../asset/review_pending.svg" />
                </div>
                <div className={classes.description}>
                    <p style={{fontSize:14,fontWeight:700}}>Application Status</p>
                    <p style={{color:'#258ee9'}}>Pending</p>
                    <p style={{fontSize:14, fontWeight: 500}}>Thank You for the application. Your application have been submitted, our team will get back to you shortly.</p>
                    <Button variant="outlined" className={classes.button} onClick={(e: any) => {
                        routeManager.openDelarDetail()
                    }}>
                        Edit form
                    </Button>

                    <Button variant="outlined" className={classes.button} onClick={(e: any) => {
                        logoutUser()
                    }}>
                        Log Out
                    </Button>
                </div>
            </div>
            <Modal open={(loading)} className={classes.modal}><LoadingComponent /></Modal>
        </div>

    )
}