import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        statusHeading: {
            fontSize: 16,
            fontWeight: 600,
            color: '#1CCB2D',
        },
        numberHeading: {
            fontSize: 10,
            opacity: 0.6,
            marginLeft: 8,
            marginTop: 5,
        },
        time: { 
            fontSize: 10,
            opacity: 0.6,
        },
        heading: {
            marginLeft: 8,
            fontSize: 16,
            fontWeight: 700,
        },
        primaryContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
        },
        statusNumberContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'left',
        },
        description: {
            marginLeft: 8,
            fontSize: 16,
            opacity: 0.7,
        },
    }),
);