export enum OrderStatus {
  ORDER_PENDING = 1,
  ORDER_ACCEPTED_BY_ADMIN = 6,
  ORDER_REJECTED_BY_ADMIN = -1,
  ORDER_ACCEPTED_BY_DELAR = 11,
  ORDER_OUT_FOR_DELIVERY = 14,
  ORDER_REJECTED_BY_DELAR = -6,
  ORDER_CANCELLED_BY_FARMER = -11,
  ORDER_DELIVERED = 16,

  RETURN_PENDING = 21,
  RETURN_ACCEPTED_BY_ADMIN = 26,
  RETURN_REJECTED_BY_ADMIN = -16,
  RETURN_ACCEPTED_BY_DELAR = 31,
  RETURN_REJECTED_BY_DELAR = -21,
  RETURN_CANCELLED_BY_FARMER = -26,
  RETURN_PICKED = 36
}

