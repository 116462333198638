import { BehaviorSubject, Observable } from "rxjs"
import { SecurityManager } from "../security/security.manager"
import { OrderDto } from "../service/dto/order.dto"
import { Order, OrderRes } from "../service/response/order.response"
import { ShipmentDetail } from "../service/response/shipement.response"

let _orderList: Array<Order> = []
let _orderListSubject = new BehaviorSubject<Array<Order> | null>(null)

let _shipmentDetail: ShipmentDetail = {} as ShipmentDetail
let _shipmentDetailSubject = new BehaviorSubject<ShipmentDetail | null>(null)

let _orderDto: OrderDto = {delarId: SecurityManager.getUid(),limit:10, orderType: 1} as OrderDto
let _orderDtoSubject = new BehaviorSubject<OrderDto | null>(null)


export const OrderStore = {
    reSetOrderDtoAndOrderList: () => {
        _orderList = []
        _orderListSubject.next([..._orderList])

        _orderDto.pageNumber = 0
        _orderDtoSubject.next({..._orderDto})

        _shipmentDetail = {} as ShipmentDetail
        _shipmentDetailSubject.next({..._shipmentDetail})
    },

    upadteOrderList: (newOrderList: Array<Order>) => {
        _orderList = [..._orderList, ...newOrderList]
        _orderListSubject.next([..._orderList])
    },
    getOrderList: (): Array<Order> | null => {
        return _orderList
    },
    getOrderListObservable: (): Observable<Array<Order> | null> => {
        return _orderListSubject.asObservable()
    },

    upadteOrderDtoStatusList: (newStatusList: Array<number>) => {
        _orderDto.statusList = newStatusList
        _orderDtoSubject.next({..._orderDto})
    },
    upadteOrderDto: (newOrderDto: OrderDto) => {
        _orderDto = newOrderDto
        _orderDtoSubject.next({..._orderDto})
    },
    getOrderDto: (): OrderDto => {
        _orderDto.delarId = SecurityManager.getUid()
        return _orderDto
    },
    getOrderDtoObservable: (): Observable<OrderDto | null> => {
        return _orderDtoSubject.asObservable()
    },

    upadteShipmentDetail: (shipmentDetail: ShipmentDetail) => {
        _shipmentDetail = shipmentDetail
        _shipmentDetailSubject.next({...shipmentDetail})
    },
    getShipmentDetail: (): ShipmentDetail => {
        return _shipmentDetail
    },
    getShipmentDetailObservable: (): Observable<ShipmentDetail | null> => {
        return _shipmentDetailSubject.asObservable()
    },
}
