import { BottomNavigation, Button } from "@material-ui/core"
import { useState } from "react"
import { useStyles } from './add-village.style'
import { Village } from "../../../service/response/village.response"
import { useTranslation } from "react-i18next"
import { SecurityManager } from "../../../security/security.manager"
import { AddVillageDto } from "../../../service/dto/add-village.dto"
import { CommonAddVillageComponent } from "../../common/add-village/add-village-common.component"
import { AddressAction } from "../../../action/address.action"
import { AddressActor } from "../../../actor/address.actor"
import { useRouteManager } from "../../../router/route.manager"
import { DELAR_VILLAGE_UPPER_CAP } from "../../../constant/base.constant"
export const AddVillageComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager()

    const [loading, setLoading] = useState(false)
    const [selectedVillageData, setSelectedVillageData] = useState<Array<Village>>([])
    
    const action = () => {
        setLoading(false)
        routeManager.goBack()
    }
    
    const saveVillages = () => {
        if (selectedVillageData.length > DELAR_VILLAGE_UPPER_CAP) {
            alert(`A Maximum of ${DELAR_VILLAGE_UPPER_CAP} villages can be selected!`)
            return;
        }
        const addDtoList: AddVillageDto[] = []
        setLoading(true)
        selectedVillageData.map((village) => {
            const addDto = {} as AddVillageDto
            addDto.delarId = SecurityManager.getUid()
            addDto.villageId = village.id
            addDtoList.push(addDto)
        })
        const data = {
            "addListDto": [...addDtoList],
            "action": action,
        } as any
        AddressActor({ "type": AddressAction.ADD_VILLAGE_LIST }, data)
    }
    return (
        <>
            <CommonAddVillageComponent
                loading={loading}
                setLoading={setLoading}
                selectedVillageData={selectedVillageData}
                setSelectedVillageData={setSelectedVillageData}
            />

            <BottomNavigation showLabels className={classes.bottomNavigation}>
                <Button className={classes.nextButton} variant="contained" color="primary" onClick={saveVillages}>
                    {t("save")}
                </Button>
            </BottomNavigation>
        </>

    )
}