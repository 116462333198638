import Axios, { AxiosResponse } from "axios";
import { District } from "./response/district.response";
import { State } from "./response/state.response";
import { SubDistrict } from "./response/sub-district.response";
import { Village, VillageData, VillageDetail } from "./response/village.response";
import { VillageSearchDto } from "./dto/village-search.dto";
import { AddVillageDto } from "./dto/add-village.dto";
import { DeleteVillageDto } from "./dto/delete-village.dto";
export const AddressService = {
    getState: (): Promise<AxiosResponse<Array<State>>> => {
        return Axios.get<Array<State>>(`/state`)
    },
    getDistrictByStateCode: (stateCode: number): Promise<AxiosResponse<Array<District>>> => {
        return Axios.get<Array<District>>(`/state/${stateCode}/district`)
    },
    getSubDistrictByDistrictCode: (districtCode: number): Promise<AxiosResponse<Array<SubDistrict>>> => {
        return Axios.get<Array<SubDistrict>>(`/district/${districtCode}/sub-district`)
    },
    getVillageBySubDistrictCode: (subDistrictCode: number): Promise<AxiosResponse<Array<VillageData>>> => {
        return Axios.get<Array<VillageData>>(`/sub-district/${subDistrictCode}/village`)
    },
    getVillageBySearch: (searchDto: VillageSearchDto): Promise<AxiosResponse<Array<VillageData>>> => {
        return Axios.post<Array<VillageData>>(`/search-village`, searchDto)
    },
    addVillageDelivery: (addDto: AddVillageDto[]): any => {
        return Axios.post(`/add-delar-delivery-village-list`, addDto)
    },
    getVillageByDelarId: (delarId: string): Promise<AxiosResponse<Array<VillageDetail>>> => {
        return Axios.get<Array<VillageDetail>>(`/delar/${delarId}/delar-delivery-village`)
    },
    deleteAddedVillage: (deleteDto: DeleteVillageDto): any => {
        return Axios.post(`/delete-delar-delivery-village`, deleteDto)
    }
}