import { BehaviorSubject, Observable } from "rxjs"
import { ApiError } from "../model/error.model"
import { GenerateOTPRes, ValidateOTPRes } from "../service/response/registration.response"
import { DtoError } from "../model/dto-error.model"

let _selectedLang = new BehaviorSubject<string>("")
let _loading = new BehaviorSubject<boolean>(false)
let _generateOtpRes = new BehaviorSubject<GenerateOTPRes | ApiError | null>(null)
let _validateOtpRes = new BehaviorSubject<ValidateOTPRes | ApiError | null>(null)
let _checkStatusRes = new BehaviorSubject<ValidateOTPRes | ApiError | null>(null)

// let _registrationDtoError: any = {
//     phone: new DtoError("", false)
// }
// let _registrationDtoErrorSubject = new BehaviorSubject<any | null>(_registrationDtoError)

// const checkPhoneError = ()
export const RegistrationStore = {
    getSelectedLang: (): string => {
        return _selectedLang.value
    },
    setSelectedLang: (newLang: string) => {
        _selectedLang.next(newLang)
    },
    getSelectedLangObservable: (): Observable<string> => {
        return _selectedLang.asObservable()
    },
    getLoading: (): boolean => {
        return _loading.value
    },
    setLoading: (loading: boolean) => {
        _loading.next(loading)
    },
    getLoadingObservable: (): Observable<boolean> => {
        return _loading.asObservable()
    },
    getGenearteOtpRes: (): GenerateOTPRes | ApiError | null => {
        return _generateOtpRes.value
    },
    setGenearteOtpRes: (generateOTPRes: GenerateOTPRes | ApiError | null) => {
        _generateOtpRes.next(generateOTPRes)
    },
    getGenearteOtpResObservable: (): Observable<GenerateOTPRes | ApiError | null> => {
        return _generateOtpRes.asObservable()
    },
    getValidateOtpRes: (): ValidateOTPRes | ApiError | null => {
        return _validateOtpRes.value
    },
    setValidateOtpRes: (validateOTPRes: ValidateOTPRes | ApiError | null) => {
        _validateOtpRes.next(validateOTPRes)
    },
    getValidateOtpResObservable: (): Observable<ValidateOTPRes | ApiError | null> => {
        return _validateOtpRes.asObservable()
    },

    getCheckStatusRes: (): ValidateOTPRes | ApiError | null => {
        return _checkStatusRes.value
    },
    setCheckStatusRes: (validateOTPRes: ValidateOTPRes | ApiError | null) => {
        _checkStatusRes.next(validateOTPRes)
    },
    getCheckStatusResObservable: (): Observable<ValidateOTPRes | ApiError | null> => {
        return _checkStatusRes.asObservable()
    },
}