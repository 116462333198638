import { createStyles, makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            display: 'inline-block', 
            backgroundColor: '#cfe8fc',
            
            
            height: 79, 
            width: 79, 
            padding: 0,
        },
        icon: {
            width: 36,
            height: 36,
            marginLeft: 21,
            marginTop: -8,
            marginBottom: 16,
        },
        galleryIcon: {
            width: 36,
            height: 36,
            marginLeft: 19,
            marginTop: -8,
            marginBottom: 16,
        },
        iconText: {
            alignSelf: "center",
            marginLeft:18,
            height:13,
            width:40,
            fontSize: 12,
        },
        galleryIconText: {
            alignSelf: "center",
            marginRight: 10,
            marginTop: -1,
            height:13,
            width:40,
            fontSize: 12,
        },
        gridTiles: {
           width: 79,
        },
        imageDelete: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 2,
        },
        cameraButton: {
            
        },
        dialogHeading: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 600,
        },
        dialogContent: {
            fontSize: 12,
        },
        dialogButton: {
            textTransform: 'capitalize',
            color: '#4A78C7',
            fontSize: 12,
        },
    }),
);