import { AddressAction } from "../action/address.action";
import { ApiError } from "../model/error.model";
import { AddressService } from "../service/address.service";
import { AddressStore } from "../store/address.store";
import { DelarService } from "../service/delar.service";
import { DelarStore } from "../store/delar.store";
import { SecurityManager } from "../security/security.manager";
import { DeleteVillageDto } from "../service/dto/delete-village.dto";
const getStateList = () => {
    AddressService.getState().then(res => {
        AddressStore.setStateList(res.data)
    }).catch(err => {
        AddressStore.setStateList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}

const getDistrictList = (stateCode: number) => {
    AddressService.getDistrictByStateCode(stateCode).then(res => {
        AddressStore.setDistrictList(res.data)
    }).catch(err => {
        AddressStore.setDistrictList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}

const getSubDistrictList = (districtCode: number) => {
    AddressService.getSubDistrictByDistrictCode(districtCode).then(res => {
        AddressStore.setSubDistrictList(res.data)
    }).catch(err => {
        AddressStore.setSubDistrictList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}
const getAddedVillageList = (delarId: string, action: any) => {
    AddressService.getVillageByDelarId(delarId).then(res => {
        AddressStore.setAddedAddressList(res.data)
        action()
    }).catch(err => {
        AddressStore.setAddedAddressList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}
const addVillageList = (addListDto: any, action: any) => {
    AddressService.addVillageDelivery(addListDto).then((res: any)=>{
        action()
    }).catch((err: any) => {
        console.log(err)
    })
}
const getVillageList = (subDistrictCode: number) => {
    AddressService.getVillageBySubDistrictCode(subDistrictCode).then(res => {
        AddressStore.setVillageList(res.data)
    }).catch(err => {
        AddressStore.setVillageList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}
const deleteAddedVillage = (deleteDto: DeleteVillageDto, action: any) => {
    AddressService.deleteAddedVillage(deleteDto).then((res: any) => {
        action()
    }).catch((err: any) => {
        console.log(err)
    })
}
export const AddressActor = (action: any, data: any) => {
    switch (action.type) {
        case AddressAction.GET_STATE_LIST:
            getStateList()
            break;
        case AddressAction.GET_DISTRICT_LIST:
            getDistrictList(data.stateCode)
            break;
        case AddressAction.GET_SUB_DISTRICT_LIST:
            getSubDistrictList(data.districtCode)
            break;
        case AddressAction.GET_VILLAGE_LIST:
            getVillageList(data.subDistrictCode)
            break;
        case AddressAction.GET_ADDED_VILLAGE_LIST:
            getAddedVillageList(data.delarId, data.action)
            break;
        case AddressAction.DELETE_ADDED_VILLAGE:
            deleteAddedVillage(data.deleteDto, data.action)
            break;
        case AddressAction.ADD_VILLAGE_LIST:
            addVillageList(data.addListDto, data.action)
            break;
        default:
            break;
    }
}