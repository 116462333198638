import Axios, { AxiosResponse } from "axios";
import { DelarDto, DelarPhotoDeleteDto } from "./dto/delar.dto";
import { NotificationDto } from "./dto/notification.dto";
import { Delar } from "./response/delar.response";
import { NotificationRes } from "./response/notification.response";
import { ValidateOTPRes } from "./response/registration.response";

export const DelarService = {
    getDelarById: (delarId: string): Promise<AxiosResponse<Delar>> => {
        return Axios.get<Delar>(`/delar/${delarId}`)
    },
    updateDelarById: (delarId: string, delarDto: DelarDto): Promise<AxiosResponse<Delar>> => {
        return Axios.put<Delar>(`/delar/${delarId}`, delarDto)
    },
    deleteDelarPhotoById: (delarId: string, delarPhotoDeleteDto: DelarPhotoDeleteDto): Promise<AxiosResponse<Delar>> => {
        return Axios.put<Delar>(`/delar/${delarId}/delete-photos`, delarPhotoDeleteDto)
    },
    checkDelarStatus: (delarId: string): Promise<AxiosResponse<ValidateOTPRes>> => {
        return Axios.get<ValidateOTPRes>(`/delar/${delarId}/check-status`)
    },
    getDelarNotifications: (notificationDto: NotificationDto): Promise<AxiosResponse<Array<NotificationRes>>> => {
        return Axios.post<Array<NotificationRes>>(`/notification/feed`, notificationDto)
    }
}