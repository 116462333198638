import { TextField, Typography } from "@material-ui/core"
import { useStyles } from "./business-detail.style"
import { DistrictPickerComponent } from "../../../common/address-picker/district-picker.component"
import { District } from "../../../../service/response/district.response"
import { SubDistrict } from "../../../../service/response/sub-district.response"
import { Village } from "../../../../service/response/village.response"
import { State } from "../../../../service/response/state.response"
import { DelarStore } from "../../../../store/delar.store"
import { useObservable } from "../../../../useSubscriber"
import React, { useEffect, useState } from "react"
import { DelarActor } from "../../../../actor/delar.actor"
import { DelarAction } from "../../../../action/delar.action"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { GalleryPickerComponent } from "../../../Image_Picker/gallery-picker.component"
import { StatePickerComponent } from "../../../common/address-picker/state-picker.component"
import { SubDistrictPickerComponent } from "../../../common/address-picker/sub-district-picker.component"
import { VillagePickerComponent } from "../../../common/address-picker/village-picker.component"
import { useTranslation } from "react-i18next"
import { SecurityManager } from "../../../../security/security.manager"

export const BusinessDetailComponent = (props: any) => {

    const classes = useStyles();

    const { t } = useTranslation();
    const [delarDto] = useObservable(DelarStore.getDelarDto(), DelarStore.getDelarDtoObservable())
    const [delarDtoError] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())

    const [pinCodeString, setPinCodeString] = useState("")

    const setState = (state: State) => {
        DelarActor({ "type": DelarAction.UPDATE_STATE }, { "state": state })
    }

    const setDistrict = (district: District) => {
        DelarActor({ "type": DelarAction.UPDATE_DISTRICT }, { "district": district })
    }

    const setSubDistrict = (subDistrict: SubDistrict) => {
        DelarActor({ "type": DelarAction.UPDATE_SUB_DISTRICT }, { "subDistrict": subDistrict })
    }

    const setVillage = (village: Village) => {
        DelarActor({ "type": DelarAction.UPDATE_VILLAGE }, { "village": village })
    }

    const handleChangeShopName = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_SHOP_NAME }, { "shopName": e.target.value })
    };

    const handleChangeStreetAddress = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_STREET_ADDRESS }, { "streetLine": e.target.value })
    };

    const handleChangeLandMark = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_LANDMARK }, { "landMark": e.target.value })
    };

    const handleChangePinCode = (e: any) => {
        if (e.target.value == "") {
            DelarActor({ "type": DelarAction.UPDATE_PIN_CODE }, { "pinCode": 0 })
            setPinCodeString("")
            return
        }
        try {
            const pinCode = Number(e.target.value)
            if (pinCode) {
                DelarActor({ "type": DelarAction.UPDATE_PIN_CODE }, { "pinCode": pinCode })
            }
        } catch {
            
        }

    };

    const handleChangeGSTNumber = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_GST_NUMBER }, { "gstNumber": e.target.value })
    };

    const deleteUploadedElement = (key: string) => {
        props.startLoading()
        DelarActor({ "type": DelarAction.DELETE_SHOP_PHOTO }, {
            "key": key, "delarId": SecurityManager.getUid(),
            "action": props.stopLoading,
            "errorAction": props.stopLoading
        })
    }

    useEffect(() => {
        if (delarDto && delarDto.shopAddress && delarDto.shopAddress.pinCode) {
            setPinCodeString(delarDto.shopAddress.pinCode.toString())
        }

    }, [delarDto])

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.title1} >
                <b>{t("onboarding_business_details")}</b>
            </Typography>
            <Typography variant="body1" className={classes.title2} >
                {t("onboarding_business_upload_shop")}
            </Typography>
            <div style={{ marginTop: 8, marginBottom: -12, }}>
                <GalleryPickerComponent
                    handleUpload={props.handleUpload}
                    uploaded={delarDto?.shopPhotos}
                    deleteUploadedElement={deleteUploadedElement}
                />
            </div>
            {/* {delarDtoError?.shopPhotos.error && !props.flag && <Typography variant="body1" className={classes.errorText}>
                <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.shopPhotos?.msg}
            </Typography>} */}
            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    {t("shop_name")}
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.shopName.newName}
                        className={classes.textField1}
                        classes={{
                            root: delarDtoError?.shopName.error ? classes.TextFieldRoot : classes.noStyle,
                        }}
                        variant="outlined"
                        onChange={handleChangeShopName} />
                    {delarDtoError?.shopName.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("common_error_text")}
                    </Typography>}
                </form>
            </React.Fragment>

            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    {t("gstin_number")}
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.gstDetail?.newGstNumber?.number}
                        className={classes.textField1}
                        classes={{
                            root: delarDtoError?.gstDetail.error ? classes.TextFieldRoot : classes.noStyle,
                        }}
                        variant="outlined"
                        onChange={handleChangeGSTNumber} />
                    {delarDtoError?.gstDetail.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("gst_error_text")}
                    </Typography>}
                </form>
            </React.Fragment>


            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    {t("street_address")}
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.shopAddress.streetLine}
                        className={classes.textField1}
                        classes={{
                            root: delarDtoError?.shopAddress.error ? classes.TextFieldRoot : classes.noStyle,
                        }}
                        variant="outlined"
                        onChange={handleChangeStreetAddress} />
                    {delarDtoError?.shopAddress.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("common_error_text")}
                    </Typography>}
                </form>
            </React.Fragment>


            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    {t("landmark")}
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.shopAddress.landMark}
                        className={classes.textField1}
                        variant="outlined"
                        onChange={handleChangeLandMark} />

                </form>
            </React.Fragment>

            <Typography variant="body1" className={classes.title4} >
                {t("state")}
            </Typography>
            <StatePickerComponent
                setState={setState} Store={DelarStore} />
            {delarDtoError?.state.error && <Typography variant="body1" className={classes.errorText}>
                <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
            </Typography>}

            {delarDto?.shopAddress?.geoAddress?.state?.name && delarDto?.shopAddress?.geoAddress?.state?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title3} >
                        {t("district")}
                    </Typography>

                    <DistrictPickerComponent
                        setDistrict={setDistrict}
                        Store={DelarStore}
                        stateCode={delarDto.shopAddress.geoAddress.state.code} />
                    {delarDtoError?.district.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
                    </Typography>}
                </React.Fragment>
            )}

            {delarDto?.shopAddress?.geoAddress?.district?.name && delarDto?.shopAddress?.geoAddress?.district?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title3} >
                        {t("city")}
                    </Typography>

                    < SubDistrictPickerComponent
                        setSubDistrict={setSubDistrict}
                        Store={DelarStore}
                        districtCode={delarDto.shopAddress.geoAddress.district.code} />
                    {delarDtoError?.city.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
                    </Typography>}
                </React.Fragment>
            )}

            {delarDto?.shopAddress?.geoAddress?.subDistrict?.name && delarDto?.shopAddress?.geoAddress?.subDistrict?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title3} >
                        {t("village")}
                    </Typography>
                    <VillagePickerComponent
                        setVillage={setVillage}
                        Store={DelarStore}
                        subDistrictCode={delarDto.shopAddress.geoAddress.subDistrict.code} />
                    {delarDtoError?.village.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
                    </Typography>}
                </React.Fragment>
            )}

            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    {t("pincode")}
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic" value={pinCodeString} className={classes.textField1} variant="outlined" onChange={handleChangePinCode} />
                </form>
            </React.Fragment>

        </div>
    )
}
