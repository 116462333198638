import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    iconContainer: {
      transform: 'scale(0.85)',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    nextButton: {
        width: "90%",
        margin: "auto",
        display: "flex",
        height: 49,
        textTransform: 'capitalize',
        boxShadow: 'none',
    },
    bottomNavigation: {
      marginTop: 10,
      width: '100%',
      maxWidth: 720,
      margin: 'auto',
      height: 79,
      position: 'fixed',
      bottom: 0,
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
    modal: {
      display: 'flex', 
      flexDirection: 'column', 
      verticalAlign: 'middle', 
      justifyContent: 'center',
      opacity: 0.5,
  },
  }),
);