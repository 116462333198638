import { AppBar, Avatar, Dialog, Divider, List, ListItem, ListItemAvatar, ListItemText, Radio, Toolbar, Typography } from "@material-ui/core"
import React, { useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './setting.style'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { LangCode } from "../../../enum/lang-code.enum";
import { RegistrationActor } from "../../../actor/registration.actor"
import { RegistrationAction } from "../../../action/registration.action"
import { useRouteManager } from "../../../router/route.manager"
import { DELAR_APP_LANG_CODE } from "../../../constant/base.constant"
import { SecurityManager } from "../../../security/security.manager"
export const SettingComponent = () => {
    const navigate = useNavigate()
    const classes = useStyles();
    const { t } = useTranslation();
    const languages = ['en', 'hi', 'gu']
    const currentLanguage = localStorage.getItem(DELAR_APP_LANG_CODE)
    const [openLanguageSelection, setOpenLanguageSelection] = useState(false);
    const routeManager = useRouteManager()
    const handleUpdate = (language: any) => {
        let langCode: any;
        if(language === 'en')
            langCode = LangCode.English
        else if(language === 'hi')
            langCode = LangCode.Hindi
        else
            langCode = LangCode.Gujurati
        RegistrationActor({ "type": RegistrationAction.SET_LANGUAGE }, { "langCode": langCode })
        setOpenLanguageSelection(false)
        window.location.reload(false)
    }
    console.log(currentLanguage)
    return (
        <React.Fragment>
           <AppBar position="sticky" style={{boxShadow:'none'}}>
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                    <Typography variant="h6" className={classes.title}>
                        {t("settings")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <List className={classes.root}>
                
                <ListItem onClick = {()=>{setOpenLanguageSelection(true)}} style={{marginTop: -4,}}>
                    <ListItemAvatar>
                        <img alt={'languageIcon'} src={'../asset/Language.svg'} className={classes.icon}/> 
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body1" className = {classes.title1}>{t("language")}</Typography>} secondary={<Typography variant="body1" className = {classes.title1}>{t(`${currentLanguage}`)}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem onClick = {()=>{routeManager.openAboutUs()}} style={{marginTop:8,marginBottom: 6,}}>
                    <ListItemAvatar>
                        <img alt={'aboutIcon'} src={'../asset/About.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2" className = {classes.title1}>{t("about_khetipoint")}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem style={{marginTop:8,marginBottom: 6,}}>
                    <ListItemAvatar>
                        <img alt={'supportIcon'} src={'../asset/Support.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary = {<Typography variant="body2" className = {classes.title1}>{t("support")}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem onClick = {()=>{routeManager.openTermsPolicies()}} style={{marginTop:8,marginBottom: 6,}}>
                    <ListItemAvatar>
                        <img alt={'termsIcon'} src={'../asset/Terms and Policy.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2" className = {classes.title1}>{t("terms_policy")}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem onClick = {()=>{SecurityManager.logout()}} style={{marginTop:8,marginBottom: 6,}}>
                    <ListItemAvatar>
                        <img alt={'logoutIcon'} src={'../asset/Logout.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2" className = {classes.title1}>{t("logout")}</Typography>} />
                </ListItem>
            </List>
            <Dialog onClose ={()=>{setOpenLanguageSelection(false)}} className = {classes.dialogbox} open={openLanguageSelection}>
      <List style = {{width: 200}}>
        {languages.map((language, index: number) => (
            <>
          <ListItem button onClick={() => {handleUpdate(language)}} key={language}>
            <ListItemAvatar>
                    <Radio
                        checked={language.includes(currentLanguage)}
                        value={language}
                        style = {{ color: '#1976D2', }}
                        size= 'small'
                     />
            </ListItemAvatar>
            <ListItemText primary={<Typography variant = "body2" style={{fontSize: 14}} >{t(`${language}`)}</Typography>} />
          </ListItem>
          {index !== 2 && <Divider />}
          </>
        ))}
      </List>
    </Dialog>
        </React.Fragment>
    )
}