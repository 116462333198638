import { AppBar, BottomNavigation, Button, Divider, Modal, Toolbar, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderAction } from "../../../../action/order.action";
import { OrderActor } from "../../../../actor/order.actor";
import { OrderStatus } from "../../../../enum/order-status.enum";
import { useRouteManager } from "../../../../router/route.manager";
import { DelarOrderProduct, SelectableDelarOrderProduct, ShipmentProductStatusGroup } from "../../../../service/response/shipement.response";
import { OrderStore } from "../../../../store/order.store";
import { useObservable } from "../../../../useSubscriber";
import { CancelledOrderDetailComponent } from "./order-detail-views/cancelled/cancelled-order.component";
import { CompletedOrderDetailComponent } from "./order-detail-views/completed/completedOrders.component";
import { DeclinedOrderDetailComponent } from "./order-detail-views/declined/declined.component";
import { NewOrderDetailComponent } from "./order-detail-views/new/newOrders.component";
import { PendingOrderDetailComponent } from "./order-detail-views/pending/pending-order.component";
import { ReturnPendingOrderDetailComponent } from "./order-detail-views/return-pending/return-pending.component";
import { ReturnedOrderDetailComponent } from "./order-detail-views/return-picked/return-picked.component";
import { useStyles } from "./shipment.style";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { DATE_FORMAT, SUPPORT_NUMBER } from "../../../../constant/base.constant";
import { SecurityManager } from "../../../../security/security.manager";
import { UpdateOrderStatusDto } from "../../../../service/dto/order.dto";
import { useTranslation } from "react-i18next";
import { AndroidConnerctor } from "../../../../android.connecter";
import LoadingComponent from "../../../common/loading/loading.component";
import { OutForDeliveryOrderDetailComponent } from "./order-detail-views/out-for-delivery/out-for-delivery-order.component";


export const ShipmentComponent = () => {
    const classes = useStyles();
    const routeManager = useRouteManager()

    const { shipmentId } = useParams()
    const { shipmentStatus } = useParams()

    const [shipmentDetail, _] = useObservable(OrderStore.getShipmentDetail(), OrderStore.getShipmentDetailObservable())

    const [loading, setLoading] = useState(true)

    const afterShipmentFetch = () => {
        setLoading(false)
    }

    const [cancelledCount, setCancelledCount] = useState(0)
    const [pendingCount, setPendingCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [orderAmount, setOrderAmount] = useState(0)
    const { t } = useTranslation()
    const [allItemSelected, setAllItemSelected] = useState(false)

    const [deliveredDate, setDeliveredDate] = useState(null)
    const [deliveryTime, setDeliveryTime] = useState(null)
    const [addressLine1, setAddressLine1] = useState("")
    const [addressLine2, setAddressLine2] = useState("")

    let _updateOrderDtos: UpdateOrderStatusDto[] = []
    const updateOrderDtos = useRef(_updateOrderDtos)

    useEffect(() => {
        setLoading(true)
        OrderActor({ "type": OrderAction.GET_SHIPMENT_DETAIL }, { "shipmentId": shipmentId, "afterShipmentFetch": afterShipmentFetch })
    }, [])

    useEffect(() => {
        if (shipmentDetail && shipmentDetail.productStatusGroups) {
            let _cancelledCount = 0
            let _totalCount = 0
            let _orderAmount = 0
            let _pendingCount = 0
            shipmentDetail.productStatusGroups.map((productStatusGroup: ShipmentProductStatusGroup, index: number) => {
                setDeliveredDate(new Date(productStatusGroup.status.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT))
                if (productStatusGroup.status.value == OrderStatus.ORDER_DELIVERED) {
                    setDeliveredDate(new Date(productStatusGroup.status.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT))
                } else if (productStatusGroup.status.value == OrderStatus.ORDER_PENDING || productStatusGroup.status.value == OrderStatus.RETURN_ACCEPTED_BY_ADMIN) {
                    setDeliveryTime(new Date(new Date(productStatusGroup.status.createdOn).getMilliseconds() + 24 * 60 * 60 * 1000).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT))
                }
                productStatusGroup.products.map((product: DelarOrderProduct, index: number) => {
                    if (product.latestStatus.value != OrderStatus.ORDER_CANCELLED_BY_FARMER) {
                        _cancelledCount = _cancelledCount + 1
                    }
                    if (product.latestStatus.value == OrderStatus.ORDER_ACCEPTED_BY_ADMIN) {
                        _pendingCount = _pendingCount + 1
                    }
                    _totalCount = _totalCount + 1
                    _orderAmount = _orderAmount + product.pack.sellingPrice * product.pack.quantity
                })
            })
            setPendingCount(_pendingCount)
            setCancelledCount(_cancelledCount)
            setTotalCount(_totalCount)
            setOrderAmount(_orderAmount / 100)

            setAddressLine1(`${shipmentDetail.address.streetLine}, ${shipmentDetail.address.streetLine}`)
            setAddressLine2(`${shipmentDetail.address.geoAddress.village.name}, ${shipmentDetail.address.geoAddress.subDistrict.name}, ${shipmentDetail.address.geoAddress.district.name}, ${shipmentDetail.address.geoAddress.state.name}`)
        }
    }, [shipmentDetail])

    const updateItems = (selectableDelarOrderProducts: Array<SelectableDelarOrderProduct>) => {
        let _allSelected = true
        _updateOrderDtos = []
        selectableDelarOrderProducts.map((selectableDelarOrderProduct: SelectableDelarOrderProduct) => {
            if (selectableDelarOrderProduct.accepted) {
                _updateOrderDtos.push({
                    "userId": selectableDelarOrderProduct.delarOrderProduct.delarId,
                    "userType": 3,
                    "status": {
                        "value": OrderStatus.ORDER_ACCEPTED_BY_DELAR,
                        "msg": "Order accepted by Delar"
                    },
                    "orderProductId": selectableDelarOrderProduct.delarOrderProduct.id,
                } as UpdateOrderStatusDto)
            } else if (selectableDelarOrderProduct.rejected) {
                _updateOrderDtos.push({
                    "userId": selectableDelarOrderProduct.delarOrderProduct.delarId,
                    "userType": 3,
                    "status": {
                        "value": OrderStatus.ORDER_REJECTED_BY_DELAR,
                        "msg": "Order rejected by Delar"
                    },
                    "orderProductId": selectableDelarOrderProduct.delarOrderProduct.id,
                } as UpdateOrderStatusDto)
            } else {
                _allSelected = false
            }
        })

        updateOrderDtos.current = _updateOrderDtos
        setAllItemSelected(_allSelected)
    }

    const afterPackUpdate = () => {
        routeManager.goBack()
    }

    const confirmOrder = () => {
        OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": updateOrderDtos.current, "afterPackUpdate": afterPackUpdate })
    }

    const orderDeliver = () => {
        if (shipmentDetail && shipmentDetail.productStatusGroups) {
            let newUpdateOrderDtos: any[] = []
            shipmentDetail.productStatusGroups.map((productStatusGroup: ShipmentProductStatusGroup, index: number) => {
                if (productStatusGroup.status.value == OrderStatus.ORDER_ACCEPTED_BY_DELAR) {
                    productStatusGroup.products.map((delarOrderProduct: DelarOrderProduct) => {
                        newUpdateOrderDtos.push({
                            "userId": delarOrderProduct.delarId,
                            "userType": 3,
                            "status": {
                                "value": OrderStatus.ORDER_DELIVERED,
                                "msg": "Order Delivered by Delar"
                            },
                            "orderProductId": delarOrderProduct.id,
                        } as UpdateOrderStatusDto)
                    })
                }
            })
            OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": newUpdateOrderDtos, "afterPackUpdate": afterPackUpdate })
        }
    }

    const returnPick = () => {
        if (shipmentDetail && shipmentDetail.productStatusGroups) {
            let newUpdateOrderDtos: any[] = []
            shipmentDetail.productStatusGroups.map((productStatusGroup: ShipmentProductStatusGroup, index: number) => {
                if (productStatusGroup.status.value == OrderStatus.RETURN_ACCEPTED_BY_ADMIN) {
                    productStatusGroup.products.map((delarOrderProduct: DelarOrderProduct) => {
                        newUpdateOrderDtos.push({
                            "userId": delarOrderProduct.delarId,
                            "userType": 3,
                            "status": {
                                "value": OrderStatus.RETURN_PICKED,
                                "msg": "Order Delivered by Delar"
                            },
                            "orderProductId": delarOrderProduct.id,
                        } as UpdateOrderStatusDto)
                    })
                }
            })
            OrderActor({ "type": OrderAction.UPDATE_ORDER_PRODUCT_STATUS }, { "updateOrderDtos": newUpdateOrderDtos, "afterPackUpdate": afterPackUpdate })
        }
    }

    return (
        <>
            {!loading && (
                <>
                    <AppBar position="sticky" style={{ boxShadow: 'none' }}>
                        <Toolbar>
                            <ArrowBackIcon className={classes.backIcon} onClick={() => { routeManager.goBack() }} />
                            <Typography variant="h6" className={classes.title}>
                                {shipmentId}
                            </Typography>
                            <Button variant="contained" color="primary" className={classes.supportButton}
                                onClick={() => AndroidConnerctor.openSupportDialog(SUPPORT_NUMBER)}>
                                <img style={{ width: 16, height: 16, marginRight: 8, }} src='/asset/support icon.svg' />
                                <Typography style={{ opacity: 0.8, fontSize: 12 }}>
                                    {t("support")}
                                </Typography>
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {shipmentDetail && shipmentDetail.productStatusGroups && (
                        <>
                            {Number(shipmentStatus) == OrderStatus.ORDER_ACCEPTED_BY_ADMIN && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingPending}>
                                            <b>{t("order_delivery_pending")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingNew}>
                                            <b>{t("farmer_name")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{shipmentDetail.address.name}</Typography>
                                    </div>
                                </React.Fragment>
                            )}
                            {Number(shipmentStatus) == OrderStatus.ORDER_ACCEPTED_BY_DELAR && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingPending}>
                                            <b>{t("order_delivery_pending")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingNew}>
                                            <b>{t("farmer_name")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{shipmentDetail.address.name}</Typography>
                                    </div>
                                    <Divider />
                                    <div style={{ color: "red", fontSize: "20px", margin: 16, textAlign: "center" }}>Only share the OTP to the delivery person after you hand them over the product</div>
                                    <div style={{ textAlign: "center", fontSize: "20px" }}><b>OTP</b></div>
                                    <div style={{ textAlign: "center", fontSize: "20px" }}>{shipmentDetail.delarOTP}</div>

                                </React.Fragment>
                            )}

                            {(Number(shipmentStatus) == OrderStatus.ORDER_DELIVERED || Number(shipmentStatus) == OrderStatus.ORDER_OUT_FOR_DELIVERY) && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingNew}>
                                            <b>{t("common_delivered")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <Divider />
                                    <Typography variant="body2" className={classes.orderTotal}>
                                        {t("common_order_total")} : {orderAmount}
                                    </Typography>
                                    <Typography variant="body2" className={classes.shippingAddressLine1}>
                                        {t("common_delivered_on")} : {`${deliveredDate}`}
                                    </Typography>

                                </React.Fragment>
                            )}
                            {Number(shipmentStatus) == OrderStatus.RETURN_ACCEPTED_BY_ADMIN && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingNew}>
                                            <b>{t("common_delivered")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <Divider />
                                    <Typography variant="body2" className={classes.orderTotal}>
                                        {t("common_order_total")} : {orderAmount}
                                    </Typography>
                                    <Typography variant="body2" className={classes.shippingAddressLine1}>
                                        {t("common_returned_on")} : {`${deliveredDate}`}
                                    </Typography>
                                </React.Fragment>
                            )}
                            {Number(shipmentStatus) == OrderStatus.RETURN_PICKED && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingReturned}>
                                            <b>{t("order_returned")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <Divider />
                                    <Typography variant="body2" className={classes.orderTotal}>
                                        {t("common_order_total")} : {orderAmount}
                                    </Typography>
                                    <Typography variant="body2" className={classes.shippingAddressLine1}>
                                        {t("common_returned_on")} : {`${deliveredDate}`}
                                    </Typography>

                                    <Divider />
                                    <div style={{ color: "red", fontSize: "20px", margin: 16, textAlign: "center" }}>Only share the OTP to the delivery person after you take the return product</div>
                                    <div style={{ textAlign: "center", fontSize: "20px" }}><b>OTP</b></div>
                                    <div style={{ textAlign: "center", fontSize: "20px" }}>{shipmentDetail.delarOTP}</div>

                                </React.Fragment>
                            )}
                            {Number(shipmentStatus) == OrderStatus.RETURN_ACCEPTED_BY_ADMIN && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingPending}>
                                            <b>{t("shipment_return_pending")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                </React.Fragment>
                            )}
                            {(Number(shipmentStatus) == OrderStatus.ORDER_CANCELLED_BY_FARMER) && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingReturned}>
                                            <b>{t('order_cancelled')}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <Divider />
                                    <Typography variant="body2" className={classes.shippingAddressLine1}>
                                        {t("common_order_total")} : {orderAmount}
                                    </Typography>
                                </React.Fragment>
                            )}
                            {Number(shipmentStatus) == OrderStatus.ORDER_REJECTED_BY_DELAR && (
                                <React.Fragment>
                                    <div className={classes.primaryContainer}>
                                        <Typography variant="body2" className={classes.statusHeadingReturned}>
                                            <b>{t("order_declined")}</b>
                                        </Typography>
                                        <Typography variant="body2" className={classes.time}>{new Date(shipmentDetail.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                                    </div>
                                    <Divider />
                                    <Typography variant="body2" className={classes.shippingAddressLine1}>
                                        {t("common_order_total")} : {orderAmount}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <div className={classes.root}>
                                <div className={classes.buttonContainer}>
                                </div>
                                <Divider style={{ height: 8, }} />
                                {shipmentDetail.productStatusGroups.map((productStatusGroup: ShipmentProductStatusGroup, index: any) => {
                                    return (
                                        <React.Fragment>
                                            {(productStatusGroup.status.value === OrderStatus.ORDER_ACCEPTED_BY_ADMIN) && (
                                                <NewOrderDetailComponent productList={productStatusGroup.products} updateItems={updateItems} />
                                            )}
                                            {productStatusGroup.status.value === OrderStatus.ORDER_ACCEPTED_BY_DELAR && (
                                                <PendingOrderDetailComponent productList={productStatusGroup.products} />
                                            )}
                                            {productStatusGroup.status.value === OrderStatus.ORDER_OUT_FOR_DELIVERY && (
                                                <OutForDeliveryOrderDetailComponent productList={productStatusGroup.products} shipmentId={shipmentDetail.id} />
                                            )}
                                            {productStatusGroup.status.value === OrderStatus.ORDER_CANCELLED_BY_FARMER && (
                                                <CancelledOrderDetailComponent productList={productStatusGroup.products} />
                                            )}
                                            {productStatusGroup.status.value === OrderStatus.ORDER_DELIVERED && (
                                                <CompletedOrderDetailComponent productList={productStatusGroup.products} shipmentId={shipmentDetail.id} />
                                            )}
                                            {productStatusGroup.status.value === OrderStatus.ORDER_REJECTED_BY_DELAR && (
                                                <DeclinedOrderDetailComponent productList={productStatusGroup.products} />
                                            )}
                                            {(productStatusGroup.status.value === OrderStatus.RETURN_ACCEPTED_BY_ADMIN || productStatusGroup.status.value === OrderStatus.RETURN_ACCEPTED_BY_DELAR) && (
                                                <ReturnPendingOrderDetailComponent productList={productStatusGroup.products} />
                                            )}
                                            {productStatusGroup.status.value === OrderStatus.RETURN_PICKED && (
                                                <ReturnedOrderDetailComponent productList={productStatusGroup.products} shipmentId={shipmentDetail.id} />

                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>

                            {pendingCount > 0 && (

                                <BottomNavigation showLabels className={classes.bottomNavigation}>
                                    {allItemSelected && <Button className={classes.nextButton} variant="contained" color="primary" onClick={() => confirmOrder()}>
                                        {t("common_confirm_order")}
                                    </Button>}
                                    {!allItemSelected && <Button disabled className={classes.nextButton} variant="contained" color="primary" style={{ backgroundColor: '#b1b1b1', color: '#ffffff' }}>
                                        {t("common_confirm_order")}
                                    </Button>}
                                </BottomNavigation>
                            )}
                        </>
                    )}
                </>
            )}
            <Modal open={loading} className={classes.modal}><LoadingComponent /></Modal>
        </>
    )
}