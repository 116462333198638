import { BehaviorSubject, Observable } from "rxjs"
import { DelarStatus } from "../enum/delar-status.enum"
import { DtoError } from "../model/dto-error.model"
import { Media } from "../model/media.model"
import { SecurityManager } from "../security/security.manager"
import { DelarDto, DelarDtoError } from "../service/dto/delar.dto"
import { NotificationDto } from "../service/dto/notification.dto"
import { Delar } from "../service/response/delar.response"
import { District } from "../service/response/district.response"
import { NotificationRes } from "../service/response/notification.response"
import { State } from "../service/response/state.response"
import { SubDistrict } from "../service/response/sub-district.response"
import { Village } from "../service/response/village.response"

let _delarDto: DelarDto = {} as DelarDto

let _delarDtoError: DelarDtoError = {
    phone: new DtoError("", false),
    name: new DtoError("", false),
    language: new DtoError("", false),
    intro: new DtoError("", false),
    alternatePhone: new DtoError("", false),
    shopName: new DtoError("", false),
    gstDetail: new DtoError("", false),
    shopAddress: new DtoError("", false),
    shopPhotos: new DtoError("Upload atleast 1 image", false),
    pesticideLiscence: new DtoError("", false),
    fertilizerLiscence: new DtoError("", false),
    seedLiscence: new DtoError("", false),
    status: new DtoError("", false),
    location: new DtoError("", false),
    pinCodeError: new DtoError("", false),
    state: new DtoError("This is a required field", false),
    district: new DtoError("This is a required field", false),
    city: new DtoError("This is a required field", false),
    village: new DtoError("This is a required field", false),
    pesticidePhoto: new DtoError("Upload atleast 1 image", false),
    fertilizerPhoto: new DtoError("Upload atleast 1 image", false),
    seedPhoto: new DtoError("Upload atleast 1 image", false),
} as DelarDtoError

let _delarDtoSubject = new BehaviorSubject<DelarDto | null>(null)
let _delarDtoErrorSubject = new BehaviorSubject<DelarDtoError | null>(_delarDtoError)

let _delarDetail: BehaviorSubject<Delar | null> = new BehaviorSubject<Delar | null>(null)

let _notificationDto: NotificationDto = { userType: 3, userId: SecurityManager.getUid(), limit: 50 } as NotificationDto
let _notificationDtoSubject = new BehaviorSubject<NotificationDto | null>(null)

let _notificationList: Array<NotificationRes> = []
let _notificationListSubject = new BehaviorSubject<Array<NotificationRes> | null>(null)

let _delarInfo: Delar
let _delarInfoSubject = new BehaviorSubject<Delar | null>(null)
let _addVillageId = -1
let _addVillageIdSubject = new BehaviorSubject<number | null>(_addVillageId)

const getMediaObjectFromArray = (newMedias: Array<Media>): any => {
    const photos = new Map()
    newMedias.forEach((media: Media) => {
        photos.set(media.fid, media)
    })

    return Object.fromEntries(photos)
}

const checkPhoneError = () => {
    if (!_delarDto || _delarDto.phone?.length > 10 || _delarDto.phone?.length < 10) {
        _delarDtoError.phone.error = true
        _delarDtoError.phone.msg = "Phone should contain 10 digits"
    } else {
        _delarDtoError.phone.error = false
        _delarDtoError.phone.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkNameError = () => {
    if (!_delarDto || _delarDto?.name?.length < 3 || _delarDto?.name?.length > 25) {
        _delarDtoError.name.error = true
        _delarDtoError.name.msg = "Name should have minimum 4 characters"
    } else {
        _delarDtoError.name.error = false
        _delarDtoError.name.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkIntroError = () => {
    if (!_delarDto || _delarDto?.intro?.length < 4 || _delarDto?.intro?.length > 80) {
        _delarDtoError.intro.error = true
        _delarDtoError.intro.msg = "Introduction should be within 400 charcaters"
    } else {
        _delarDtoError.intro.error = false
        _delarDtoError.intro.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkAlternatePhoneError = () => {
    if (!_delarDto || _delarDto?.alternatePhone?.length > 10 || _delarDto?.alternatePhone?.length < 10) {
        _delarDtoError.alternatePhone.error = true
        _delarDtoError.alternatePhone.msg = "Phone should contain 10 digits"
    } else {
        _delarDtoError.alternatePhone.error = false
        _delarDtoError.alternatePhone.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkShopNameError = () => {
    if (!_delarDto || _delarDto?.shopName?.newName.length < 4) {
        _delarDtoError.shopName.error = true
        _delarDtoError.shopName.msg = "Shop Name should have minimum 4 charcters"
    } else {
        _delarDtoError.shopName.error = false
        _delarDtoError.shopName.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkGSTNumberError = () => {
    const reggst = /([0-9]{2}[a-z]{4}([a-z]{1}|[0-9]{1}).[0-9]{3}[a-z]([a-z]|[0-9]){3})/i;
    if (!_delarDto || _delarDto?.gstDetail?.newGstNumber?.number.length < 3 || !reggst.test(_delarDto?.gstDetail?.newGstNumber?.number)) {
        _delarDtoError.gstDetail.error = true
        _delarDtoError.gstDetail.msg = "Enter valid gst number"
    } else {
        _delarDtoError.gstDetail.error = false
        _delarDtoError.gstDetail.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkShopAddressError = () => {
    if (!_delarDto || _delarDto?.shopAddress.streetLine?.length < 4) {
        _delarDtoError.shopAddress.error = true
        _delarDtoError.shopAddress.msg = "Street Address should have minimum 4 charcters"
    } else {
        _delarDtoError.shopAddress.error = false
        _delarDtoError.shopAddress.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkShopPhotosError = () => {

    if (!_delarDto || _delarDto?.shopPhotos?.size > 0) {
        _delarDtoError.shopPhotos.error = false
        _delarDtoError.shopPhotos.msg = ""
    } else {
        _delarDtoError.shopPhotos.error = true
        _delarDtoError.shopPhotos.msg = "Upload atleast 1 image"
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })

}
const checkSeedPhotosError = () => {

    if (_delarDto.seedLiscence.newLicence.number?.length == 0 || (_delarDto && _delarDto.seedLiscence.newLicence.photos && Object.entries(_delarDto.seedLiscence.newLicence.photos).length > 0)) {
        _delarDtoError.seedPhoto.error = false
        _delarDtoError.seedPhoto.msg = ""
    } else {
        _delarDtoError.seedPhoto.error = true
        _delarDtoError.seedPhoto.msg = "Upload atleast 1 image"
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })

}
const checkPesticidePhotosError = () => {
    if (_delarDto.pesticideLiscence.newLicence.number?.length == 0 || (_delarDto && _delarDto.pesticideLiscence.newLicence.photos && Object.entries(_delarDto.pesticideLiscence.newLicence.photos).length > 0)) {
        _delarDtoError.pesticidePhoto.error = false
        _delarDtoError.pesticidePhoto.msg = ""
    } else {
        _delarDtoError.pesticidePhoto.error = true
        _delarDtoError.pesticidePhoto.msg = "Upload atleast 1 image"
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkFertiliserPhotosError = () => {
    if (_delarDto.fertilizerLiscence.newLicence.number?.length == 0 || (_delarDto && _delarDto.fertilizerLiscence.newLicence.photos && Object.entries(_delarDto.fertilizerLiscence.newLicence.photos).length > 0)) {
        _delarDtoError.fertilizerPhoto.error = false
        _delarDtoError.fertilizerPhoto.msg = ""
    } else {
        _delarDtoError.fertilizerPhoto.error = true
        _delarDtoError.fertilizerPhoto.msg = "Upload atleast 1 image"
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })

}

const checkStateError = () => {
    if (_delarDto?.shopAddress?.geoAddress?.state &&
        _delarDto?.shopAddress?.geoAddress?.state.name.length > 0 &&
        _delarDto?.shopAddress?.geoAddress?.state.code > 0)
        _delarDtoError.state.error = false
    else {
        _delarDtoError.state.error = true
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkDistrictError = () => {
    if (_delarDto?.shopAddress?.geoAddress?.district &&
        _delarDto?.shopAddress?.geoAddress?.district.name.length > 0 &&
        _delarDto?.shopAddress?.geoAddress?.district.code > 0)
        _delarDtoError.district.error = false
    else
        _delarDtoError.district.error = true
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkSubDisctrictError = () => {
    if (_delarDto?.shopAddress?.geoAddress?.subDistrict &&
        _delarDto?.shopAddress?.geoAddress?.subDistrict.name.length > 0 &&
        _delarDto?.shopAddress?.geoAddress?.subDistrict.code > 0)
        _delarDtoError.city.error = false
    else
        _delarDtoError.city.error = true
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkVillageError = () => {
    if (_delarDto?.shopAddress?.geoAddress?.village &&
        _delarDto?.shopAddress?.geoAddress?.village.name.length > 0 &&
        _delarDto?.shopAddress?.geoAddress?.village.code > 0)
        _delarDtoError.village.error = false
    else
        _delarDtoError.village.error = true
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}

const checkPincodeError = () => {
    if (!_delarDto || _delarDto?.shopAddress?.pinCode != 0 && _delarDto?.shopAddress?.pinCode.toString().length != 6) {
        _delarDtoError.pinCodeError.error = true
        _delarDtoError.pinCodeError.msg = "Pin code should have 6 charcters"
    } else {
        _delarDtoError.pinCodeError.error = false
        _delarDtoError.pinCodeError.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}
const checkPesticideLicense = () => {
    if (_delarDto.pesticideLiscence.newLicence.number?.length < 3) {
        _delarDtoError.pesticideLiscence.error = true
        _delarDtoError.pesticideLiscence.msg = "License Number should contain more then 3 number"
    }
    else {
        _delarDtoError.pesticideLiscence.error = false
        _delarDtoError.pesticideLiscence.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}
const checkFertiliserLicense = () => {
    if (_delarDto.fertilizerLiscence.newLicence.number?.length > 0 && _delarDto.fertilizerLiscence.newLicence.number?.length < 3) {
        _delarDtoError.fertilizerLiscence.error = true
        _delarDtoError.fertilizerLiscence.msg = "License Number should contain more then 3 number"
    }
    else {
        _delarDtoError.fertilizerLiscence.error = false
        _delarDtoError.fertilizerLiscence.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}
const checkSeedLicense = () => {
    if (_delarDto.seedLiscence.newLicence.number?.length > 0 && _delarDto.seedLiscence.newLicence.number?.length < 3) {
        _delarDtoError.seedLiscence.error = true
        _delarDtoError.seedLiscence.msg = "License Number should contain more then 3 number"
    }
    else {
        _delarDtoError.seedLiscence.error = false
        _delarDtoError.seedLiscence.msg = ""
    }
    _delarDtoErrorSubject.next({ ..._delarDtoError })
}
export const DelarStore = {
    getDelarDto: (): DelarDto | null => {
        return { ..._delarDto }
    },
    getDelarDtoError: (): DelarDtoError | null => {
        return { ..._delarDtoError }
    },
    setDelarDto: (newDelarDto: DelarDto) => {
        _delarDto = newDelarDto
        _delarDtoSubject.next({ ..._delarDto })
    },
    updatePhone: (phone: string) => {
        _delarDto.phone = phone
        _delarDtoSubject.next({ ..._delarDto })
        checkPhoneError()
    },
    updateName: (name: string) => {
        _delarDto.name = name
        _delarDtoSubject.next({ ..._delarDto })
        checkNameError()
    },
    updateLanguage: (language: string) => {
        _delarDto.language = language
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateIntro: (intro: string) => {
        _delarDto.intro = intro
        _delarDtoSubject.next({ ..._delarDto })
        checkIntroError()
    },
    updateAlternatePhone: (alternatePhone: string) => {
        _delarDto.alternatePhone = alternatePhone
        _delarDtoSubject.next({ ..._delarDto })
        checkAlternatePhoneError()
    },
    updateShopName: (shopName: string) => {
        _delarDto.shopName.newName = shopName
        _delarDto.shopName.status = DelarStatus.DELER_DATA_FIELD_PENDING
        _delarDtoSubject.next({ ..._delarDto })
        checkShopNameError()
    },
    updateGSTNumber: (gstNumber: string) => {
        if (!_delarDto.gstDetail || !_delarDto.gstDetail.newGstNumber) {
            _delarDto.gstDetail = {
                gstNumber: {
                    number: "",
                    photos: new Map()
                },
                newGstNumber: {
                    number: "",
                    photos: new Map()
                },
                status: 1
            }
        } else if (!_delarDto.gstDetail.newGstNumber) {
            _delarDto.gstDetail.newGstNumber = {
                number: "",
                photos: null
            }
        }
        _delarDto.gstDetail.newGstNumber.number = gstNumber.toUpperCase()
        _delarDto.gstDetail.status = DelarStatus.DELER_DATA_FIELD_PENDING
        _delarDtoSubject.next({ ..._delarDto })
        checkGSTNumberError()
    },
    updateStreetAddress: (streetLine: string) => {
        _delarDto.shopAddress.streetLine = streetLine
        _delarDtoSubject.next({ ..._delarDto })
        checkShopAddressError()
    },
    updateLandmark: (landMark: string) => {
        _delarDto.shopAddress.landMark = landMark
        _delarDtoSubject.next({ ..._delarDto })
    },
    updatePinCode: (pinCode: number) => {
        _delarDto.shopAddress.pinCode = Number(pinCode)
        _delarDtoSubject.next({ ..._delarDto })
        checkPincodeError()
    },
    updateNewPesticideLicenseNumber: (number: string) => {
        _delarDto.pesticideLiscence.newLicence.number = number
        _delarDto.pesticideLiscence.status = DelarStatus.DELER_DATA_FIELD_PENDING
        _delarDtoSubject.next({ ..._delarDto })
        checkPesticideLicense()
    },
    updateNewFertilizerLicenseNumber: (number: string) => {
        _delarDto.fertilizerLiscence.newLicence.number = number
        _delarDto.fertilizerLiscence.status = DelarStatus.DELER_DATA_FIELD_PENDING
        _delarDtoSubject.next({ ..._delarDto })
        checkFertiliserLicense()
    },
    updateNewSeedLicenseNumber: (number: string) => {
        _delarDto.seedLiscence.newLicence.number = number
        _delarDto.seedLiscence.status = DelarStatus.DELER_DATA_FIELD_PENDING
        _delarDtoSubject.next({ ..._delarDto })
        checkSeedLicense()
    },
    updateStatus: (status: number, msg: string) => {
        _delarDto.status.value = status
        _delarDto.status.msg = msg
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateState: (state: State) => {
        if (!state || (_delarDto.shopAddress.geoAddress.state &&
            _delarDto.shopAddress.geoAddress.state.id != state.id)) {
            delete _delarDto.shopAddress.geoAddress.district
            delete _delarDto.shopAddress.geoAddress.subDistrict
            delete _delarDto.shopAddress.geoAddress.village
        }
        _delarDto.shopAddress.geoAddress.state = state
        _delarDtoSubject.next({ ..._delarDto })
        checkStateError()
    },
    updateDistrict: (district: District) => {
        if (!district || (_delarDto.shopAddress.geoAddress.district &&
            _delarDto.shopAddress.geoAddress.district.id != district.id)) {
            delete _delarDto.shopAddress.geoAddress.subDistrict
            delete _delarDto.shopAddress.geoAddress.village
        }
        _delarDto.shopAddress.geoAddress.district = district
        _delarDtoSubject.next({ ..._delarDto })
        checkDistrictError()
    },
    updateSubDistrict: (subDistrict: SubDistrict) => {
        if (!subDistrict || (_delarDto.shopAddress.geoAddress.subDistrict &&
            _delarDto.shopAddress.geoAddress.subDistrict.id != subDistrict.id)) {
            delete _delarDto.shopAddress.geoAddress.village
        }
        _delarDto.shopAddress.geoAddress.subDistrict = subDistrict
        _delarDtoSubject.next({ ..._delarDto })
        checkSubDisctrictError()
    },
    updateVillage: (village: Village) => {
        _delarDto.shopAddress.geoAddress.village = village
        _delarDtoSubject.next({ ..._delarDto })
        checkVillageError()
    },
    updateShopImages: (newImages: Array<Media>) => {

        _delarDto.shopPhotos = { ..._delarDto.shopPhotos, ...getMediaObjectFromArray(newImages) }
        _delarDtoSubject.next({ ..._delarDto })
        checkShopPhotosError()
    },
    updateNewPesticideImages: (newImages: Array<Media>) => {
        _delarDto.pesticideLiscence.newLicence.photos = { ..._delarDto.pesticideLiscence.newLicence.photos, ...getMediaObjectFromArray(newImages) }
        _delarDtoSubject.next({ ..._delarDto })
        checkPesticidePhotosError()
    },
    updateNewFertiliserImages: (newImages: Array<Media>) => {
        _delarDto.fertilizerLiscence.newLicence.photos = { ..._delarDto.fertilizerLiscence.newLicence.photos, ...getMediaObjectFromArray(newImages) }
        _delarDtoSubject.next({ ..._delarDto })
        checkFertiliserPhotosError()
    },
    updateNewSeedImages: (newImages: Array<Media>) => {
        _delarDto.seedLiscence.newLicence.photos = { ..._delarDto.seedLiscence.newLicence.photos, ...getMediaObjectFromArray(newImages) }
        _delarDtoSubject.next({ ..._delarDto })
        checkSeedPhotosError()
    },
    deleteShopPhoto: (key: string) => {
        delete (_delarDto.shopPhotos as any)[key]
        _delarDtoSubject.next({ ..._delarDto })
    },
    deleteNewPesticidePhoto: (key: string) => {
        delete (_delarDto.pesticideLiscence.newLicence.photos as any)[key]
        _delarDtoSubject.next({ ..._delarDto })
    },
    deleteNewFertiliserPhoto: (key: string) => {
        delete (_delarDto.fertilizerLiscence.newLicence.photos as any)[key]
        _delarDtoSubject.next({ ..._delarDto })
    },
    deleteNewSeedPhoto: (key: string) => {
        delete (_delarDto.seedLiscence.newLicence.photos as any)[key]
        _delarDtoSubject.next({ ..._delarDto })
    },
    getDelarDtoObservable: (): Observable<DelarDto | null> => {
        return _delarDtoSubject.asObservable()
    },
    getDelarDtoErrorObservable: (): Observable<DelarDtoError | null> => {
        return _delarDtoErrorSubject.asObservable()
    },
    getAddVillageIdObservable: (): Observable<number | null> => {
        return _addVillageIdSubject.asObservable()
    },
    upadteDelarInfo: (newDelarInfo: Delar) => {
        _delarInfo = newDelarInfo
        _delarInfoSubject.next(_delarInfo)
    },
    reInitDelarDto: (delar: Delar) => {
        _delarDto = {
            name: delar.name,
            intro: delar.intro,
            alternatePhone: delar.alternatePhone,
            shopName: delar.shopName,
            gstDetail: delar.gstDetail,
            status: delar.status,
            shopAddress: delar.shopAddress,
            shopPhotos: delar.shopPhotos,
            pesticideLiscence: delar.pesticideLiscence,
            fertilizerLiscence: delar.fertilizerLiscence,
            seedLiscence: delar.seedLiscence,
            active: true
        } as DelarDto
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateShopPhotosError: (flag: boolean) => {
        _delarDtoError.shopPhotos.error = flag
        _delarDtoErrorSubject.next({ ..._delarDtoError })
    },
    updateSeedImagesError: (flag: boolean) => {
        _delarDtoError.seedPhoto.error = flag
        _delarDtoErrorSubject.next({ ..._delarDtoError })
    },
    updateFertiliserImagesError: (flag: boolean) => {
        _delarDtoError.fertilizerPhoto.error = flag
        _delarDtoErrorSubject.next({ ..._delarDtoError })
    },
    updatePesticideImagesError: (flag: boolean) => {
        _delarDtoError.pesticidePhoto.error = flag
        _delarDtoErrorSubject.next({ ..._delarDtoError })
    },
    getDelarInfo: (): Delar | null => {
        return _delarInfo
    },
    getDelarInfoObservable: (): Observable<Delar | null> => {
        return _delarInfoSubject.asObservable()
    },
    getDelar: (): Delar | null => {
        return _delarDetail.value
    },
    setDelar: (newDelar: Delar) => {
        _delarDetail.next({ ...newDelar })
    },
    getDelarObservable: (): Observable<Delar | null> => {
        return _delarDetail.asObservable()
    },
    getNotificationDto: (): NotificationDto => {
        return _notificationDto
    },
    getNotificationDtoObservable: (): Observable<NotificationDto | null> => {
        return _notificationDtoSubject.asObservable()
    },
    upadteNotificationList: (newNotificationList: Array<NotificationRes>) => {
        _notificationList = [...newNotificationList]
        _notificationListSubject.next(_notificationList)
    },
    getNotificationList: (): Array<NotificationRes> | null => {
        return _notificationList
    },
    getNotificationListObservable: (): Observable<Array<NotificationRes> | null> => {
        return _notificationListSubject.asObservable()
    },
    checkErrorPersonalDetailPage: () => {
        checkNameError()
        // checkAlternatePhoneError()
        // checkIntroError()
    },
    checkErrorBussinessDetailPage: () => {
        checkNameError()
        // checkAlternatePhoneError()
        // checkIntroError()

        checkShopNameError()
        checkGSTNumberError()
        // checkShopPhotosError()
        checkShopAddressError()

        checkStateError()
        checkDistrictError()
        checkSubDisctrictError()
        checkVillageError()

        checkPincodeError()
    },
    checkErrorDocumentProoofPage: () => {
        checkNameError()
        // checkAlternatePhoneError()
        // checkIntroError()

        checkShopNameError()
        checkGSTNumberError()
        // checkShopPhotosError()
        checkShopAddressError()

        checkStateError()
        checkDistrictError()
        checkSubDisctrictError()
        checkVillageError()

        checkPincodeError()

        checkSeedPhotosError()
        checkPesticidePhotosError()
        checkFertiliserPhotosError()

        checkFertiliserLicense()
        checkPesticideLicense()
        checkSeedLicense()
    }
}