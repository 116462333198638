export const AddProductAction = {
    GET_LIST: "get_list",
    UPDATE_SEARCH_TEXT: "update_search_product",
    ADD_DELAR_PRODUCT_DTO: "add_delar_product_dto",
    DELETE_DELAR_PRODUCT_DTO: "delete_delar_product_dto",
    UPDATE_DELAR_PRODUCT_DTO: "update_delar_product_dto",
    UPDATE_FILTER_COMPANY: "update_filter_company",
    SET_SELECTED_PRODUCT: "set_selected_product",
    SET_CURRENT_PRODUCT: "set_current_product",
    UPDATE_PRODUCT_PACKS: "update_product_packs",
    UPDATE_DELAR_ID: "update_delar_id",
    UPDATE_PRODUCT_ID: "update_product_id",
    UPDATE_PACK_DATA: "update_pack_data",
    SAVE_DATA: "save_data",
    INIT_SEARCH_DTO_AND_PRODUCT_LIST: "init_search_dto_and_product_list",
    REMOVE_FILTER_COMPANY: "remove_filter_company"
}