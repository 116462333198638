import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appbar: {
      position: 'relative',
      bottom: 1,
      paddingBottom: 12,
      paddingTop: 4,
      zIndex: 0,
      boxShadow: 'none',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      marginRight: theme.spacing(2),
      marginLeft: 16,
      height: 40,
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 1),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000',
      opacity: 0.5,
    },
    inputRoot: {
      color: '#000',
    },
    inputInput: {
      padding: theme.spacing(2, 2, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      fontSize: 12,
      width: 'auto%',

    },

    topMenu: {

      display: "flex",
      width: "auto",
      height: 48,
      borderBottom: '1px solid #ccc',
    },
    buttonStyle: {

      textTransform: 'capitalize',
      width: 'auto',
      marginLeft: 12,
      marginTop: 0,
      marginRight: 16,
      fontSize: 12,
      letterSpacing: 0,
      whiteSpace: 'nowrap',
      fontWeight: 500,

    },
    separator: {
      width: 1,
      height: 56,
      background: "#000",
    },
    topIcon: {
      height: 16,
      width: 16,
      marginRight: 12,
    },
    productName: {
      fontSize: 14,
      marginLeft: 8,
      color: '#2A312F'
    },
    productDescription: {
      fontSize: 14,
      marginLeft: 8,
      color: '#2A312F'
    },
    nextButton: {
      position: 'fixed',
      right: 16,
      width: 174,
      height: 48,
      fontWeight: 500,
      fontSize: 13,
    },
    floatingIcon: {
      position: 'fixed',
      right: 16,
      bottom: 70,
      width: 174,
      height: 48,
      fontWeight: 700,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      margin: theme.spacing(1),
    },
    svg: {
      width: 100,
      height: 100,
    },
    polygon: {
      fill: theme.palette.common.white,
      stroke: theme.palette.divider,
      strokeWidth: 1,
    },
    container: {
      position: "fixed",
      visibility: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      zIndex: 21,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      overflow: "hidden"
    },
    backgroundContainer: {
      position: "absolute",
      zIndex: 1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.5)",
      fontWeight: 'bold',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    list: {
      position: "relative",
      zIndex: 2,
      listStyle: "none",
      margin: 0,

      backgroundColor: "#fff",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
      padding: '8px 0',
      transform: "translateY(0%)"
    },
    button: {
      backgroundColor: "#fff",
      border: 0,
      margin: 0,
      width: "100%",
      display: "flex",
      flex: "0 1 auto",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      textTransform: "none",
      lineHeight: "48px",
      padding: "0 16px",
      borderRadius: 0,
      outline: "none",
      cursor: "pointer"
    },
    text: {
      fontSize: "16px"
    },
    topButtonCard: {
      width: 'auto',
      marginTop: -2,
      height: 48,
      position: 'static',
      // padding: "12px 0px 12px 0px",
      paddingLeft: 16,
      overflowX: 'scroll',
      overFlowY: 'hidden',
      whiteSpace: 'nowrap',
      display: 'flex',
    },
    chip: {
      marginRight: 12,
      marginBottom: 4,
      backgroundColor: '#fff',
      border: '0.5px solid #0E4BB4',
      borderRadius: 2,
      fontSize: 11,
      marginTop: 8,
    },
    addPackButton: {
      fontSize: 13,
      textTransform: 'capitalize',
      borderRadius: 20,
      border: '1px solid #0E4BB4',
      width: 108,
      heigt: 32,
      letterSpacing: 0,
      paddingTop: 8,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
    },
    fillPackNoti: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
      fontSize: 14,
      paddingLeft: 16,
      paddingTop: 8,
      widtyh: 'auto',
      height: 32,
      borderBottom: '1px solid #ccc',
      background: '#ccc',

    },
    fillPackIcon: {
      position: 'relative',
      right: 12,
      fontSize: 10,
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      verticalAlign: 'middle',
      justifyContent: 'center',
      opacity: 0.5
    },
    img: {
      width: 274,
      height: 192,
      margin: "auto",
      marginBottom: 8,
      marginTop: "1%"
    },
    heading: {
      position: 'static',
      fontSize: 14,
      marginLeft: 124,
      fontWeight: 700,
    },
    description: {
      fontSize: 14,
      marginLeft: 36,
      marginRight: 36,
      marginTop: 8,
      maxLines: 3,
      textAlign: 'center',
      color: '#4B514F',
    },
  }),
);