import { Avatar, AppBar, Toolbar, BottomNavigation, Button,  Card, CardHeader, Container, Typography } from "@material-ui/core"
import React from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from "./edit-detail.style"

export const EditDetailComponent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title1}>
                        Change Address
                    </Typography>
                </Toolbar>
            </AppBar>
        <Card className={classes.subRoot1} >
            <CardHeader
                avatar = {<div className = {classes.numberContainer}>1</div>} 
                title={<Typography variant="body2" style ={{fontSize: 12, fontWeight: 'bold',}} >Personal Details</Typography>}
                action = {
                    <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.icon}  />
                }
            />
        </Card>
        <Card className={classes.subRoot} >
            <CardHeader
            avatar = {<div className = {classes.numberContainer}>2</div>}
                title={<Typography variant="body2" style ={{fontSize: 12, fontWeight: 'bold',}} >Business Details</Typography>}
                action = {
                    <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.icon}  />
                }
            />
        </Card>
        <Card className={classes.subRoot} >
            <CardHeader
                avatar = {<div className = {classes.numberContainer}>3</div>}
                title={<Typography variant="body2" style ={{fontSize: 12, fontWeight: 'bold',}} >Document Details</Typography>}
                action = {
                    <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.icon}  />
                }
            />
        </Card>
        <BottomNavigation
                showLabels
                className={classes.bottomNavigation}
            >
                <Button variant="contained" color="primary" className={classes.requestButton}>
                    Confirm Edit
                </Button>
            </BottomNavigation>
    </div>
    )
}