import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            margin: '12px 8px',
            flexDirection: 'column',
            
            justifyContent: 'center',
            alignItems: 'center',
        },
        img: {
            width: 224.52,
            height: 131.75,
            marginBottom: 8,
        },
        heading: {
            position: 'static',
            fontSize: 14,
            fontWeight: 500,
        },
        description: {
            fontSize: 12,
            opacity: 0.5,
            marginLeft: 67,
            marginRight: 67,
            marginTop: 8,
            textAlign: 'center',
        },
        rejected: {
            color: '#D51A1A',
            fontSize: 16,
            fontWeight: 900,
            marginTop: 4,
        },
        button: {
            
            textTransform: 'capitalize',
            padding: 10,
            fontSize: 13,
            opacity: 0.8,
            width: 132,
            height: 42,
            marginRight: 16,
            borderRadius: 20,
            fontWeight: 500,
            borderColor: '#0e4bb4',
        },
        buttonHolder: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 16,
            marginBottom: 16,
        },
    }),
);