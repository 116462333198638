import { AppBar, List, ListItem, Button, ListItemAvatar, ListItemText, Divider, Avatar, Container, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './notification.style'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { DelarActor } from "../../../actor/delar.actor"
import { DelarAction } from "../../../action/delar.action"
import { useObservable } from "../../../useSubscriber"
import { DelarStore } from "../../../store/delar.store"
import { Link } from 'react-router-dom'

export const NotificationComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [notificationList, _] = useObservable(DelarStore.getNotificationList(), DelarStore.getNotificationListObservable())

    useEffect(() => {
        DelarActor({"type": DelarAction.GET_NOTIFICATION_FEED},{})
    }, [])

    return (
        
            <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                    <Typography variant="h6" className={classes.title}>
                        {t("notifications")}
                    </Typography>
                </Toolbar>
            </AppBar>
            { notificationList && notificationList.length>0 && (
            <React.Fragment>
            <List dense style={{ width: '100%', backgroundColor: 'background.paper' }}>
            {notificationList.map((notification,index) => {
                    const labelId = '' + index;
                    return (
                        <>
                        { notification?.mainActionLink && notification?.mainActionLink.length>0 && (
                        <ListItem key={index} button onClick={()=>{navigate(notification.mainActionLink)}} style = {{position: 'relative', paddingLeft: 16,}}>
                        { notification?.primaryImage && notification?.primaryImageLink && notification?.primaryImageLink.length>0 && (
                        <React.Fragment>    
                        <ListItemAvatar style = {{ top: 0, width: 52, height: 119  }}>
                            <img style = {{width: 40, height: 40,}}
                                alt={`Avatar ${index}`}
                                src={notification.primaryImageLink}
                            />
                        </ListItemAvatar>
                        </React.Fragment>
                        )}
                        <ListItemText disableTypography id={labelId} primary={<div className = {classes.primaryContainer}>
                            { notification?.primaryText && notification?.primaryText.length>0 && (
                            <React.Fragment>
                            <Typography variant = "body2" className = {classes.heading}>
                                {notification.primaryText}
                            </Typography>
                            </React.Fragment>
                            )}
                            {notification?.seenAt && (
                            <React.Fragment>
                            <Typography variant = "body2" className = {classes.time}>{notification.seenAt.toString().substring(11,16)}</Typography>
                            </React.Fragment>
                            )}
                            </div>} 
                            secondary={<div className = {classes.secondaryHolder}>

                                    <div className = {classes.secondaryContainer}>
                                    { notification?.secondaryText && notification?.secondaryText.length>0 && (
                                    <React.Fragment>
                                    <Typography variant = "subtitle1" className = {classes.description}>
                                        {notification.secondaryText}
                                    </Typography>
                                    </React.Fragment>
                                    )}
                                    { notification?.secondaryImage && notification?.secondaryImageLink && notification?.secondaryImageLink.length>0 && (
                                    <React.Fragment>
                                    <img style = {{width: 27, height: 27, marginLeft: 8,}} src = {notification.secondaryImageLink} />
                                    </React.Fragment>
                                    )}
                                </div>
                                <div className={classes.bigImageHolder}>
                                { notification?.bigImage && notification?.bigImage.length>0 && (
                                    <React.Fragment>
                                        <img className={classes.bigImage} src={notification.bigImage} alt={`media_${index}`} />
                                    </React.Fragment>
                                )}
                                </div>
                                <div className = {classes.buttonHolder}>
                                { notification?.actionTwo && notification?.actionTwoLink && notification?.actionTwo.length>0 && notification?.actionTwoLink.length>0 && (
                                <React.Fragment>
                                <Button variant="outlined" component={Link} to={notification.actionTwoLink} color="primary" className={classes.button} style = {{color: '#0e4bb4', fontWeight: 700, borderWidth: 1,}}>
                                        {notification.actionTwo}
                                    </Button>
                                    </React.Fragment>
                                )}
                                    { notification?.actionOne && notification?.actionOneLink && notification?.actionOne.length>0 && notification?.actionOneLink.length>0 && (
                                    <React.Fragment>
                                    <Button variant="contained" component={Link} to={notification.actionOneLink} color="primary" className={classes.button} style = {{marginRight: 0, backgroundColor: '#0e4bb4',}}>
                                        {notification.actionOne}
                                    </Button>
                                    </React.Fragment>
                                    )}
                                </div>
                                </div>
                            }/>
                            {/*<div style = {{background: '#ccc',}}>
                                <Typography variant = "body1" className = {classes.time}>{step.time}</Typography>
                                
                    </div>*/}
                        
                        </ListItem>
                    )}
                        <Divider />
                        </>
                    );
                })}
            </List>
            </React.Fragment>
            )}
            </React.Fragment>
        
    )
}