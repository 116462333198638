import { AppBar, Avatar, Divider, Modal, Toolbar, Typography } from "@material-ui/core"
import CallIcon from '@material-ui/icons/Call'
import React, { useEffect, useState } from "react"
import { useStyles } from "./profile.style"
import { SecurityManager } from "../../../security/security.manager"
import { useTranslation } from "react-i18next"
import { DelarActor } from "../../../actor/delar.actor"
import { DelarAction } from "../../../action/delar.action"
import { useObservable } from "../../../useSubscriber"
import { DelarStore } from "../../../store/delar.store"
import { Media } from "../../../model/media.model"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useRouteManager } from "../../../router/route.manager"
import EditIcon from '@material-ui/icons/Edit';
import { AddressStore } from "../../../store/address.store"
import LoadingComponent from "../../common/loading/loading.component"

export const ProfileComponent = () => {
    const { t } = useTranslation();
    const navigation = useNavigate()

    const routeManager = useRouteManager()

    const classes = useStyles();
    const [delarInfo, _] = useObservable(DelarStore.getDelarInfo(), DelarStore.getDelarInfoObservable())
    const [loading, setLoading] = useState(false)
    const [addressInfo, __] = useObservable(AddressStore.getAddedAddressList() as any, AddressStore.getAddedAddressListObservable())

    useEffect(() => {
        setLoading(true)
        DelarActor({ "type": DelarAction.GET_DELAR_INFO }, { "delarID": SecurityManager.getUid(), "action": () => { setLoading(false); } })
    }, [])
    
    return (

        <div className={classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <ArrowBackIcon onClick={() => navigation(-1)} className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("profile")}
                    </Typography>
                </Toolbar>
            </AppBar>
            {!loading && <>
                {delarInfo?.shopPhotoList && delarInfo?.shopPhotoList.length > 0 && (
                    <React.Fragment>
                        <div style={{ flex: 1, alignItems: 'stretch', position: 'relative', }}>
                            <img src={new Map(Object.entries((delarInfo.shopPhotoList[0].urls))).get('main')} alt={'profileImage'} className={classes.img} />
                            <Typography variant="h6" className={classes.title1}>
                                {delarInfo.shopName.newName}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}

                {delarInfo?.shopPhotoList && delarInfo?.shopPhotoList.length == 0 && (
                    <React.Fragment>
                        <div onClick={() => routeManager.openEditPersonalInfo()} className={classes.zeroImageCard} style={{ flex: 1, alignItems: 'stretch', position: 'relative' }}>
                            <img src='../asset/home_thumb.svg' alt={'shopImage'} className={classes.imgCard} />
                            <Typography variant="body1" className={classes.textThumb}>
                                {t("add_image_hint")}
                            </Typography>
                            <Typography variant="h6" className={classes.title1}>
                                {delarInfo.shopName.newName}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}


                <div style={{ display: "flex", }}>
                    <Typography variant="h6" className={classes.title2} noWrap>
                        <b>{t("personal_details")}</b>
                    </Typography>
                    <EditIcon onClick={() => routeManager.openEditPersonalInfo()} className={classes.editIcon} />
                </div>

                {delarInfo?.name && delarInfo?.name.length > 0 && (
                    <React.Fragment>
                        <div style={{ display: "flex", }}>
                            <Avatar alt="editIcon" src={'../asset/userIcon.svg'} className={classes.icon} />
                            <Typography variant="subtitle1" className={classes.title3} noWrap>
                                {delarInfo.name}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}
                {delarInfo?.phone && delarInfo?.phone.length > 0 && (
                    <React.Fragment>
                        <div style={{ display: "flex" }}>
                            <CallIcon className={classes.icon} style={{ opacity: 0.5 }} />
                            {delarInfo?.alternatePhone && delarInfo?.alternatePhone.length > 0 && (
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.phone}, {delarInfo.alternatePhone}
                                </Typography>
                            )}
                            {delarInfo?.alternatePhone.length <= 0 && (
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.phone}
                                </Typography>
                            )}
                        </div>
                    </React.Fragment>
                )}

                {false && (
                    <Divider style={{ marginTop: 12, }} />
                )}

                {false && (
                    <div style={{ display: "flex", }}>
                        <Typography variant="h6" className={classes.title2} noWrap>
                            <b>{t("delivery_in_villages")}</b>
                        </Typography>
                        <EditIcon onClick={() => routeManager.openEditVillage()} className={classes.editIcon} />
                    </div>
                )}
                {/* {delarInfo?.name && delarInfo?.name.length > 0 && ( */}
                {false && (
                    <React.Fragment>
                        <div style={{ display: "flex", }}>
                            <img src={'/asset/Truck Icon.svg'} alt={'truck icon'} style={{ marginLeft: 16, marginTop: 10, width: 16, height: 16, }} />
                            <Typography variant="subtitle1" className={classes.title3} >
                                {t("delivers_in")} {
                                    addressInfo.map((address: any, index: number) => {
                                        if (index !== addressInfo.length - 1)
                                            return (
                                                `${address.village.name}, `
                                            )
                                        else
                                            return (
                                                `${address.village.name}.`
                                            )
                                    })
                                }
                            </Typography>
                        </div>
                    </React.Fragment>

                )}
                <Divider style={{ marginTop: 12, }} />

                <div style={{ display: "flex" }}>
                    <Typography variant="h6" className={classes.title2} noWrap>
                        <b>{t("profile_business_details")}</b>
                    </Typography>
                    <EditIcon style={{ display: "none" }} onClick={() => routeManager.openEditInfo()} className={classes.editIcon} />
                </div>

                {delarInfo?.shopAddress && (
                    <React.Fragment>
                        <div style={{ display: "flex" }} onClick={() => { routeManager.openEditAddress() }}>
                            <Avatar alt="editIcon" src={'../asset/locationIcon.svg'} className={classes.icon} />
                            <Typography variant="subtitle1" className={classes.title3} noWrap>
                                {delarInfo.shopAddress.landMark}, {delarInfo.shopAddress.streetLine}, {delarInfo.shopAddress.geoAddress.district.name}, {delarInfo.shopAddress.geoAddress.state.name}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}
                {delarInfo && (
                    <React.Fragment>
                        <div style={{ display: "flex" }} onClick={() => { routeManager.openEditPesticideLicense() }}>
                            <img src={'/asset/pest-observation.svg'} alt={'pesticide icon'} style={{ marginLeft: 16, marginTop: 9, width: 17, height: 17, }} />

                            {delarInfo?.pesticideLiscence?.licence?.number.length === 0 && delarInfo?.pesticideLiscence?.newLicence?.number.length === 0 && (
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#1078FF', }} noWrap>
                                    {t("profile_add_pesticide_license")}
                                </Typography>
                            )}

                            {(delarInfo?.pesticideLiscence?.licence?.number.length > 0 && delarInfo?.pesticideLiscence?.newLicence?.number.length === 0) && (
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.pesticideLiscence.licence.number}
                                </Typography>
                            )}

                            {(delarInfo?.pesticideLiscence?.newLicence?.number.length > 0) && (
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#FFCC00', }} noWrap>
                                    {delarInfo.pesticideLiscence.newLicence.number}
                                </Typography>
                            )}
                        </div>
                    </React.Fragment>
                )}
                {delarInfo && (
                    <React.Fragment>
                        <div style={{ display: "flex" }} onClick={() => { routeManager.openEditFertilizerLicense() }}>
                            <img src={'/asset/fertilizer icon.svg'} alt={'fertilizer icon'} style={{ marginLeft: 16, marginTop: 9, width: 17, height: 17, }} />
                            {delarInfo?.fertilizerLiscence?.licence?.number.length === 0 && delarInfo?.fertilizerLiscence?.newLicence?.number.length === 0 && (
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#1078FF', }} noWrap>
                                    {t("profile_add_fertilizer_license")}
                                </Typography>
                            )}

                            {(delarInfo?.fertilizerLiscence?.licence?.number.length > 0 && delarInfo?.fertilizerLiscence?.newLicence?.number.length === 0) && (
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.fertilizerLiscence.licence.number}
                                </Typography>
                            )}

                            {(delarInfo?.fertilizerLiscence?.newLicence?.number.length > 0) && (
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#FFCC00', }} noWrap>
                                    {delarInfo.fertilizerLiscence.newLicence.number}
                                </Typography>
                            )}
                        </div>
                    </React.Fragment>
                )}
                {delarInfo && (
                    <React.Fragment>
                        <div style={{ display: "flex" }} onClick={() => { routeManager.openEditSeedLicense() }}>
                            <img src={'/asset/seed icon.svg'} alt={'seed icon'} style={{ marginLeft: 16, marginTop: 9, width: 17, height: 17, }} />
                            {delarInfo?.seedLiscence?.licence?.number.length === 0 && delarInfo?.seedLiscence?.newLicence?.number.length === 0 && (
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#1078FF', }} noWrap>
                                    {t("profile_add_seed_license")}
                                </Typography>
                            )}

                            {(delarInfo?.seedLiscence?.licence?.number.length > 0 && delarInfo?.seedLiscence?.newLicence?.number.length === 0) && (
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.seedLiscence.licence.number}
                                </Typography>
                            )}

                            {(delarInfo?.seedLiscence?.newLicence?.number.length > 0) && (
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#FFCC00', }} noWrap>
                                    {delarInfo.seedLiscence.newLicence.number}
                                </Typography>
                            )}
                        </div>
                    </React.Fragment>
                )}

                {/*delarInfo?.intro && delarInfo?.intro.length > 0 && (
                    <React.Fragment>
                        <div style={{ display: "flex" }} >
                            <MessageIcon className={classes.icon} style={{ opacity: 0.5 }} />
                            <Typography paragraph variant="subtitle1" className={classes.title3} >
                                {delarInfo.intro}
                            </Typography>
                        </div>
                    </React.Fragment>
                )*/}
            </>
            }
            <Modal open={loading} className={classes.modal}><> <LoadingComponent /></></Modal>
        </div>
    )
}