import { useState, useEffect } from "react"
import { Observable } from "rxjs";
import { ApiError } from "./model/error.model";

export function useObservable<T>(
    initialValue: T, observable: Observable<T>
): [T|null, ApiError] {
    const [value, setValue] = useState<T|null>(initialValue)

    const _apiError: ApiError = {
        msg: "",
        error: false
    }

    const [error, setError] = useState<ApiError>(_apiError)

    useEffect(() => {
        const sub = observable.subscribe((s: any) => {
            if (s && s.error && s.error == true) {
                setError(s)
                setValue(null)
            } else {
                setError({
                    msg: "",
                    error: false
                })
                setValue(s)
            }
        })
        return () => sub.unsubscribe()
    }, []);

    return [value, error]
}