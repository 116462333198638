import { Check, ErrorOutlineOutlined } from "@material-ui/icons";
import { RegistrationAction } from "../action/registration.action";
import { ApiError } from "../model/error.model";
import { Session } from "../model/session.model";
import { SecurityManager } from "../security/security.manager";
import { DelarService } from "../service/delar.service";
import { GenerateOTPDto, ValidateOTPDto } from "../service/dto/registration.dto";
import { RegistrationService } from "../service/registration.service";
import { RegistrationStore } from "../store/registration.store";

const setLanguage = (data: any) => {
    SecurityManager.setLanguage(data.langCode)
    RegistrationStore.setSelectedLang(data.langCode)
}

const sendOtp = (data: any) => {
    var generateOTPDto = {
        phone: data.phone,
        appSignature: ""
    } as GenerateOTPDto
    RegistrationService.generateOtp(generateOTPDto).then(res => {
        RegistrationStore.setGenearteOtpRes(res.data)
        data.action()
    }).catch(err => {
        RegistrationStore.setGenearteOtpRes({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}

const validateOtp = (data: any) => {
    var validateOTPDto = {
        otp: data.otp,
        name: "",
        phone: data.phone,
        language: data.langCode
    } as ValidateOTPDto
    RegistrationService.validateOtp(validateOTPDto).then(res => {
        const session = {
            id: res.data.delar.id,
            name: res.data.delar.name,
            isLoggedIn: true,
            token: res.data.logInRes.token,
            phone: res.data.delar.phone,
            verified: res.data.delar.status.value
        } as Session
        SecurityManager.setSession(session)
        RegistrationStore.setValidateOtpRes(res.data)
        data.action()
    }).catch(err => {
        RegistrationStore.setValidateOtpRes({
            "msg": "Invalid Otp",
            "error": true
        } as ApiError)
        data.action()
    })
}

const checkStatus = (data: any) => {
    DelarService.checkDelarStatus(SecurityManager.getUid()).then(res => {
        const session = {
            id: res.data.delar.id,
            name: res.data.delar.name,
            isLoggedIn: true,
            token: res.data.logInRes.token,
            phone: res.data.delar.phone,
            verified: res.data.delar.status.value
        } as Session
        SecurityManager.setSession(session)
        RegistrationStore.setCheckStatusRes(res.data)
        data.action()
    }).catch(err => {
        RegistrationStore.setCheckStatusRes({
            "msg": "Error in api",
            "error": true
        } as ApiError)
        data.action()
    })
}

export const RegistrationActor = (action: any, data: any) => {
    switch (action.type) {
        case RegistrationAction.SET_LANGUAGE:
            setLanguage(data)
            break;
        case RegistrationAction.SEND_OTP:
            sendOtp(data)
            break;
        case RegistrationAction.VALIDATE_OTP:
            validateOtp(data)
            break;
        case RegistrationAction.CHECK_STATUS:
            checkStatus(data)
            break;
        default:
            break;
    }
}