import { AppBar, BottomNavigation, Button, Modal, TextField, Toolbar, Typography,} from "@material-ui/core"
import React, {useState} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './edit-address.style'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useObservable } from "../../../../../useSubscriber"
import { EditProfileStore } from "../../../../../store/edit-profile.store"
import { EditProfileActor } from "../../../../../actor/edit-profile.actor"
import { EditProfileAction } from "../../../../../action/edit-profile.action"
import { StatePickerComponent } from "../../../../common/address-picker/state-picker.component"
import { State } from "../../../../../service/response/state.response"
import { DistrictPickerComponent } from "../../../../common/address-picker/district-picker.component"
import { SubDistrictPickerComponent } from "../../../../common/address-picker/sub-district-picker.component"
import { VillagePickerComponent } from "../../../../common/address-picker/village-picker.component"
import { District } from "../../../../../service/response/district.response"
import { SubDistrict } from "../../../../../service/response/sub-district.response"
import { Village } from "../../../../../service/response/village.response"
import { SecurityManager } from "../../../../../security/security.manager"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../../../router/route.manager"
import LoadingComponent from "../../../../common/loading/loading.component"
import { useEffect } from "react"

export const EditAddressComponent = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(true);
    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(EditProfileStore.getDelarDtoError(), EditProfileStore.getDelarDtoErrorObservable())
    
    const [pinCodeString, setPinCodeString] = useState("")

    const handleChangeStreetAddress = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_STREET_ADDRESS }, { "streetLine": e.target.value })
    };

    const handleChangeLandMark = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_LANDMARK }, { "landMark": e.target.value })
    };
    
    const setState = (state: State) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_STATE }, { "state": state })
    };

    const setDistrict = (district: District) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_DISTRICT }, { "district": district })
    };

    const setSubDistrict = (subDistrict: SubDistrict) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_SUB_DISTRICT }, { "subDistrict": subDistrict })
    };

    const setVillage = (village: Village) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_VILLAGE }, { "village": village })
    };

    const handleChangePinCode = (e: any) => {
        
        if (e.target.value == "") {
            EditProfileActor({ "type": EditProfileAction.UPDATE_PINCODE }, { "pincode": 0 })
            setPinCodeString("")
            return
        }
        try {
            const pinCode = Number(e.target.value)
            if (pinCode) {
                EditProfileActor({ "type": EditProfileAction.UPDATE_PINCODE }, { "pincode": pinCode })
            }
        } catch {
            
        }
    };

    const handleUpdate = () => {
        setLoading(true)
        EditProfileActor({ "type": EditProfileAction.UPDATE_DATA }, { "delarId": SecurityManager.getUid(), "action": ()=>{setLoading(false); routeManager.goBack()} })
    };

    useEffect(() => {
        setLoading(true)
        EditProfileActor({ "type": EditProfileAction.RE_INIT_DELAR }, {
            "delarId": SecurityManager.getUid(),
            "action": () => {
                setLoading(false)
            },
            "errorAction": () => { }
        })
    }, [])

    useEffect(() => {
        if (delarDto && delarDto.shopAddress && delarDto.shopAddress.pinCode) {
            setPinCodeString(delarDto.shopAddress.pinCode.toString())
        }

    }, [delarDto])

    return (
        <div className={classes.root}>
            {!loading &&<> <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon onClick={() => routeManager.goBack()} className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("change_address")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Typography variant="body1" className={ classes.title4 } style = {{marginTop: 16,}}>
                {t("street_address")}
            </Typography>
            <form className={classes.formField}  noValidate autoComplete="off">
                <TextField 
                    id="outlined-basic" 
                    value={delarDto?.shopAddress?.streetLine} className={classes.textField1} 
                    variant="outlined" 
                    InputProps={{
                        classes: {
                          input: classes.font12,
                        },
                      }} 
                    onChange={handleChangeStreetAddress} 
                />
                {delarDtoError?.shopAddress.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("common_error_text")}
                    </Typography>}
            </form>
            <Typography variant="body1" className={ classes.title4 } >
                {t("landmark")}
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField 
                    id="outlined-basic" 
                    value={delarDto?.shopAddress?.landMark} 
                    className={classes.textField1} 
                    variant="outlined" 
                    InputProps={{
                        classes: {
                          input: classes.font12,
                        },
                      }} 
                    onChange={handleChangeLandMark} 
                />
            </form>
            <Typography variant="body1" className={ classes.title4 } >
                {t("state")}
            </Typography>
            <div className = {classes.pickerDiv}>
            <StatePickerComponent 
            setState={setState} Store = {EditProfileStore}/>
            {delarDtoError?.state.error && <Typography variant="body1" className={classes.errorText}>
                <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
            </Typography>}
            </div>
           {delarDto?.shopAddress?.geoAddress?.state?.name && delarDto?.shopAddress?.geoAddress?.state?.name.length > 0 && (
                <div>
                    <Typography variant="body1" className={classes.title4} >
                        {t("district")}
                    </Typography>
                    <div className = {classes.pickerDiv}>
                    <DistrictPickerComponent
                        setDistrict={setDistrict}
                        Store = {EditProfileStore}
                        stateCode={delarDto.shopAddress.geoAddress.state.code} />
                        {delarDtoError?.district.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
                    </Typography>}
                    </div>
                </div>
            )}

            {delarDto?.shopAddress?.geoAddress?.district?.name && delarDto?.shopAddress?.geoAddress?.district?.name.length > 0 && (
                <div>
                    <Typography variant="body1" className={classes.title4} >
                        {t("city")}
                    </Typography>
                    <div className = {classes.pickerDiv}>
                    < SubDistrictPickerComponent
                        setSubDistrict={setSubDistrict}
                        Store = {EditProfileStore}
                        districtCode={delarDto?.shopAddress?.geoAddress.district.code} />
                        {delarDtoError?.city.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
                    </Typography>}
                    </div>
                </div>
            )}

            {delarDto?.shopAddress?.geoAddress?.subDistrict?.name && delarDto?.shopAddress?.geoAddress?.subDistrict?.name.length > 0 && (
                <div>
                    <Typography variant="body1" className={classes.title4} >
                        {t("village")}
                    </Typography>
                    <div className = {classes.pickerDiv}>
                    <VillagePickerComponent
                        setVillage={setVillage}
                        Store = {EditProfileStore}
                        subDistrictCode={delarDto?.shopAddress?.geoAddress?.subDistrict?.code} />
                        {delarDtoError?.village.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{t("required_field")}
                    </Typography>}
                    </div>
                </div>
            )}
            <Typography variant="body1" className={ classes.title4 } >
                {t("pincode")}
            </Typography>
            <form className={classes.formField} style = {{marginBottom: 100,}} noValidate autoComplete="off">
                <TextField id="outlined-basic" value={pinCodeString} className={classes.textField1}  variant="outlined" onChange={handleChangePinCode} />
            </form>
            {/*<Divider style={{marginTop:50,}}/>*/}
            <BottomNavigation className = {classes.bottomNavigation}>
                <Button variant="contained" color="primary" className={classes.requestButton} onClick={handleUpdate}>
                        {t("send_request")}
                    </Button>
            </BottomNavigation>
              </>  
            }
            <Modal open = {loading} className = {classes.modal}><LoadingComponent /></Modal>
        </div>
    )
}