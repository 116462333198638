import { Button, Typography } from "@material-ui/core"
import { useStyles } from './not-deliverable.style'

export const NotDeliverable = () => {
    const classes = useStyles();
    return(
        
            <div className = {classes.root}>
                <div>
                <div className = {classes.imageHolder}>
                    <img className = {classes.image} src = "../asset/not deliverable icon.svg" />
                </div>
                <Typography variant='body1' className = {classes.description}>
                    We do not serve at your location at this moment. We will soon serve at your location.
                </Typography>
                <div className = {classes.buttonContainer}>
                    <Button variant = "outlined" className = {classes.button} style={{marginLeft: 12,border:'1px solid #0e4bb4',color:'#0e4bb4'}}>
                        Request Center
                    </Button>
                    <Button variant = "contained" color = "primary" className = {classes.button} style={{marginRight: 12,boxShadow:'none',backgroundColor:'#0e4bb4'}}>
                        Update Location
                    </Button>
                </div>
                </div>
            </div>
        
    )
}