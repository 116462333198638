import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        kpLogo: {
            width: 75,
            height: 75,
            marginTop: 157,
        },
        langTitle1: {
            flexGrow: 1,
            align: "center",
            marginTop: 54,
            fontSize: '1.1428571428571428em',
            fontWeight: 500,
        },
        langTitle2: {
            flexGrow: 1,
            align: "center",
            marginTop: 8,
            fontSize: '1.1428571428571428em',
        },
        radioRoot: {
            color: '#0E4BB4'
        },
        engButton: {
            marginTop: 24,
            textAlign: "center",
            width: 240,
            height: 48,
            fontSize: '1em',
            fontWeight: 500,
            textTransform: 'capitalize',
        },
        hinButton: {
            marginTop: 20,
            textAlign: "center",
            width: 240,
            height: 48,
            fontSize: '1em',
            fontWeight: 400,
        },
        gujButton: {
            marginTop: 20,
            textAlign: "center",
            width: 240,
            height: 48,
            fontSize: '1em',
            fontWeight: 400,
        },
        radioButton: {
            "& svg": {
                width: "0.7142857142857143em",
                height: "0.7142857142857143em"
              },
              
        }
    }),
);