import { List, ListItem, ListItemText, Typography, ListItemAvatar, Divider } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT } from "../../../../../constant/base.constant";
import { Media } from "../../../../../model/media.model";
import { SecurityManager } from "../../../../../security/security.manager";
import { Order } from "../../../../../service/response/order.response";
import { useStyles } from "./completed-order.style";

export const CompletedOrderComponent: React.FC<{ order: Order, handleItemClick: any }> = ({ order, handleItemClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let photo = new Map(Object.entries(order.photos)).values().next().value as Media

    return (
        <React.Fragment>
            <List onClick={() => { handleItemClick(order.id, order.status.value) }} dense style={{ width: '100%', backgroundColor: 'background.paper', marginTop: -10, }}>
                <ListItem style={{ position: 'relative', paddingLeft: 16, marginBottom: 0 }}>
                    <ListItemText disableTypography id={order.id} primary={<div className={classes.primaryContainer}>
                        <div className={classes.statusNumberContainer}>
                            <Typography variant="body2" className={classes.statusHeadingDelivered}>
                                <b>{t("common_delivered")}</b>
                            </Typography>
                            <Typography variant="body2" className={classes.numberHeading}>
                                {order.id}
                            </Typography>
                        </div>
                        <Typography variant="body2" className={classes.time}>{new Date(order.createdOn).toLocaleDateString(SecurityManager.getLanguage(), DATE_FORMAT)}</Typography>
                    </div>} />
                </ListItem>
                <ListItem style={{ position: 'relative', marginBottom: 8, paddingLeft: 16, marginTop: -5 }}>
                    <ListItemAvatar>
                        <img style={{ width: 53, height: 53, }}
                            alt={`Avatar ${order.id}`}
                            src={new Map(Object.entries(photo.urls)).get('main') as string}
                        />
                    </ListItemAvatar>
                    <ListItemText disableTypography id={order.address.geoAddress.village.id} primary={
                        <Typography variant="body2" className={classes.heading}>
                            {/* <b>{`${order.address.geoAddress.village.name}, ${order.address.geoAddress.district.name}`}</b> */}
                            <b>{order.products.map(product => {
                                return product.name
                            }).toString()}</b>
                        </Typography>
                    }
                        secondary={
                            <Typography variant="subtitle1" className={classes.description}>
                                {order.products.length} {t("home_item")}
                            </Typography>
                        } />
                </ListItem>
                <Divider style={{ marginTop: -4, }} />
            </List>
        </React.Fragment>
    )
}