import { FilterAction } from "../action/filter.action";
import { FilterService } from "../service/filter.service";
import { FilterStore } from "../store/filter.store";

const getDelarProduct = (afterDataFetch: any, setHasMore?: any) => {
    FilterService.getShopCompany(FilterStore.getShopCompanyDto()).then((res: any) => {
        FilterStore.upadteShopCompanyList(res.data.data)
        FilterStore.updatePageNumber()
        afterDataFetch()
        console.log(res.data.data.length)
        console.log(FilterStore.getShopCompanyDto().limit)
        if(setHasMore) {
            if(res.data.data.length < FilterStore.getShopCompanyDto().limit) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

const updateSearchText = (searchText: string, afterDataFetch: any) => {
    initSearchDtoAndShopCompanyList()
    FilterStore.updateSearchText(searchText)
    getDelarProduct(afterDataFetch)
}

const initSearchDtoAndShopCompanyList = () => {
    FilterStore.reSetSearchDtoAndShopCompanyList()
}

export const FilterActor = (action: any, data: any) => {
    switch (action.type) {
        case FilterAction.INIT_SEARCH_DTO_AND_SHOP_COMPANY_LIST:
            initSearchDtoAndShopCompanyList()
            break;
        case FilterAction.GET_LIST:
            getDelarProduct(data.afterDataFetch, data.setHasMore)
            break;
        case FilterAction.UPDATE_SEARCH_TEXT:
            updateSearchText(data.searchText, data.afterDataFetch)
            break;
        default:
            break;
    }
}