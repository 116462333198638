import { createStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { useStyles } from "./credit-note-B.style";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#DBDBDB',
      color: theme.palette.common.black,
    },
    body: {
        fontSize: '0.75rem',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
        flex: 1,
    },
  }),
)(TableRow);

function createData(sl_no: number,description: string, companyName: string, batchNumber: string, qty: string, unitPrice: string, taxableAmount: string,cgst: string, sgst: string,total: string) {
  return { sl_no,description,companyName,batchNumber,qty,unitPrice,taxableAmount,cgst,sgst,total };
}

const rows = [
  createData(1,'Product Name\nProduct Weight\n\nHSN: HSN Number', 'Company Name', 'XXXX', 'X', 'Rs XX.XX', 'Rs XX.XX','Rs XX.XX','Rs XX.XX','Rs XX.XX'),
  createData(2,'Product Name\nProduct Weight\n\nHSN: HSN Number', 'Company Name', 'XXXX', 'X', 'Rs XX.XX', 'Rs XX.XX','Rs XX.XX','Rs XX.XX','Rs XX.XX'),
];
export const CreditNoteBComponent = () => {
    const classes = useStyles();
    return (
        <div className ={classes.root}>
            <div className={classes.rowContainer}>
                <img src={'../asset/Layer 6.png'} alt={'Icon'} style={{width:100, height:50,marginLeft:16,}}/>
                <div className={classes.columnContainer}>
                    <Typography variant='h6' className={classes.topText1}>
                        Credit Note
                    </Typography>
                    <Typography variant='h6' className={classes.topText2}>
                        (Original for Recipient)
                    </Typography>
                </div>
            </div>
            <div className={classes.rowContainer}>
                <div className={classes.columnContainer}>
                    <Typography variant='h6' className={classes.heading1}>
                        Sold By:
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        Dealer’s Shop name
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        Dealer’s Shop address ”Street address”,
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        “Landmark” 
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        “Village”,“Taluka”, “District”, ”State”,
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        “Pincode” 
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        IN 
                    </Typography>
                </div>
                <div className={classes.columnContainer}>
                    <Typography variant='h6' className={classes.heading2}>
                        Billing address
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine1}>
                        Name of Farmer
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        Delivery Address Street address
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine2}>
                        Landmark 
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine3}>
                        “Village”,“Taluka”, “District”, ”State”,
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine4}>
                        Pincode 
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine5}>
                        IN 
                    </Typography>
                </div>
            </div>
            <div className={classes.rowContainer}>
                <div className={classes.columnContainer}>
                    <Typography variant='subtitle1' className={classes.PanNumberHeading}>
                        <b>Pan No:</b> Pan No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        <b>GST Registration No:</b> GST No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        <b>Pesticide License No:</b> No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        <b>Fertilizer License No:</b> No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        <b>Seed License No:</b> No here
                    </Typography>
                </div>
                <div className={classes.columnContainer}>
                    <Typography variant='subtitle1' className={classes.RightInfoLine8}>
                        <b>CIN No:</b> No here 
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine9}>
                        <b>Pan No:</b> Pan No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine10}>
                        <b>GST Registration No:</b> GST No here
                    </Typography>
                </div>
            </div>
            <div className={classes.rowContainer}>
                <div className={classes.columnContainer}>
                    <Typography variant='subtitle1' className={classes.PanNumberHeading}>
                        <b>Order No:</b> No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        <b>Original Invoice Number :</b> No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.addressLines}>
                        <b>Order Date:</b> “DD.MM.YYY”
                    </Typography>
                </div>
                <div className={classes.columnContainer}>
                    <Typography variant='subtitle1' className={classes.RightInfoLine6}>
                        <b>Credit note No: :</b> No here
                    </Typography>
                    <Typography variant='subtitle1' className={classes.RightInfoLine7}>
                        <b>Credit note Date :</b> “DD.MM.YYY”
                    </Typography>
                </div>
            </div>
            <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className ={classes.tableCell} style={{width: 20}}>SL<br/>No</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>Description</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>Company</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell} style={{width: 60}}>Batch No</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell} style={{width: 30}}>Qty</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell} style={{width: 60}}>Unit<br/>Price</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell} style={{width: 60}}>Taxable<br/>Amount</StyledTableCell>
                            <StyledTableCell align="left" style={{border: '1px solid rgba(0, 0, 0, 1)',}}>
                                <Typography variant='subtitle1' align='center' style={{fontSize:14,}}>CGST</Typography>
                                    <div style={{display:'flex',}}>
                                        <StyledTableCell style={{padding: 0, width: '50%', fontSize: '0.75rem', wordBreak: 'keep-all'}}>Rate</StyledTableCell>
                                        <StyledTableCell style={{padding: 0, width: '50%', fontSize: '0.75rem', wordBreak: 'keep-all'}}>Amount</StyledTableCell>
                                    </div>
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{border: '1px solid rgba(0, 0, 0, 1)',}}>
                                <Typography variant='subtitle1' align='center' style={{fontSize:14,}}>SGST</Typography>
                                    <div style={{display:'flex',}}>
                                        <StyledTableCell style={{padding: 0, width: '50%', fontSize: '0.75rem', wordBreak: 'keep-all'}}>Rate</StyledTableCell>
                                        <StyledTableCell style={{padding: 0, width: '50%', fontSize: '0.75rem', wordBreak: 'keep-all'}}>Amount</StyledTableCell>
                                    </div>
                            </StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>Total<br/>Amount</StyledTableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.sl_no}>
                            <StyledTableCell component="th" scope="row" className ={classes.tableCell}>
                                {row.sl_no}
                            </StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.description}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.companyName}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.batchNumber}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.qty}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.unitPrice}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.taxableAmount}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.cgst} {row.cgst}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.sgst} {row.sgst}</StyledTableCell>
                            <StyledTableCell align="left" className ={classes.tableCell}>{row.total}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableRow >
                <StyledTableCell colSpan ={7} align="left"><b>Total</b></StyledTableCell>
                    <StyledTableCell align="left" style={{backgroundColor:'#DBDBDB'}} className ={classes.tableCell}>Rs XX.XX</StyledTableCell>
                    <StyledTableCell align="left" style={{backgroundColor:'#DBDBDB'}} className ={classes.tableCell}>Rs XX.XX</StyledTableCell>
                    <StyledTableCell align="left" style={{backgroundColor:'#DBDBDB'}} className ={classes.tableCell}>Rs XX.XX</StyledTableCell>
                </TableRow>
                <TableRow >
                    <StyledTableCell colSpan ={10} align="left"><b>Amount in words</b><br/><b>“One thousand only”</b></StyledTableCell>
                </TableRow>
                <TableRow>
                    <StyledTableCell colSpan ={10} align='right'><b>For “Dealer’s Shop name”:</b><br/><br/><br/><br/><b>Authorized Signature</b></StyledTableCell>
                </TableRow>
                </Table>
            </TableContainer>
        </div>
    )
}