import { AppBar, Avatar, BottomNavigation, Button, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './multpile-product-selected.style'
import { useObservable } from "../../../../../useSubscriber"
import { AddProductStore } from "../../../../../store/product/add-product.store"
import { DelarProductSearchResult } from "../../../../../service/response/product.response"
import { useRouteManager } from "../../../../../router/route.manager"
import { useNavigate } from "react-router-dom"
import { Media } from "../../../../../model/media.model"
import { useTranslation } from "react-i18next"

export const MultiProductPackSizeComponent = () => {

    const classes = useStyles();

    const navigation = useNavigate();
    const { t } = useTranslation()
    const routeManager = useRouteManager();

    const [addedProductList] = useObservable(AddProductStore.getAddedProductList(), AddProductStore.getAddedProductListObservable())

    const handlePackUpdate = (product: DelarProductSearchResult) => {
        routeManager.openProductPackSizePage(product.id)
    };

    const handleAddLater = () => {
        navigation(-2)
    };

    useEffect(() => {
        console.log(addedProductList)
    }, [])

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick={() => { routeManager.goBack() }} />
                    <Typography variant="h6" className={classes.title}>
                        {t("product_pack_size")}
                    </Typography>
                </Toolbar>
            </AppBar>
            {addedProductList && (
                <>
                    <Typography variant="body2" className={classes.infoText1}>
                        {t("add_product_add_description_before")}<b>{addedProductList?.length} {t("add_product_add_description_middle")}</b> {t("add_product_add_description_after")}
                    </Typography>
                    <Divider />
                    <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 50 }}>

                        {addedProductList.map((product, index) => {
                            const labelId = product.id;
                            const photo = new Map(Object.entries(product.photos)).values().next().value as Media
                            return (
                                <>
                                    <ListItem key={index} >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`Avatar ${product.id}`}
                                                src={new Map(Object.entries(photo.urls)).get('main') as string}
                                                className={classes.productImage}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.productName}>
                                            {product.name}
                                        </Typography>}
                                            secondary={<Typography variant="subtitle1" className={classes.productDescription}>
                                                {product.company.name}
                                            </Typography>} />
                                        <ListItemSecondaryAction style={{ padding: 2 }}>
                                            <Button color="primary" onClick={() => { handlePackUpdate(product) }}><b>{t("add_product_add_pack_size")}</b></Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                    <BottomNavigation showLabels className={classes.bottomNavigation}>
                        <Button className={classes.nextButton} variant="contained" color="primary" onClick={handleAddLater} >
                            {t("add_product_save_and_do_later")}
                        </Button>
                    </BottomNavigation>
                </>
            )}
        </React.Fragment>
    )
}