import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '99%',
            fontSize: 14,
        },
        rowContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
        },
        columnContainer: {
            display:"flex", 
            flexDirection: 'column',
            alignItems: 'right', 
        },
        topText1: {
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 185,
            marginTop: 8,
        },
        topText2: {
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 110,
            marginTop: 4,
            marginRight:16,
        },
        heading1: {
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 16,
            marginTop: 16,
        },
        heading2: {
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 120,
            marginTop: 16,
        },
        addressLines: {
            fontSize: 14,
            marginLeft: 16,
            marginTop: -2,
        },
        RightInfoLine1: {
            fontSize: 14,
            marginLeft: 120,
            marginTop: -2,
        },
        RightInfoLine2: {
            fontSize: 14,
            marginLeft: 155,
            marginTop: -2,
        },
        RightInfoLine3: {
            fontSize: 14,
            marginRight: 16,
            marginTop: -2,
        },
        RightInfoLine4: {
            fontSize: 14,
            marginLeft: 165,
            marginRight: 16,
            marginTop: -2,
        },
        RightInfoLine5: {
            fontSize: 14,
            marginLeft: 200,
            marginTop: -2,
        },
        PanNumberHeading: {
            fontSize: 14,
            marginLeft: 16,
            marginTop: 16,
        },
        heading3: {
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 100,
            marginTop: 16,
        },
        RightInfoLine6: {
            fontSize: 14,
            marginLeft: 95,
            marginRight: 16,
        },
        RightInfoLine7: {
            fontSize: 14,
            marginLeft: 50,
            marginRight:16,
        },
        RightInfoLine8: {
            fontSize: 14,
            marginLeft: 120,
            marginTop: -2,
        },
        RightInfoLine9: {
            fontSize: 14,
            marginLeft: 90,
            marginTop: -2,
        },
        RightInfoLine10: {
            fontSize: 14,
            marginRight: 16,
            marginTop: -2,
        },
        [`@media print`]: {
            table:{
                pageBreakInside: 'avoid',
                pageBreakBefore: 'auto',
                breakInside: 'avoid'
            },
          },
        table: {
            marginLeft:16,
            marginTop:16,
            marginRight: 16,
            maxWidth: '98%',
            fontSize: '0.6rem',
            tableLayout: 'fixed',
            wordBreak: 'break-word',
            border: '1px solid rgba(0, 0, 0, 1)',
          },
          tableCell: {
            border: '1px solid rgba(0, 0, 0, 1)',
            paddingLeft: 2,
            paddingRight: 3,
          },
        verticleLine: {
            height: '100%',
            width: 1,
            backgroundColor: '#909090',
        },
        tableContainer: {
        },
        
    }),
);