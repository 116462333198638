import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { AddressAction } from '../../../action/address.action';
import { AddressActor } from '../../../actor/address.actor';
import { District } from '../../../service/response/district.response';
import { AddressStore } from '../../../store/address.store';
import { useObservable } from '../../../useSubscriber';
import { useStyles } from './ui.style';
import { useTranslation } from "react-i18next"

export const DistrictPickerComponent = (props: any) => {

    const [districtList, districtListError] = useObservable(AddressStore.getDistrictList(), AddressStore.getDistrictListObservable())
    const [delarDto] = useObservable((props.Store).getDelarDto(), (props.Store).getDelarDtoObservable())
    const { t } = useTranslation();
    const [districtData, setDistrictData] = useState<Array<District>>([])
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    useEffect(() => {
        setLoading(true)
        AddressActor({ "type": AddressAction.GET_DISTRICT_LIST }, { "stateCode": props.stateCode })
    }, [])

    useEffect(() => {
        if (!districtListError.error && districtList) {
            var newStates: Array<District> = Object.keys(districtList).map(function (index: any) {
                let district = (districtList as District[])[index]
                return district;
            });
            setDistrictData(newStates)
        }
        setLoading(false)
    }, [districtList])

    const handleDistrictPick = (district: string | District) => {
        props.setDistrict(district)
        if (district) {
            AddressActor({ "type": AddressAction.GET_SUB_DISTRICT_LIST }, { "districtCode": (district as District).code })
        }
    }

    return (
        !loading && <Autocomplete
            id="combo-box-demo"
            options={districtData}
            defaultValue={delarDto?.shopAddress?.geoAddress?.district}
            getOptionLabel={(option: District) => option.name}
            onChange={(event, district) => handleDistrictPick(district)}
            fullWidth={true}
            style={{ marginTop: 8, }}
            loading={districtData.length > 0 ? true : false}
            classes={{ input: classes.input }}
            renderInput={(params: any) => <TextField {...params}
                defaultValue={delarDto?.shopAddress?.geoAddress?.district?.name}
                // classes = {{
                //     root: delarDtoError?.district.error?classes.TextFieldRoot: classes.noStyle,
                // }}
                label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">{t("common_type_district")}</Typography>} variant="outlined" />}
        />
    );
}