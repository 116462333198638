import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        statusHeading: {
            fontSize: 16,
            fontWeight: 600,
            color: '#D6D000',
        },
        numberHeading: {
            fontSize: 10,
            opacity: 0.6,
            marginLeft: 8,
            marginTop: 5,
        },
        time: { 
            fontSize: 10,
            opacity: 0.6,
        },
        heading: {
            marginLeft: 8,
            fontSize: 16,
            fontWeight: 400,
        },
        primaryContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
        },
        statusNumberContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'left',
        },
        description: {
            marginLeft: 8,
            fontSize: 16,
            opacity: 0.7,
        },
        secondaryHolder: {
            display: 'flex',
            flexDirection: 'column',
        },
        secondaryContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        buttonHolder: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 16,
            marginBottom: 8,
        },
        button: {
            textTransform: 'capitalize',
            padding: 10,
            fontSize: 13,
            opacity: 0.8,
            width: 168,
            height: 30,
            marginRight: 8,
            fontWeight: 500,
            borderColor: '#0e4bb4',
        },
    }),
);