import { useTranslation } from "react-i18next"
import { RegistrationAction } from "../../../action/registration.action"
import { RegistrationActor } from "../../../actor/registration.actor"
import { useRouteManager } from "../../../router/route.manager"
import { RegistrationStore } from "../../../store/registration.store"
import { useObservable } from "../../../useSubscriber"
import { useStyles } from "./register.style"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useState, useEffect } from "react"
import { Typography, FormControl, OutlinedInput, InputAdornment, Button, Modal, BottomNavigation } from "@material-ui/core"
import LoadingComponent from "../../common/loading/loading.component"


export const RegisterComponent = () => {
    const { t } = useTranslation();
    const routeManager = useRouteManager();
    const [genearteOtpRes, generateOtpError] = useObservable(RegistrationStore.getGenearteOtpRes(), RegistrationStore.getGenearteOtpResObservable())

    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [optSending, setOptSending] = useState(false);

    const handleChange = (event: any) => {
        let value = event.target.value;
        let formattedValue = "";
        let phoneValue = "";
        let count = 0;
        for (var i = 0; i < value.length; i++) {
            if ((count === 3 && value[i] !== ' ') || (count === 6 && value[i] !== ' ')) {
                formattedValue += " "
            }
            if (value[i] === ' ')
                continue
            formattedValue += value[i];
            phoneValue += value[i];
            count++;

        }
        event.target.value = formattedValue
        if (phoneValue.length !== 10)
            setError(true)
        else
            setError(false)
        setPhoneNumber(phoneValue)
    };

    useEffect(() => {
        if (!generateOtpError.error && genearteOtpRes && !optSending) {
            routeManager.openOTPValidation()
        }
    }, [genearteOtpRes, generateOtpError, optSending])

    const sendOTP = () => {
        setFirstTime(false)
        if (error)
            return
        setOptSending(true)
        RegistrationActor({ "type": RegistrationAction.SEND_OTP }, { "phone": phoneNumber, "action": () => { setOptSending(false) } })
    }
    const [error, setError] = useState(true);
    const [firstTime, setFirstTime] = useState(true)
    return (
        <>
            <div className={classes.root}>
                {!optSending && <><ArrowBackIcon className={classes.backIcon} />

                    <div className={classes.mainContent}>
                        <Typography variant="h4" className={classes.title1} >
                            <b>{t("register_mobile_no")}</b>
                        </Typography>
                        <Typography variant="subtitle2" className={classes.title2} >
                            {t("register_mobile_no_input")}
                        </Typography>
                        <FormControl className={classes.numberMargin} variant="outlined">
                            <OutlinedInput
                                id="input-phone-number"
                                placeholder={"123 456 7890"}
                                onChange={(e: any) => handleChange(e)}
                                classes={{
                                    adornedStart: classes.adornedStart,
                                    input: classes.input,
                                }}
                                startAdornment={<InputAdornment
                                    position="start" style={{ width: '20%!important' }}><Typography variant="body1" style={{ fontSize: 12, paddingLeft: "12px!important" }} >+91</Typography></InputAdornment>}
                            />
                            {error && !firstTime && <Typography variant="body1" className={classes.errorText}>
                                <ErrorOutlineIcon className={classes.errorIcon} />{t("mobile_no_should_have_ten_digits")}
                            </Typography>}
                        </FormControl>

                    </div>

                    <BottomNavigation showLabels className={classes.bottomNavigation}>
                        <Button variant="contained" color="primary" className={classes.nextButton}
                            onClick={() => sendOTP()}>
                            {t("next")}
                        </Button>
                    </BottomNavigation>
                </>}
            </div>
            <Modal open={optSending} className={classes.modal}><LoadingComponent /></Modal>
        </>

    )
}