import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            margin: '12px 8px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',
            marginTop: "40",
        },
        img: {
            width: 174,
            height: 132,
            marginBottom: 8,
        },
        heading: {
            position: 'static',
            fontSize: 14,
            fontWeight: 700,
        },
        description: {
            fontSize: 14,
            marginLeft: 36,
            marginRight: 36,
            marginTop: 8,
            maxLines: 2,
            textAlign: 'center',
        },
        button: {
            textTransform: 'capitalize',
            backgroundColor: '#0E4BB4',
            color: '#0E4BB4',
            padding: 10,
            fontSize: 13,
            opacity: 0.8,
            width: 174,
            height: 48,
            fontWeight: 500,
            marginTop: 16,
            marginBottom: 16,
        },
    }),
);