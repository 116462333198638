import { Avatar, Button, GridList, GridListTile, Typography } from "@material-ui/core";
import { useRef, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useStyles } from './gallery-picker.style'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Media } from "../../model/media.model";
import DialogAlertComponent from "../common/dialog-alert/dialog-alert.component"; 
import { useTranslation } from "react-i18next";

export const GalleryPickerComponent = (props: any) => {
  const _images = useRef<Array<any>>([])
  const { t } = useTranslation();
  const [images, setImages] = useState<Array<any>>(_images.current);
  const maxNumber = 8;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    _images.current = imageList
    setImages(_images.current)
    sendDataBack()
  };

  const classes = useStyles();

  const imageClicked = (input: any) => {
    var x = input.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(x);
    reader.onload = (e) => {

      var img1 = {
        file: x,
        dataURL: e.target.result,
      };
      _images.current = [img1, ...images]

      setImages(_images.current)
      sendDataBack()
    }
  };
 
  const deleteElement = (elementIndex: any) => {
    let imagesCopy = [..._images.current]
    imagesCopy.splice(elementIndex.index, 1);

    _images.current = [...imagesCopy]
    setImages(_images.current);
    sendDataBack()
  }

  const sendDataBack = () => {
    props.handleUpload(_images.current)
  }
  let uploadedPhotos: any[] = []
  if(props.uploaded){
  for(const[key, value] of Object.entries(props.uploaded))
  {
    uploadedPhotos.push([key, value])
  }
}
const [openDialog, setOpenDialog] = useState(false);
const [imageType, setImageType] = useState(0)
const [imageIndex, setImageIndex] = useState(0)
  return (
    <div className="App" style={{ marginTop: 8, }}>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <div className="upload_image-wrapper">
            <GridList cellHeight={79} cols={4}>
            {uploadedPhotos.map((image, index) => (
                <GridListTile key={index} style={{ width: 79, }}>
                  <HighlightOffIcon key={index} className={classes.imageDelete} onClick={() => {setImageIndex(index);setImageType(0); setOpenDialog(true) }} />
                  <img src={new Map(Object.entries((image[1] as Media).urls)).get('main') as string} alt="" width="100%" />
                </GridListTile>
              ))}
              {imageList.map((image, index) => (
                <GridListTile key={index} style={{ width: 79, }}>
                  <HighlightOffIcon key={index} className={classes.imageDelete} onClick={()=>{setImageIndex(index);setImageType(1);setOpenDialog(true)}} />
                  <img src={image.dataURL} alt="" width="100%" />
                </GridListTile>
              ))}
              <label className={classes.cameraButton} style={{ marginLeft: 8, width: 79, }}>
                <GridListTile className={classes.imageContainer} style = {{background: '#eff2ff', border: '0.5px solid #258ee9'}}>

                  <img alt="camIcon" src={'/asset/Camera Icon.svg'} className={classes.icon} />
                  <Typography variant="subtitle2" color="primary" className={classes.iconText}>
                    {t("camera")}
                  </Typography>

                </GridListTile>
                <input type="file" id="capture" accept="image/*" capture="camera" style={{ display: 'none' }} onChange={(value) => { imageClicked(value) }} />
              </label>
              <GridListTile className={classes.imageContainer} style={{ marginLeft: 8, marginTop: 2, height: 79, width: 79, background: '#eff2ff', border: '0.5px solid #258ee9' }} onClick={onImageUpload} {...dragProps}>
                <img alt="camIcon" src={'/asset/Gallery Icon.svg'} className={classes.galleryIcon} />
                <Typography variant="subtitle2" color="primary" className={classes.galleryIconText} style={{ marginLeft: 18,marginTop: -2, }}>
                  {t("gallery")}
                </Typography>
              </GridListTile>
            </GridList>
            &nbsp;

          </div>
        )}
      </ImageUploading>
      <DialogAlertComponent 
        open = {openDialog} 
        handleClose = {()=>{setOpenDialog(false)}}
        heading = {t("common_discard_image")}
        content = {t("common_discard_image_description")}
        disagree = {t("save")}
        agree = {t("common_discard")}
        handleAgree={imageType === 0 ? () => { props.deleteUploadedElement(uploadedPhotos[imageIndex][0]); setOpenDialog(false) } :
          () => { deleteElement(imageIndex); setOpenDialog(false) }}
      />
    </div>
  );
}