export const DelarAction = {
    UPDATE_DELAR: "update_delar",
    UPDATE_NAME: "update_name",
    UPDATE_ALTERNATE_NUMBER: "update_alternate_number",
    UPDATE_INTRO: "update_intro",
    UPDATE_SHOP_NAME: "update_shop_name",
    UPDATE_STREET_ADDRESS: "update_street_address",
    UPDATE_LANDMARK: "update_landmark",
    UPDATE_STATE: "update_state",
    UPDATE_DISTRICT: "update_district",
    UPDATE_SUB_DISTRICT: "update_sub_district",
    UPDATE_VILLAGE: "update_village",
    UPDATE_PIN_CODE: "update_pin_code",
    UPDATE_PESTICIDE_LICENCE: "update_pesticide_licence",
    UPDATE_FERTILIZER_LICENCE: "update_fertilizer_licence",
    UPDATE_SEED_LICENCE: "update_seed_licence",
    UPDATE_STATUS: "update_status",
    RE_INIT_DELAR: "re_init_delar",
    SAVE_DATA: "save_data",
    GET_DELAR_INFO: "get_delar_info",
    UPLOAD_SHOP_IMAGES: "upload_shop_images",
    UPLOAD_PESTICIDE_IMAGES: "upload_pesticide_images",
    UPLOAD_FERTILISER_IMAGES: "upload_fertiliser_images",
    UPLOAD_SEED_IMAGES: "upload_seed_images",
    VALIDATE_PERSONAL_DETAIL_PAGE_ERROR: "validate_personal_detail_page_error",
    VALIDATE_BUSSINESS_DETAIL_PAGE_ERROR: "validate_business_detail_page_error",
    VALIDATE_DOCUMENT_PROOF_DETAIL_PAGE_ERROR: "validate_document_proof_page_error",
    UPDATE_SHOP_PHOTOS_ERROR: "update_shop_photos_error",
    UPDATE_PESTICIDE_IMAGES_ERROR: "update_pesticide_images_error",
    UPDATE_FERTILISER_IMAGES_ERROR: "update_fertiliser_images_error",
    UPDATE_SEED_IMAGES_ERROR: "update_seed_images_error",
    DELETE_SHOP_PHOTO: "delete_shop_photo",
    DELETE_PESTICIDE_PHOTO: "delete_pesticide_photo",
    DELETE_FERTILISER_PHOTO: "delete_fertiliser_photo",
    DELETE_SEED_PHOTO: "delete_seed_photo",
    GET_NOTIFICATION_FEED: "get_notification_feed",
    UPDATE_GST_NUMBER: "update_gst_number"
}