import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: '#fff',
            height: '100%'
        },
        appbar: {
            background: '#418ef2',
        },
        backIcon: {
            color: '#fff',
            fontSize: 30,
        },
        search: {
            position: 'relative',
            height: 40,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: '#fff',
            border: '0.5px solid #b1b1b1',
            marginRight: 0,
            marginLeft: 16,
            width: '100%',
        },
        searchIcon: {
            padding: theme.spacing(0, 1),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#000',
            opacity: 0.5,
        },
        inputRoot: {
            color: '#000',
        },
        inputInput: {
            padding: theme.spacing(1.75, 2, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: 12,
        },
        companyLogo: {
            width: 40, height: 40,
            marginLeft: 8,
        },
        companyName: {
            fontSize: 12,
            fontWeight: 600,
            marginLeft: 16,
        },
        nextButton: {
            width: "90%",
            margin: "auto",
            display: "flex",
            textTransform: 'capitalize',
            background: '#0E4BB4',
            fontSize: 14,
            height: 49,
            boxShadow: 'none'
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            position: 'fixed',
            maxWidth: 720,
            margin: 'auto',
            bottom: 0,
            height: 79,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        evenItem: {
            background: '#fff',
            height: 68,
        },
        oddItem: {
            background: '#fff',
            height: 68,
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            justifyContent: 'center',
            opacity: 0.5,
        },
    })
);
