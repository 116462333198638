import * as React from 'react'
import { Navigate, Route, RouteProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import { DelarStatus } from '../enum/delar-status.enum'
import { SecurityManager } from './security.manager'


const SecureRoute: React.FC<RouteProps> = props => {

    const loggedIn = SecurityManager.loggedIn()
    const verificationStatus = SecurityManager.getVerified()
    // console.log(verificationStatus)
    const location = useLocation();

    // return <Route {...props} />

    if (loggedIn && verificationStatus == DelarStatus.Verified) {
        return <Route {...props} />
    } else if (loggedIn && verificationStatus == DelarStatus.Pending) {
        const redirectUrl = '/review-pending'
        return <Navigate to={redirectUrl} />
    } else if (loggedIn && verificationStatus == DelarStatus.NotInit) {
        if(location.pathname == '/on-board/detail') {
            return <Route {...props} />
        }
        const redirectUrl = '/on-board/detail'
        return <Navigate to={redirectUrl} />
    } else if (loggedIn && verificationStatus == DelarStatus.Rejected) {
        if(location.pathname == '/verification-rejected') {
            return <Route {...props} />
        }
        const redirectUrl = '/verification-rejected'
        return <Navigate to={redirectUrl} />
    }
    else {
        let redirectUrl = '/on-board'
        const language = SecurityManager.getLanguage()
        if (language && language.length > 0) {
            redirectUrl = '/on-board/register'
        }
        
        return <Navigate to={redirectUrl} />
    }
}

export default SecureRoute