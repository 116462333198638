import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom: 64
        },
        buttonContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
            marginTop:8,
            marginBottom:8,
        },
        imgNoOrder: {
            width: 124,
            height: 136,
            margin: 'auto',
            display: 'block',
            marginBottom: 8,
            paddingTop: '24%'
        },
        description: {
            fontSize: 14,
            marginLeft: 37,
            marginRight: 37,
            marginTop: 8,
            maxLines: 3,
            textAlign: 'center',
            color: '#000000',
        },
        shareButton: {
            width:154, 
            height: 44,
            marginLeft:'32%', 
            marginTop: 8,
            marginRight: 'auto',
            borderRadius: 30,
            textTransform: 'capitalize',
            fontSize:12,
            boxShadow: 'none',
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            opacity: 0.5,
        }
    }),
);