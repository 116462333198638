import firebase from "firebase/app";
import { useEffect } from "react";

export const useFirebaseStorageIntercepter = () => {
    useEffect(() => {
        // for development
        //  const firebaseConfig = {
        //      apiKey: "AIzaSyBFsxdhPTtmHX5rJvNNYJp1RQNlPVRVNfw",
        //      authDomain: "khetipoint-dev.firebaseapp.com",
        //      projectId: "khetipoint-dev",
        //      storageBucket: "khetipoint-dev.appspot.com",
        //      messagingSenderId: "883382868669",
        //      appId: "1:883382868669:web:6949f86e1c90bc9d3fb5d4",
        //      measurementId: "G-NPHKLBVR0R",
        //  };

        //for production
        const firebaseConfig = {
          apiKey: "AIzaSyDXCkg6MC85dhMDnO6sHE0mDe4NZzF3ySw",
          authDomain: "kheti-point-2020.firebaseapp.com",
          databaseURL: "https://kheti-point-2020.firebaseio.com",
          projectId: "kheti-point-2020",
          storageBucket: "kheti-point-2020.appspot.com",
          messagingSenderId: "1049286782102",
          appId: "1:1049286782102:web:3d84299574d26e660266b7",
          measurementId: "G-QFZTRXPZ8G"
        };        
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
    }, [])
}