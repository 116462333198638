import { List, ListItem, ListItemText, Typography, ListItemAvatar, Button, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { OrderStatus } from "../../../../../../enum/order-status.enum";
import { Media } from "../../../../../../model/media.model";
import { UpdateOrderStatusDto } from "../../../../../../service/dto/order.dto";
import { DelarOrderProduct, SelectableDelarOrderProduct, toPackString } from "../../../../../../service/response/shipement.response";
import { useStyles } from "./newOrders.style";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";

export const NewOrderDetailComponent: React.FC<{ productList: Array<DelarOrderProduct>, updateItems: any }> = ({ productList, updateItems }) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const [products, setProducts] = useState<Array<SelectableDelarOrderProduct>>([])

    useEffect(() => {
        const _products: SelectableDelarOrderProduct[] = []
        productList.map((product: DelarOrderProduct, index: any) => {
            _products.push({
                delarOrderProduct: product,
                accepted: false,
                rejected: false
            } as SelectableDelarOrderProduct)
        })

        setProducts(_products)

    }, [productList])

    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        let _totalAmount = 0
        productList.map((product) => {
            _totalAmount = _totalAmount + product.pack.sellingPrice * product.pack.quantity
        })
        setTotalAmount(_totalAmount/100)
    }, [productList])


    const acceptItem = (orderProduct: DelarOrderProduct) => {
        const _products: SelectableDelarOrderProduct[] = []
        products.map((product: SelectableDelarOrderProduct, index: any) => {
            if (product.delarOrderProduct.id != orderProduct.id) {
                _products.push(product)
            } else {
                _products.push({
                    delarOrderProduct: product.delarOrderProduct,
                    accepted: true,
                    rejected: false
                } as SelectableDelarOrderProduct)
            }
        })
        setProducts(_products)
    }

    const rejectItem = (orderProduct: DelarOrderProduct) => {
        const _products: SelectableDelarOrderProduct[] = []
        products.map((product: SelectableDelarOrderProduct, index: any) => {
            if (product.delarOrderProduct.id != orderProduct.id) {
                _products.push(product)
            } else {
                _products.push({
                    delarOrderProduct: product.delarOrderProduct,
                    accepted: false,
                    rejected: true
                } as SelectableDelarOrderProduct)
            }
        })

        setProducts(_products)
    }

    const resetItem = (orderProduct: DelarOrderProduct) => {
        const _products: SelectableDelarOrderProduct[] = []
        products.map((product: SelectableDelarOrderProduct, index: any) => {
            if (product.delarOrderProduct.id != orderProduct.id) {
                _products.push(product)
            } else {
                _products.push({
                    delarOrderProduct: product.delarOrderProduct,
                    accepted: false,
                    rejected: false
                } as SelectableDelarOrderProduct)
            }
        })

        setProducts(_products)
    }

    useEffect(() => {
        updateItems(products)
    }, [products])

    return (
        <React.Fragment>
            <Typography variant="h6" className={classes.topHeading}>
                <b>{t("common_product_ordered")}</b>
            </Typography>
            <Divider />
            <List dense style={{ width: '100%', backgroundColor: 'background.paper' }}>
                {products.map((product: SelectableDelarOrderProduct, index: any) => {
                    const labelId = `${index}`
                    let photo = new Map(Object.entries(product.delarOrderProduct.product.photos)).values().next().value as Media
                    return (
                        <>
                            <ListItem style={{ position: 'relative', paddingLeft: 16 }}>
                                <ListItemAvatar style={{ top: 0, width: 60, height: 119 }}>
                                    <img style={{ width: 60, height: 60, }}
                                        alt={`Avatar ${photo.fid}`}
                                        src={new Map(Object.entries(photo.urls)).get('main') as string}
                                    />
                                </ListItemAvatar>
                                <ListItemText disableTypography id={labelId} primary={
                                    <Typography variant="body2" className={classes.heading}>
                                        <b>{product.delarOrderProduct.product.name} ({toPackString(product.delarOrderProduct.pack)})</b>
                                    </Typography>
                                }
                                    secondary={<div className={classes.secondaryHolder}>

                                        <div className={classes.secondaryContainer}>
                                            <Typography variant="subtitle1" className={classes.description}>
                                                {product.delarOrderProduct.product.company.name}
                                            </Typography>
                                        </div>
                                        <div className={classes.secondaryContainer}>
                                            <Typography variant="subtitle1" className={classes.info}>
                                                <b>{t("common_qty")}: {product.delarOrderProduct.pack.quantity}({(product.delarOrderProduct.pack.sellingPrice/100).toFixed(0)})</b>
                                            </Typography>
                                            <Typography variant="subtitle1" className={classes.info}>
                                                <b>{(product.delarOrderProduct.pack.quantity * product.delarOrderProduct.pack.sellingPrice/100).toFixed(0)}</b>
                                            </Typography>
                                        </div>
                                        <div className={classes.buttonHolder}>
                                            {!product.accepted && !product.rejected && (
                                                <>
                                                    <Button variant="outlined" color="primary" className={classes.declineButton} style={{ color: '#C64C27', fontWeight: 700, }} onClick={() => rejectItem(product.delarOrderProduct)}>
                                                        {t("common_decline")}
                                                    </Button>
                                                    <Button variant="outlined" color="primary" className={classes.acceptButton} style={{ color: '#1CCB2D', fontWeight: 700, }} onClick={() => acceptItem(product.delarOrderProduct)}>
                                                        {t("common_accept")}
                                                    </Button>
                                                </>
                                            )}

                                            {product.accepted && (
                                                <>
                                                    <Button variant="outlined" color="primary" className={classes.acceptButton} style={{ color: '#1CCB2D', fontWeight: 700, }} onClick={() => resetItem(product.delarOrderProduct)}>
                                                        {t("common_accepted")}
                                                        <CloseIcon style={{ color: '#000', fontSize: 10, }} />
                                                    </Button>
                                                </>
                                            )}

                                            {product.rejected && (
                                                <>
                                                    <Button variant="outlined" color="primary" className={classes.declineButton} style={{ color: '#C64C27', fontWeight: 700, }} onClick={() => resetItem(product.delarOrderProduct)}>
                                                        {t("common_declined")}
                                                        <CloseIcon style={{ color: '#000', fontSize: 10, }} />
                                                    </Button>
                                                </>
                                            )}



                                        </div>
                                    </div>
                                    } />

                            </ListItem>
                            <Divider />
                        </>
                    );
                })}
            </List>
            <div className={classes.secondaryContainer}>
                <Typography variant="subtitle1" className={classes.totalText}>
                    <b>{t("common_grand_total")}</b>
                </Typography>
                <Typography variant="subtitle1" className={classes.priceText}>
                    <b>{totalAmount.toFixed(0)}</b>
                </Typography>
            </div>
        </React.Fragment>
    )
}