import { EditProfileAction } from "../action/edit-profile.action";
import { DelarPhotoType } from "../enum/delar-photo-type.enum";
import { Media } from "../model/media.model";
import { DelarService } from "../service/delar.service";
import { DelarPhotoDeleteDto } from "../service/dto/delar.dto";
import { StorageService } from "../service/storage.service";
import { EditProfileStore } from "../store/edit-profile.store";

const updateData = (delarId: string, action: any) => {
    const delarDto = EditProfileStore.getDelarDto()
    DelarService.updateDelarById(delarId, delarDto).then(res => {
        action()
    }).catch(err => {
        console.log(err)
    })

}
const reInitDelarDto = (delarId: string, action: any, errorAction: any) => {
    DelarService.getDelarById(delarId).then(res => {
        EditProfileStore.reInitDelarDto(res.data)
        action()
    }).catch(err => {
        errorAction()
    })
}
const shopImagesUpload = (delarId: string, shopPhotos: any[], action: any) => {
    const promises: any[] = []
    shopPhotos.map(shopPhoto => {
        promises.push(StorageService.uploadShopImage(shopPhoto.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        EditProfileStore.updateShopImages(medias)
        updateData(delarId, action)
    }).catch(err => {

    });
}
const pesticideImagesUpload = (delarId: string, pesticidePhotos: any[], action: any) => {
    const promises: any[] = []
    pesticidePhotos.map(pesticidePhoto => {
        promises.push(StorageService.uploadDocument(pesticidePhoto.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        EditProfileStore.updatePesticideImages(medias)
        updateData(delarId, action)
    }).catch(err => {

    });
}
const fertilizerImagesUpload = (delarId: string, fertilizerPhotos: any[], action: any) => {
    const promises: any[] = []
    fertilizerPhotos.map(fertilizerPhoto => {
        promises.push(StorageService.uploadDocument(fertilizerPhoto.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        EditProfileStore.updateFertilizerImages(medias)
        updateData(delarId, action)
    }).catch(err => {

    });
}
const seedImagesUpload = (delarId: string, seedPhotos: any[], action: any) => {
    const promises: any[] = []
    seedPhotos.map(seedPhoto => {
        promises.push(StorageService.uploadDocument(seedPhoto.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        EditProfileStore.updateSeedImages(medias)
        updateData(delarId, action)
    }).catch(err => {

    });
}

const deleteShopPhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_SHOP_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        EditProfileStore.deleteShopPhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}

const deletePesticidePhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_NEW_PESTICIDE_LISCENCE_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        EditProfileStore.deleteNewPesticidePhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}


const deleteFertiliserPhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_NEW_FERTILIZER_LISCENCE_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        EditProfileStore.deleteNewFertiliserPhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}


const deleteSeedPhoto = (fid: string, delarId: string, action: any, errorAction: any) => {
    const delarPhotoDeleteDto = {
        "type": DelarPhotoType.DELAR_NEW_SEED_LISCENCE_PHOTO,
        "fid": fid
    } as DelarPhotoDeleteDto
    DelarService.deleteDelarPhotoById(delarId, delarPhotoDeleteDto).then(res => {
        EditProfileStore.deleteNewSeedPhoto(fid)
        action()
    }).catch(err => {
        errorAction()
    })
}

export const EditProfileActor = (action: any, data: any) => {
    switch (action.type) {
        case EditProfileAction.UPDATE_NAME:
            EditProfileStore.updateName(data.name)
            break;
        case EditProfileAction.UPDATE_ALTERNATE_PHONE:
            EditProfileStore.updateAlternatePhone(data.alternatePhone)
            break;
        case EditProfileAction.UPDATE_INTRO:
            EditProfileStore.updateIntro(data.intro)
            break;
        case EditProfileAction.UPDATE_SHOP_NAME:
            EditProfileStore.updateShopName(data.newShopName)
            break;
        case EditProfileAction.UPDATE_PESTICIDE_LICENSE:
            EditProfileStore.updateNewPesticideLicenseNumber(data.pesticideLicenseNumber)
            break;
        case EditProfileAction.UPDATE_FERTILIZER_LICENSE:
            EditProfileStore.updateNewFertilizerLicenseNumber(data.fertilizerLicenseNumber)
            break;
        case EditProfileAction.UPDATE_SEED_LICENSE:
            EditProfileStore.updateNewSeedLicenseNumber(data.seedLicenseNumber)
            break;
        case EditProfileAction.UPDATE_STREET_ADDRESS:
            EditProfileStore.updateStreetAddress(data.streetLine)
            break;
        case EditProfileAction.UPDATE_LANDMARK:
            EditProfileStore.updateLandmark(data.landMark)
            break;
        case EditProfileAction.UPDATE_STATE:
            EditProfileStore.updateState(data.state)
            break;
        case EditProfileAction.UPDATE_DISTRICT:
            EditProfileStore.updateDistrict(data.district)
            break;
        case EditProfileAction.UPDATE_SUB_DISTRICT:
            EditProfileStore.updateSubDistrict(data.subDistrict)
            break;
        case EditProfileAction.UPDATE_VILLAGE:
            EditProfileStore.updateVillage(data.village)
            break;
        case EditProfileAction.UPDATE_PINCODE:
            EditProfileStore.updatePincode(data.pincode)
            break;
        case EditProfileAction.UPDATE_DATA:
            updateData(data.delarId, data.action)
            break;
        case EditProfileAction.RE_INIT_DELAR:
            reInitDelarDto(data.delarId, data.action, data.errorAction)
            break;
        case EditProfileAction.DELETE_SHOP_PHOTO:
            EditProfileStore.deleteShopPhoto(data.key)
            break;
        case EditProfileAction.DELETE_SHOP_PHOTO:
            deleteShopPhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case EditProfileAction.DELETE_PESTICIDE_PHOTO:
            deletePesticidePhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case EditProfileAction.DELETE_FERTILIZER_PHOTO:
            deleteFertiliserPhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case EditProfileAction.DELETE_SEED_PHOTO:
            deleteSeedPhoto(data.key, data.delarId, data.action, data.errorAction)
            break;
        case EditProfileAction.UPLOAD_NEW_SHOP_IMAGES:
            shopImagesUpload(data.delarId, data.shopPhotos, data.action)
            break;
        case EditProfileAction.UPLOAD_NEW_PESTICIDE_IMAGES:
            pesticideImagesUpload(data.delarId, data.pesticidePhotos, data.action)
            break;
        case EditProfileAction.UPLOAD_NEW_FERTILIZER_IMAGES:
            fertilizerImagesUpload(data.delarId, data.fertilizerPhotos, data.action)
            break;
        case EditProfileAction.UPLOAD_NEW_SEED_IMAGES:
            seedImagesUpload(data.delarId, data.seedPhotos, data.action)
            break;
        case EditProfileAction.UPDATE_SEED_IMAGES_ERROR:
            EditProfileStore.updateSeedImagesError(data.flag)
            break;
        case EditProfileAction.UPDATE_FERTILIZER_IMAGES_ERROR:
            EditProfileStore.updateFertiliserImagesError(data.flag)
            break;
        case EditProfileAction.UPDATE_PESTICIDE_IMAGES_ERROR:
            EditProfileStore.updatePesticideImagesError(data.flag)
            break;
        case EditProfileAction.UPDATE_SHOP_IMAGES_ERROR:
            EditProfileStore.updateShopImagesError(data.flag)
            break;
        case EditProfileAction.VALIDATE_PESTICIDE_LICENCE_ERROR:
            EditProfileStore.validatePesticideLicenceError()
            break;
        case EditProfileAction.VALIDATE_FERTILIZER_LICENCE_ERROR:
            EditProfileStore.validateFertilizerLicenceError()
            break;
        case EditProfileAction.VALIDATE_SEED_LICENCE_ERROR:
            EditProfileStore.validateSeedLicenceError()
            break;
        default:
            break;
    }
}