import { AppBar, Avatar, BottomNavigation, Modal, Button, Checkbox, InputBase, List, ListItem, ListItemSecondaryAction, ListItemText, Toolbar, Typography, Divider, ListItemAvatar } from "@material-ui/core"
import { useState, useEffect, useRef } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from "./filter.style"
import { useObservable } from "../../../../useSubscriber";
import SearchIcon from '@material-ui/icons/Search'
import { FilterActor } from "../../../../actor/filter.actor";
import { FilterStore } from "../../../../store/filter.store";
import { FilterAction } from "../../../../action/filter.action";
import { useTranslation } from "react-i18next"
import { ShopCompany } from "../../../../service/response/shop-company.response";
import LoadingComponent from "../../../common/loading/loading.component";
import InfinitScroll from 'react-infinite-scroll-component';
import useWindowDimensions from "../../../../util/getWindowDimensions";
import { toast } from "react-toastify";


export const FilterComponent = (props: any) => {

    const { height, width } = useWindowDimensions();

    const { t } = useTranslation();
    const classes = useStyles();

    const [companyList, _] = useObservable(FilterStore.getShopCompanyList(), FilterStore.getShopCompanyListObservable())

    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(false)

    // let selectedCompaniesList: ShopCompany[] = []

    let selectedCompaniesList = useRef<ShopCompany[]>([])

    const timeOut = useRef<any>(0)

    const afterDataFetch = () => {
        setLoading(false)
    }

    const handleSearch = (e: any) => {
        if (e.target.value.length === 0) {
            return
        }

        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
            setLoading(true)
            FilterActor({ "type": FilterAction.UPDATE_SEARCH_TEXT }, { "searchText": e.target.value, "afterDataFetch": afterDataFetch })
        }, 500);
    }

    const handlePagination = () => {
        FilterActor({ "type": FilterAction.GET_LIST }, { "setHasMore": setHasMore })
    };

    useEffect(() => {
        setLoading(true)
        FilterActor({ "type": FilterAction.INIT_SEARCH_DTO_AND_SHOP_COMPANY_LIST }, {})
        FilterActor({ "type": FilterAction.GET_LIST }, { "afterDataFetch": afterDataFetch, "setHasMore": setHasMore })
    }, [])

    const updateSelectedList = (newcompany: ShopCompany) => {
        if (selectedCompaniesList.current.indexOf(newcompany) == -1) {
            let _selectedCompaniesList = selectedCompaniesList.current
            _selectedCompaniesList.push(newcompany)
            selectedCompaniesList.current = _selectedCompaniesList
        } else {
            let _selectedCompaniesList = selectedCompaniesList.current
            _selectedCompaniesList = _selectedCompaniesList.filter(company => {
                return company.id != newcompany.id
            })
            selectedCompaniesList.current = _selectedCompaniesList
        }
    }

    const applyFilter = () => {
        props.applyFilter(selectedCompaniesList.current)
        props.handleClose()
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                style={{ opacity: 1, maxWidth: 720, margin: 'auto' }}
            >
                <div className={classes.root}>
                    <AppBar elevation={0} position="sticky" className={classes.appbar}>
                        <Toolbar>
                            <ArrowBackIcon className={classes.backIcon} onClick={props.handleClose} />
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t("common_search_company")}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e: any) => { handleSearch(e) }}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                    {(loading || props.loading) && (
                        <LoadingComponent />
                    )}
                    {companyList && companyList.length > 0 && (
                        <InfinitScroll
                            dataLength={companyList.length}
                            next={handlePagination}
                            hasMore={false}
                            loader={<></>}
                        >
                            <List dense style={{ width: '100%', maxHeight: `${Number(height) - 200}px`, backgroundColor: 'background.paper', marginBottom: 40 }}>
                                {companyList.map((company, index) => {
                                    const labelId = `${company.id}`
                                    if (company && company.logo && company.name && company.name.length > 0 && company.logo.urls) {
                                        return (
                                            <>
                                                <ListItem key={index} button className={index % 2 === 1 ? classes.evenItem : classes.oddItem}>
                                                    <ListItemAvatar>
                                                        <img
                                                            alt={`Avatar ${company.name}`}
                                                            src={new Map(Object.entries(company.logo.urls)).get('main') as string}
                                                            className={classes.companyLogo}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.companyName}>
                                                        {company.name}
                                                    </Typography>} />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={(e: any) => updateSelectedList(company)}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            color="primary"
                                                            style={{ marginRight: -8, }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        );
                                    }
                                })}
                            </List>
                        </InfinitScroll>
                    )}

                    <BottomNavigation showLabels className={classes.bottomNavigation}>
                        <Button className={classes.nextButton} variant="contained" color="primary" onClick={applyFilter} >
                            {t("apply")}
                        </Button>
                    </BottomNavigation>
                </div>
            </Modal>
        </>
    );
}