import { AppBar, Avatar, Container, Link, Toolbar, Typography } from "@material-ui/core"
import React from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useNavigate } from "react-router"
import { useStyles } from './about-us.style'
import { useTranslation } from "react-i18next"

export const AboutUsComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    const navigate = useNavigate()
    return (
        <div className = {classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}} />
                    <Typography variant="h6" className={classes.title}>
                        {t("about_us")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Typography variant="body1" className={classes.title1}>
                <b> {t("about_line_1")} </b>
            </Typography>
            <Typography paragraph variant="subtitle1" className={classes.title2}>
                {t("about_line_2_1")}<br /> <br /> {t("about_line_2_2")}
            </Typography>
            <Typography variant="body1" className={classes.title1}>
                <b> {t("about_line_3")} </b>
            </Typography>
            <Typography paragraph variant="subtitle1" className={classes.title2}>
            {t("about_line_4_1")}<br/>{t("about_line_4_2")}<br/>{t("about_line_4_3")}<br/>{t("about_line_4_4")}<br/>{t("about_line_4_5")}<br/>
            </Typography>
            <Typography paragraph variant="subtitle1" className={classes.title3}>
            {t("about_line_5")}
            </Typography>
            <Typography variant="body1" className={classes.title1} style = {{fontSize: 16,}}>
                <b> {t("about_line_6")} </b>
            </Typography>
            <Typography variant="body1" className={classes.title1} style = {{fontSize: 16, wordBreak: 'break-all'}}>
                <b> {t("fb_link")} <Link href="#" onClick={preventDefault}>https://www.facebook.com/Khetipoint/</Link> </b>
            </Typography>
        </div>
    )
}