import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: '#fff',
        },
        backIcon: {
            color: '#fff',
            fontSize: 30,
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        productImage: {
            width: 64,
            height: 64,
        },
        productName: {
            fontSize: 12,
            fontWeight: 600,
        },
        productDescription: {
            fontSize: 11,
            opacity: 0.8,
        },
        infoText1: {
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 16,
            maxLines: 3,
            fontSize: 12,
            fontWeight: 400,
        },
        nextButton: {
            width: "80%",
            margin: "auto",
            display: "flex",
            textTransform: 'capitalize',
            background: '#0E4BB4',
            fontSize: 14,
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            maxWidth: 720,
            margin: 'auto',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    })
);
