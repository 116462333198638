import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        backIcon: {
            position: 'fixed',
            top: theme.spacing(2),
            left: theme.spacing(2),
            fontSize: 30,
        },
        otpHolder: {
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            fontSize: '0.7857142857142857em',
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        title1: {
            flexGrow: 1,
            
            align: "left",
            letterSpacing: 0,
            marginLeft: theme.spacing(2),
            
        },
        title2: {
            flexGrow: 1,
            align: "left",
            height: 15,
            opacity: 0.6,
            letterSpacing: 0.24,
            marginLeft: theme.spacing(2),
            marginTop: 8.91,
            fontSize: 12,
        },
        otpFormat: {
            marginLeft: theme.spacing(2),
            borderRadius: 5,
            marginTop: 24,
            width: 47,
            height: 47,
            background: '#F1F2F4',
            justifyContent: 'center',
        },
        inputStyle: {
            background: '#F1F2F4',
            border: 0,
            fontSize: 18,   
        },
        title3: {
            fontSize: 14,
            flexGrow: 1,
            height: 18,
            color: "#4B514F",
            align: "left",
            opacity: 1,
            letterSpacing: 0.28,
            marginLeft: theme.spacing(2),
            marginTop: 24,
        },
        title4: {
            fontSize: 14,
            flexGrow: 1,
            height: 18,
            color: "#4B514F",
            align: "left",
            opacity: 1,
            letterSpacing: 0.28,
            marginTop: 24.09,
            marginLeft: 8,
            fontWeight: 600,
            marginRight: theme.spacing(15),
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            outline: 'none',
            backgroundColor: 'transparent',
            opacity: 0.5,
        },
    }),
);