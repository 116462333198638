import { Avatar, Button, Card, CardContent, Container, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../../router/route.manager"
import { useStyles } from "./add-product-zero.style"
export const AddProductZeroComponent = () => {
    const classes = useStyles();
    const routeManager = useRouteManager();
    const { t } = useTranslation();
    return (
        <Card className={classes.root}>
            <CardContent>
                <img src='/asset/product zero.svg' className={classes.img} />
            </CardContent>
            <Typography variant="body2" className={classes.heading}>
                <b>{t("home_add_products_to_shop_zero_state")}</b>
            </Typography>
            <Typography variant="body2" className={classes.description}>
                {t("home_add_products_zero_state_description")}
            </Typography>
            <Button variant="contained" color="primary" className={classes.button} style={{ color: '#FFFFFF', fontWeight: 700, borderWidth: 1, }} onClick={() => routeManager.openAddProduct()}>
                {t("home_zero_state_add_product")}
            </Button>
        </Card>
    );
}