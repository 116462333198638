import React, { useEffect, useState } from "react"
import { AppBar, Avatar, BottomNavigation, BottomNavigationAction, IconButton, Toolbar, Typography } from "@material-ui/core"
import { Outlet, useLocation } from "react-router-dom"
import MenuIcon from '@material-ui/icons/Menu'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import { useStyles } from "./main.style"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../router/route.manager"
import { AndroidConnerctor } from "../../android.connecter"
import { useObservable } from "../../useSubscriber";
import { DelarStore } from "../../store/delar.store";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const MainComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeManager = useRouteManager();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (location.pathname == "/product") {
      setActiveTab(1)
    } else if (location.pathname == "/order") {
      setActiveTab(2)
    } else {
      setActiveTab(0)
    }
  }, [])

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/*delarInfo?.name && delarInfo?.name.length > 0 && (
          <>
          <ListItem>
            <ListItemText primary={<Typography> Hi, {delarInfo.name}</Typography>}/>
          </ListItem>
          <Divider/>
          </>
        )*/}
        <ListItem button key="my_serving_villages"
          onClick={() => { routeManager.openProfile() }}>
          <Divider />
          <ListItemIcon><Avatar alt="profile" src={'../drawable/ic_user.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("profile")} style={{ marginLeft: -8, marginBottom: 16, }} />
        </ListItem>
        <ListItem button key="main_share_dukaan"
          onClick={() => { AndroidConnerctor.shareDukaan("This is dukaan share testing") }}>
          <Divider />
          <ListItemIcon><img alt="share" src={'../drawable/ic_share.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("main_share_dukaan")} style={{ marginLeft: -8, marginBottom: 16 }} />
        </ListItem>
        <ListItem button key="about_khetipoint"
          onClick={() => { routeManager.openAboutUs() }}>
          <Divider />
          <ListItemIcon><img alt="about" src={'../drawable/ic_info.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("about_khetipoint")} style={{ marginLeft: -8, marginBottom: 16 }} />
        </ListItem>
        <ListItem button key="main_rate_khetipoint"
          onClick={() => { AndroidConnerctor.rateKhetiPoint() }}>
          <Divider />
          <ListItemIcon><Avatar alt="rate" src={'../drawable/ic_rate_app.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("main_rate_khetipoint")} style={{ marginLeft: -8, marginBottom: 16 }} />
        </ListItem>
        <ListItem button key="main_recommend"
          onClick={() => { AndroidConnerctor.recommendedToFriends("This is recommand to friends testing") }}>
          <Divider />
          <ListItemIcon><Avatar alt="recommend" src={'../drawable/ic_reco.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("main_recommend")} style={{ marginLeft: -8, marginBottom: 16 }} />
        </ListItem>
        <ListItem button key="support"
          onClick={() => { AndroidConnerctor.openSupportDialog("7755058011") }}>
          <Divider />
          <ListItemIcon><Avatar alt="support" src={'../drawable/ic_support.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("support")} style={{ marginLeft: -8, marginBottom: 16 }} />
        </ListItem>
        <ListItem button key="settings"
          onClick={() => { routeManager.openSetting() }}>
          <Divider />
          <ListItemIcon><Avatar alt="setting" src={'../drawable/ic_setting.svg'} className={classes.menuIcon} /></ListItemIcon>
          <ListItemText primary={t("settings")} style={{ marginLeft: -8, marginBottom: 16, }} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
            onClick={toggleDrawer("left", true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t("khetipoint")}
          </Typography>

          {/*TODO: need to handle after release*/}
          {/* <IconButton edge="end"
            onClick={() => { routeManager.openNotification() }}
            className={classes.notificationButton} color="inherit" aria-label="menu">
            <NotificationsIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>

      <div className={classes.mainView}>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>

        <Outlet />

        <BottomNavigation
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
          showLabels
          className={classes.bottomNavigation}
        >
          <BottomNavigationAction onClick={() => routeManager.openHome()} label={t("home")} icon={<img alt="product" src={'../drawable/ic_home.svg'} className={classes.bottomIcon} />} />
          <BottomNavigationAction onClick={() => routeManager.openProduct()} label={t("product")} icon={<img alt="product" src={'../drawable/ic_product.svg'} className={classes.bottomIcon} />} />
          <BottomNavigationAction onClick={() => routeManager.openOrderTab()} label={t("order")} icon={<img alt="order" src={'../drawable/ic_order.svg'} className={classes.bottomIcon} />} />
        </BottomNavigation>
      </div>
    </React.Fragment>
  )
}