import { Avatar, Button, Card, CardContent, Container, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "./rejected.style"
export const RejectedComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Card className = {classes.root}>
            <CardContent>
                <img src = '../asset/rejected.svg' className = {classes.img}/>
            </CardContent>
            <Typography variant = "body2" className = {classes.heading}>
                {t("application_status")}
            </Typography>
            <Typography variant = "body2" className = {classes.rejected}>
                {t("rejected")}
            </Typography>
            <Typography variant = "body2" className = {classes.description}>
                {t("application_rejected_text")}
            </Typography>
            <div className = {classes.buttonHolder}>
            <Button variant="outlined" color="primary" className={classes.button} style = {{color: '#0e4bb4', fontWeight: 700, borderWidth: 1,}}>
                    {t("edit_form")}
                </Button>
                <Button variant="contained" color="primary" className={classes.button} style = {{backgroundColor: '#0e4bb4',}}>
                    {t("apply_again")}
                </Button>
            </div>
            
        </Card>
    );
}