import { Modal, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useStyles } from "./validate-otp.style";
import OtpInput from 'react-otp-input';
import { useObservable } from "../../../useSubscriber";
import { RegistrationStore } from "../../../store/registration.store";
import { useTranslation } from "react-i18next";
import { RegistrationAction } from "../../../action/registration.action";
import { RegistrationActor } from "../../../actor/registration.actor";
import { useRouteManager } from "../../../router/route.manager";
import { GenerateOTPRes, ValidateOTPRes } from "../../../service/response/registration.response";
import { DelarStatus } from "../../../enum/delar-status.enum";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../common/loading/loading.component";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const ValidateOtpComponent = () => {
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const [validateOtpRes, validateOtpError] = useObservable(RegistrationStore.getValidateOtpRes(), RegistrationStore.getValidateOtpResObservable())
    const [optSending, setOptSending] = useState(false);
    const genearteOtpRes = RegistrationStore.getGenearteOtpRes() as GenerateOTPRes
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const [state, setState] = useState({
        otp: '',
    });
    const [seconds, setSeconds] = useState(30);

    const handleChange = (otp: any) => {
        setState({ otp });
        if (otp.length >= 4) {
            validateOTP(otp)
        }
    }

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
        }
    });

    useEffect(() => {
        if (!genearteOtpRes) {
            routeManager.openRegister()
        }
    }, [])

    useEffect(() => {
        if (!validateOtpError.error && validateOtpRes) {
            var _validateOtpRes = validateOtpRes as ValidateOTPRes
            if (_validateOtpRes.delar.status.value == DelarStatus.NotInit) {
                if (genearteOtpRes.exist) {
                    routeManager.openDelarDetail()
                } else {
                    routeManager.openIntro()
                }
            } else if (_validateOtpRes.delar.status.value == DelarStatus.Pending) {
                routeManager.openReviewPending()
            } else {
                routeManager.openHome()
            }
        }

    }, [validateOtpRes, validateOtpError])

    const validateOTP = (otp: string) => {
        if (genearteOtpRes) {
            setLoading(true)
            RegistrationActor({ "type": RegistrationAction.VALIDATE_OTP }, {
                "otp": otp,
                "phone": genearteOtpRes.phone,
                "action": () => { setLoading(false) }
            })
        }
    }
    const resendHandle = () => {
        if (seconds === 0) {
            setOptSending(true)
            setState({ otp: '' })
            RegistrationActor({ "type": RegistrationAction.SEND_OTP }, { "phone": genearteOtpRes.phone, "action": () => { setOptSending(false); setSeconds(30) } })

        }

    }
    const navigate = useNavigate()
    return (
        <>
            {genearteOtpRes && genearteOtpRes.phone && (
                <div className={classes.root}>
                    <ArrowBackIcon className={classes.backIcon} onClick={() => { navigate(-1) }} />
                    <div className={classes.otpHolder}>
                        <Typography variant="h4" className={classes.title1} >
                            <b>{t("otp")}</b>
                        </Typography>
                        <Typography variant="subtitle2" className={classes.title2} >
                            {t("validate_otp_input_before")} +91 {genearteOtpRes.phone} {t("validate_otp_input_after")}
                        </Typography>
                        <OtpInput
                            value={state.otp}
                            onChange={handleChange}
                            className={classes.otpFormat}
                            numInputs={4}
                            inputStyle={classes.inputStyle}
                            separator={<span></span>}
                        />
                        {validateOtpError.error && <Typography variant="body1" style={{ marginLeft: 10 }} className={classes.errorText}>
                            <ErrorOutlineIcon className={classes.errorIcon} />{t("validate_invalid_otp")}
                        </Typography>}
                        <div style={{ display: "flex" }}>
                            <Typography variant="subtitle2" className={classes.title3} noWrap>
                                {t("validate_otp_didnt_recieve")}
                            </Typography>
                            <Typography variant="subtitle2" className={classes.title4} noWrap>
                                {seconds !== 0 && <span>00:{seconds <= 9 ? '0' : ''}{seconds}</span>} <span onClick={resendHandle} style={{ color: seconds === 0 ? '#1078FF' : '#2a312f' }}>{t("validate_otp_resend")}</span>
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
            <Modal open={(optSending || loading || !genearteOtpRes || !genearteOtpRes.phone)} className={classes.modal}><LoadingComponent /></Modal>
        </>
    )
}
