import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        topHeading: {
            marginTop: 12,
            marginBottom: 8,
            marginLeft: 16,
            fontSize: 16,
            fontWeight: 600,
        },
        statusHeading: {
            fontSize: 16,
            fontWeight: 600,
            color: '#D6D000',
        },
        numberHeading: {
            fontSize: 10,
            opacity: 0.6,
            marginLeft: 8,
            marginTop: 5,
        },
        time: { 
            fontSize: 10,
            opacity: 0.6,
        },
        heading: {
            marginLeft: 8,
            fontSize: 14,
            fontWeight: 400,
        },
        primaryContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'center',
        },
        statusNumberContainer: {
            display: 'flex', 
            justifyContent: 'space-between',  
            flexDirection: 'row',
            alignItems: 'left',
        },
        description: {
            marginLeft: 8,
            marginTop:3,
            fontSize: 12,
            opacity: 0.7,
        },
        info: {
            marginLeft: 8,
            fontSize: 14,
            fontWeight: 400,
        },
        secondaryHolder: {
            display: 'flex',
            flexDirection: 'column',
        },
        secondaryContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        totalText: {
            marginTop:8,
            marginBottom: 8,
            marginLeft: 16,
            fontSize: 14,
            fontWeight: 400,
        },
        priceText: {
            marginTop:8,
            marginBottom: 8,
            marginRight: 16,
            fontSize: 14,
            fontWeight: 400,
        },
        downloadText: {
            marginTop:8,
            marginLeft: 16,
            fontSize: 13,
            opacity: 0.7,
        },
    }),
);