import { ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DelarStatus } from "../../enum/delar-status.enum";
import { useRouteManager } from "../../router/route.manager";
import { SecurityManager } from "../../security/security.manager"
import { ValidateOTPRes } from "../../service/response/registration.response";

export const ApplicationRejectedComponent = () => {
    const { t } = useTranslation()

    const routeManager = useRouteManager();

    const logoutUser = () => {
        SecurityManager.logout()
    }

    useEffect(() => {
        if (!SecurityManager.loggedIn() || SecurityManager.getVerified() != DelarStatus.Rejected) {
            routeManager.openHome()
        }
    }, [])

    return (
        <>

            <h1>Application Rejected</h1>

            <p>Please Contanct Khetipoint support</p>

            <ListItem onClick={() => { logoutUser() }} style={{ marginTop: 8, marginBottom: 6, }}>
                <ListItemText primary={<Typography variant="body2">{t("logout")}</Typography>} />
            </ListItem>

        </>
    )
}
