// export const SERVER_URL = 'http://localhost:5500/v1/'
//  export const SERVER_URL = 'https://api-dev.khetipoint.com/v1/'
export const SERVER_URL = 'https://api.khetipoint.com/v1/'

export const DELAR_APP_SESSION = 'delar_app_session'
export const DELAR_APP_TOKEN = 'delar_app_token'

export const DELAR_APP_LANG_CODE = 'delar_app_lang_code'

export const DATE_FORMAT = { day: 'numeric', month: 'long', year: 'numeric' } as any

export const DELAR_VILLAGE_UPPER_CAP = 10

export const SUPPORT_NUMBER = "7755058011"

export const PRODUCT_SEARCH_PAGE_SIZE = 20
export const PRODUCT_LIST_PAGE_SIZE = 20