import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 16,
        },
        title1: {
            marginLeft: 16,
            marginTop: 24,
            fontSize: 14,
        },
        gridList: {
            marginTop:16,
            marginLeft:16,
            width: '100%',
            height: '50%',
        },
        requestButton:{
            width: '95%',
            alignSelf: 'center',
            justifySelf: 'center',
            padding: 10,
            textTransform: 'capitalize',
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    }),
);