import { BehaviorSubject, Observable } from "rxjs"
import { PRODUCT_LIST_PAGE_SIZE } from "../../constant/base.constant"
import { SecurityManager } from "../../security/security.manager"
import { DelarProductSearchDto } from "../../service/dto/product.dto"
import { Company } from "../../service/response/company.response"
import { DelarProductInfoRes } from "../../service/response/product.response"
import { ShopCompany } from "../../service/response/shop-company.response"


let _delarProductList: Array<DelarProductInfoRes> = []
let _delarProductListSubject = new BehaviorSubject<Array<DelarProductInfoRes> | null>(null)

let _delarProductSearchDto: DelarProductSearchDto = { delarId: SecurityManager.getUid(), limit: PRODUCT_LIST_PAGE_SIZE, orderType: 1 } as DelarProductSearchDto
let _delarProductSearchDtoSubject = new BehaviorSubject<DelarProductSearchDto | null>(null)

let _delarProductInfo: DelarProductInfoRes = { product: { name: "", company: { name: "" }, chemical: { chemicalID: "" }, description: "" } } as unknown as DelarProductInfoRes
let _delarProductInfoSubject = new BehaviorSubject<DelarProductInfoRes | null>(null)

let _filterCompanies: Array<ShopCompany> = []
let _filterCompaniesSubject = new BehaviorSubject<Array<ShopCompany> | null>(null)

export const ProductStore = {
    reSetSearchDtoAndProductList: () => {
        _delarProductList = []
        _delarProductListSubject.next(_delarProductList)

        _delarProductSearchDto.pageNumber = 0
        _delarProductSearchDto.delarId = SecurityManager.getUid()
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    upadteDelarProductList: (newDelarProductList: Array<DelarProductInfoRes>) => {
        _delarProductList = [..._delarProductList, ...newDelarProductList];
        _delarProductListSubject.next([..._delarProductList])
    },
    getDelarProductList: (): Array<DelarProductInfoRes> | null => {
        return _delarProductList
    },
    getDelarProductListObservable: (): Observable<Array<DelarProductInfoRes> | null> => {
        return _delarProductListSubject.asObservable()
    },
    upadteDelarProductSearchDto: (newDelarProductSearchDto: DelarProductSearchDto) => {
        _delarProductSearchDto = newDelarProductSearchDto
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    updateSearchText: (searchText: string) => {
        _delarProductSearchDto.searchText = searchText
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    updateCompanyId: (companyIds: Array<string>) => {
        _delarProductSearchDto.companyIds = companyIds
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },

    updateAvailability: (available: number) => {
        _delarProductSearchDto.avability = available
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    getDelarProductSearchDto: (): DelarProductSearchDto => {
        _delarProductSearchDto.delarId = SecurityManager.getUid()
        return _delarProductSearchDto
    },
    getDelarProductSearchDtoObservable: (): Observable<DelarProductSearchDto | null> => {
        return _delarProductSearchDtoSubject.asObservable()
    },

    upadteDelarProductInfo: (newDelarProductInfo: DelarProductInfoRes) => {
        _delarProductInfo = newDelarProductInfo
        _delarProductInfoSubject.next({ ..._delarProductInfo })
    },
    getDelarProductInfo: (): DelarProductInfoRes | null => {
        return _delarProductInfo
    },
    getDelarProductInfoObservable: (): Observable<DelarProductInfoRes | null> => {
        return _delarProductInfoSubject.asObservable()
    },
    resetDelarProductInfo: () => {
        _delarProductInfo = { product: { name: "", company: { name: "" }, chemical: { chemicalID: "" }, description: "" } } as unknown as DelarProductInfoRes
        _delarProductInfoSubject.next({ ..._delarProductInfo })
    },

    upadteFilterCompaniesList: (newCompanies: Array<ShopCompany>) => {
        _filterCompanies = newCompanies
        _delarProductSearchDto.companyIds = _filterCompanies.map((company: Company) => {
            return company.id
        })
        _delarProductSearchDtoSubject.next(_delarProductSearchDto)
        _filterCompaniesSubject.next([..._filterCompanies])
    },
    removeFilterCompany: (_company: ShopCompany) => {
        _filterCompanies = _filterCompanies.filter(company => {
            return company.id != _company.id
        })
        _delarProductSearchDto.companyIds = _filterCompanies.map((company: Company) => {
            return company.id
        })
        _delarProductSearchDtoSubject.next(_delarProductSearchDto)
        _filterCompaniesSubject.next([..._filterCompanies])
    },
    getFilterCompanies: (): Array<ShopCompany> | null => {
        return _filterCompanies
    },
    getFilterCompaniesObservable: (): Observable<Array<ShopCompany> | null> => {
        return _filterCompaniesSubject.asObservable()
    }
}