import { Session } from "./model/session.model"

const Android = (window as any).Android
// const Android: any = null

export const AndroidConnerctor = {
    getSession: (): string => {
        console.log("Android Connector", "getSession")
        if (Android) {
            try {
                return JSON.stringify(Android.getSession())
            } catch {
                return "{}"
            }
        }
        return "{}"
    },
    getLanguage: (): string => {
        console.log("Android Connector", "getLanguage")
        if (Android) {
            return Android.getLanguage()
        }
        return ""
    },
    setSession: (session: Session) => {
        console.log("Android Connector", "setSession")
        if (Android) {
            Android.setSession(JSON.stringify(session))
        }
    },
    setLanguage: (lang: string) => {
        console.log("Android Connector", "setLanguage")
        if (Android) {
            Android.setLanguage(lang)
        }
    },
    updateStatus: (status: Number) => {
        console.log("Android Connector", "updateStatus")
        if (Android) {
            Android.updateStatus(status)
        }
    },
    removeSession: () => {
        console.log("Android Connector", "removeSession")
        if (Android) {
            Android.logOut()
        }
    },


    //For support, rating and share
    shareDukaan: (msg: string) => {
        console.log("Android Connector", "shareDukaan")
        if (Android) {
            Android.shareDukaan(msg)
        }
    },
    recommendedToFriends: (msg: string) => {
        console.log("Android Connector", "recommendedToFriends")
        if (Android) {
            Android.recommendedToFriends(msg)
        }
    },
    openSupportDialog: (phone: string) => {
        console.log("Android Connector", "openSupportDialog")
        if (Android) {
            Android.openSupportDialog(phone)
        }
    },
    rateKhetiPoint: () => {
        console.log("Android Connector", "rateKhetiPoint")
        if (Android) {
            Android.rateKhetiPoint()
        }
    }
}