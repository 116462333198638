import { AppBar, Avatar, BottomNavigation, Button, Checkbox, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch, TextField, Toolbar, Typography } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './pack-size.style'
import { Media } from "../../../../model/media.model"
import { ProductPack } from "../../../../model/product-pack.model"
import { AddProductActor } from "../../../../actor/product/add-product.actor"
import { AddProductAction } from "../../../../action/product/add-product.action"
import { useNavigate, useParams } from "react-router-dom"
import { ProductAction } from "../../../../action/product/product.action"
import { ProductActor } from "../../../../actor/product/product.actor"
import { ProductStore } from "../../../../store/product/product.store"
import { useObservable } from "../../../../useSubscriber"
import { DelarProductInfoRes } from "../../../../service/response/product.response"
import { DelarProductPackDto } from "../../../../service/dto/product.dto"
import { useTranslation } from "react-i18next"
import { AndroidConnerctor } from "../../../../android.connecter"
import { toast } from "react-toastify"

export const ProductPackSizeComponent = () => {

    const { productId } = useParams()

    const classes = useStyles();
    const { t } = useTranslation()
    const _productInfo = useRef<DelarProductInfoRes>()

    const [productInfo, setProductInfo] = useState({ ..._productInfo.current })

    const [newProductInfo, _] = useObservable(ProductStore.getDelarProductInfo(), ProductStore.getDelarProductInfoObservable())

    const navigate = useNavigate()
    useEffect(() => {
        ProductActor({ "type": ProductAction.GET_PRODUCT_INFO }, { "delarProductID": productId })
    }, [])

    useEffect(() => {
        if (newProductInfo) {
            _productInfo.current = newProductInfo
            setProductInfo({ ...newProductInfo })
        }
    }, [newProductInfo])

    const handleEnable = (e: any, newPack: ProductPack) => {
        _productInfo.current.packs.map(pack => {
            if (pack.size == newPack.size && pack.unit == newPack.unit) {
                pack.enabled = e.target.checked
                if (pack.enabled) {
                    pack.inStock = true
                } else {
                    pack.inStock = false
                }
            }
        })
        setProductInfo({ ..._productInfo.current })
    }

    const handleInStock = (e: any, newPack: ProductPack) => {
        _productInfo.current.packs.map(pack => {
            if (pack.size == newPack.size && pack.unit == newPack.unit) {
                pack.inStock = e.target.checked
            }
        })
        setProductInfo({ ..._productInfo.current })
    }

    const handleSellingPrice = (e: any, newPack: ProductPack) => {
        _productInfo.current.packs.map(pack => {
            if (pack.size == newPack.size && pack.unit == newPack.unit) {
                pack.sellingPrice = parseInt(e.target.value) * 100
            }
        })
        setProductInfo({ ..._productInfo.current })
    }

    const afterPackUpdate = () => {
        navigate(-1)
    }

    const handleUpdate = () => {
        if (!_productInfo.current.packs) {
            navigate(-2)
            return
        }
        let isError = false
        _productInfo.current.packs.forEach(pack => {
            if (pack.enabled && pack.sellingPrice <= 100) {
                isError = true
            }
        })

        if (isError) {
            toast('Selling Price should be valid')
            return
        }

        const delarPackDto = {
            delarId: _productInfo.current.delarId,
            packs: _productInfo.current.packs,
            productId: _productInfo.current.productId,
            active: true
        } as DelarProductPackDto
        AddProductActor({ "type": AddProductAction.UPDATE_PACK_DATA }, { "delarPackDto": delarPackDto, "afterPackUpdate": afterPackUpdate })
    };

    return (
        <>
            {productInfo && productInfo?.id?.length > 0 && (
                <div className={classes.root}>
                    {productInfo && productInfo.name && (

                        <AppBar position="sticky" style={{ boxShadow: 'none' }}>
                            <Toolbar>
                                <ArrowBackIcon onClick={afterPackUpdate} className={classes.backIcon} />
                                <Typography variant="h6" className={classes.title}>
                                    {productInfo.name}
                                </Typography>
                            </Toolbar>
                        </AppBar>

                    )}
                    <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 0 }}>
                        <ListItem>
                            {productInfo && productInfo.photos && (
                                <ListItemAvatar >
                                    <Avatar
                                        alt={`Avatar`}
                                        src={new Map(Object.entries((new Map(Object.entries(productInfo.photos)).values().next().value as Media).urls)).get('main') as string}
                                        style={{ width: 60, height: 60, }}
                                    />
                                </ListItemAvatar>
                            )}
                            {productInfo && productInfo.name && productInfo.company && productInfo.company.name && (
                                <ListItemText disableTypography primary={<Typography variant="subtitle1" className={classes.productName}>
                                    <b>{productInfo.name}</b>
                                </Typography>}
                                    secondary={<Typography variant="subtitle1" className={classes.companyName}>
                                        {productInfo.company.name}
                                    </Typography>} />
                            )}
                        </ListItem>
                    </List>
                    <Divider />
                    <Typography variant="body2" className={classes.infoText1}>
                        {t("pack_size_select_selling_price")}
                    </Typography>
                    <Divider />
                    <Grid container spacing={0} >
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" className={classes.packSizeText}>
                                {t("product_pack_size")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" className={classes.sellingPriceText}>
                                {t("pack_size_your_selling_price")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    {productInfo && productInfo.packs && productInfo.packs.length > 0 && (
                        <List dense style={{ width: '100%', backgroundColor: 'background.paper', paddingTop: 0 }}>

                            {productInfo.packs.map((pack, index) => {
                                const labelId = "" + pack.unit;
                                let si: string
                                let cgs: string
                                if (pack.unit === 1) {
                                    si = "kg"
                                    cgs = "gm"
                                }
                                else if (pack.unit === 2) {
                                    si = "L"
                                    cgs = "ml"
                                }
                                else {
                                    si = "unit"
                                    cgs = "unit"
                                }
                                return (
                                    <ListItem key={index} style={{ paddingRight: "0px", alignItems: "start" }} className={(index % 2 === 0) ? classes.evenItem : classes.oddItem}>
                                        <Checkbox
                                            onChange={(e: any) => handleEnable(e, pack)}
                                            checked={pack.enabled}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            color="primary"
                                            style={{ marginLeft: -10 }}
                                        />
                                        {pack.enabled && (
                                            <div style={{ height: "auto", width: '100%' }}>
                                                <ListItem key={index} style={{ height: 54, width: 'auto', paddingTop: 8, paddingBottom: 8 }}>
                                                    <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.listItemText}>
                                                        <b style={{ fontSize: 16 }}>{pack.size >= 1000 ? (pack.size) / 1000 : (pack.size)} {pack.size >= 1000 ? si : cgs}</b> MRP {pack.mrp / 100}
                                                    </Typography>} />
                                                    <ListItemSecondaryAction style={{ padding: 2, }}>
                                                        {pack.enabled && (
                                                            <TextField defaultValue={pack.sellingPrice / 100} id={"" + index} variant="outlined" InputProps={{
                                                                style: { height: 34, width: 91, backgroundColor: '#ffffff' },
                                                                startAdornment: <InputAdornment position="start" style={{ fontSize: 2, backgroundColor: 'none' }}>₹</InputAdornment>,
                                                            }} onChange={(e: any) => handleSellingPrice(e, pack)} className={classes.textField} />
                                                        )}
                                                        {!pack.enabled && (
                                                            <TextField defaultValue={pack.sellingPrice / 100} id={"" + index} variant="outlined" InputProps={{
                                                                style: { height: 34, width: 91 },
                                                                startAdornment: <InputAdornment position="start" style={{ fontSize: 2, backgroundColor: 'none' }}>₹</InputAdornment>,
                                                            }} onChange={(e: any) => handleSellingPrice(e, pack)} disabled className={classes.textField} />
                                                        )}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem key={"a" + index} style={{ height: 54 }}>
                                                    <ListItemSecondaryAction style={{ "display": "flex", "alignItems": "center" }}>
                                                        {pack.inStock && (
                                                            <Typography variant="subtitle1" className={classes.stockText1}>
                                                                {t("in_stock")}
                                                            </Typography>
                                                        )}
                                                        {!pack.inStock && (
                                                            <Typography variant="subtitle1" className={classes.stockText2}>
                                                                {t("out_of_stock")}
                                                            </Typography>
                                                        )}
                                                        <Switch
                                                            edge="end"
                                                            onChange={(e: any) => handleInStock(e, pack)}
                                                            checked={pack.inStock}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            color="primary"
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </div>
                                        )}

                                        {!pack.enabled && (
                                            <div style={{ height: "auto", width: '100%' }}>
                                                <ListItem key={index} style={{ height: 54, width: 'auto', paddingTop: 8, paddingBottom: 8 }}>
                                                    <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.listItemTextDisabled}>
                                                        <b style={{ fontSize: 16 }}>{pack.size >= 1000 ? (pack.size) / 1000 : (pack.size)} {pack.size >= 1000 ? si : cgs}</b> MRP {pack.mrp / 100}
                                                    </Typography>} />
                                                    <ListItemSecondaryAction style={{ padding: 2, }}>
                                                        <TextField defaultValue={pack.sellingPrice / 100} id={"" + index} variant="outlined" InputProps={{
                                                            style: { height: 34, width: 91 },
                                                            startAdornment: <InputAdornment position="start" style={{ fontSize: 2, backgroundColor: 'none' }}>₹</InputAdornment>,
                                                        }} onChange={(e: any) => handleSellingPrice(e, pack)} disabled className={classes.textField} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem key={"a" + index} style={{ height: 54 }}>
                                                    <ListItemSecondaryAction style={{ "display": "flex", "alignItems": "center" }}>
                                                        {pack.inStock && (
                                                            <Typography variant="subtitle1" className={classes.stockText}>
                                                                {t("in_stock")}
                                                            </Typography>
                                                        )}
                                                        {!pack.inStock && (
                                                            <Typography variant="subtitle1" className={classes.stockText}>
                                                                {t("out_of_stock")}
                                                            </Typography>
                                                        )}
                                                        <Switch
                                                            disabled={true}
                                                            edge="end"
                                                            onChange={(e: any) => handleInStock(e, pack)}
                                                            checked={pack.inStock}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            color="primary"
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </div>
                                        )}

                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    <div className={classes.bottomTextButton}>
                        <Typography variant="body2" className={classes.bottomText}>
                            {t("pack_size_assistance_description")}
                        </Typography>
                        <Button variant="contained" color="primary" className={classes.supportButton}
                            onClick={() => AndroidConnerctor.openSupportDialog("7755058011")}>
                            <img style={{ width: 16, height: 16, marginRight: 8, }} src='/asset/support icon.svg' />
                            <Typography style={{ opacity: 0.8, fontSize: 12 }}>
                                {t("support")}
                            </Typography>
                        </Button>
                    </div>
                    <BottomNavigation showLabels className={classes.bottomNavigation}>
                        <Button className={classes.nextButton} variant="contained" color="primary" onClick={handleUpdate} >
                            {t("pack_size_save_product")}
                        </Button>
                    </BottomNavigation>
                </div>
            )}
        </>
    )
}
