import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 720,
            margin: 'auto'
        },
        titleEdit: {
            marginTop: theme.spacing(4),
            fontSize: '1.7142857142857142em',
            fontWeight: 'bold',
        },
        descEdit: {
            marginTop: 8,
            fontSize: '0.8571428571428571em',
        },
        container: {
            margin: 'auto',
            height: 320,
            marginTop: 300,
            padding: 16
        },
        img: {
            height: '50%',
            margin: 'auto',
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        dot: {
            width: 5,
            height: 5,
            marginTop: 1,
        },
        dotActive: {
            width: 8,
            height: 8,
            marginTop: 0,
        },
        positionStatic: {
            position: "fixed",
            bottom: 0,
            width: '100%',
            backgroundColor: '#ffffff',
            maxWidth: 720,
            flexGrow: 1
        },
        nextButton: {
            align: 'center',
            width: 154,
            height: 48,
            fontSize: '1em',
            textTransform: 'capitalize',
            backgroundColor: '#0E4BB4',
            boxShadow: 'none',
            marginRight: 16
        },
        backButton: {
            width: 154,
            height: 48,
            fontSize: '1em',
            textTransform: 'capitalize',
            color: '#0E4BB4',
            fontWeight: 700,
            marginLeft: 16
        },
        dot2: {
            display: 'none'
        },
        dotActive2: {
            display: 'none'
        },
    }),
);