import { Grid, IconButton, Typography } from "@material-ui/core"
import React, {  } from "react"
import Button from '@material-ui/core/Button';
import { useStyles } from "./language-selection.style"
import { Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRouteManager } from "../../../router/route.manager";
import { RegistrationAction } from "../../../action/registration.action";
import { RegistrationActor } from "../../../actor/registration.actor";
import Radio from '@material-ui/core/Radio';
import { LangCode } from "../../../enum/lang-code.enum";

export const LanguageSelectionComponent = () => {

        const { t } = useTranslation();
        const routeManager = useRouteManager();

        const classes = useStyles();
        const [selectedValue, setSelectedValue] = React.useState('english');
        const handleChange = (event: any) => {
                setSelectedValue(event.target.value);
              };
        const updateLanguage = (langCode: string) => {
                RegistrationActor({ "type": RegistrationAction.SET_LANGUAGE }, { "langCode": langCode })
                routeManager.openRegister()
        }

        return (
                <React.Fragment>
                        <Grid container direction="column" alignItems="center" justify="center">
                                <Avatar alt="logof" src={'../asset/logof.png'} className={classes.kpLogo} />
                                <Typography variant="h6" className={classes.langTitle1}>
                                        {t("select_your_language")}
                                </Typography>
                                <Typography variant="h6" className={classes.langTitle2}>
                                        अपनी भाषा चुनिए
                                </Typography>
                        <Button variant="outlined" className={classes.engButton} color="primary"
                                        onClick={() => {setSelectedValue('english');updateLanguage(LangCode.English)}}>
                                                
                                                {/*Radio
                                                checked={false}
                                                value="english"
                                                style = {{position: 'absolute', left: 10, color: '#0E4BB4' }}
                                                classes = {{
                                                        root: classes.radioRoot
                                                }}
                                                className ={classes.radioButton}
                                                */}
                                
                                        English
                                        
                        </Button>
                        <Button variant="outlined" className={classes.hinButton} color="primary"
                                        onClick={() => {setSelectedValue('hindi');updateLanguage(LangCode.Hindi)}}>
                                                {/*Radio
                                                checked={false}
                                                value="hindi"
                                                className ={classes.radioButton}
                                                classes = {{
                                                        root: classes.radioRoot
                                                }}
                                                style = {{position: 'absolute', left: 10, }}
                                                
                                                */}
                                        हिन्दी
                        </Button>
                        <Button variant="outlined" className={classes.gujButton} color="primary"
                                        onClick={() => {setSelectedValue('gujrati');updateLanguage(LangCode.Gujurati)}}>
                                                
                                                {/*Radio
                                                color="default"
                                                checked={false}                                     
                                                value="gujrati"
                                                style = {{position: 'absolute', left: 10}}
                                                classes = {{
                                                        root: classes.radioRoot
                                                }}
                                                className ={classes.radioButton}
                                                */}
                                        ગુજરાતી
                        </Button>
                        </Grid>
                </React.Fragment>
        )
}