import { ProductAction } from "../../action/product/product.action";
import { ProductService } from "../../service/product.service";
import { ShopCompany } from "../../service/response/shop-company.response";
import { ProductStore } from "../../store/product/product.store";

const getDelarProduct = (afterDataFetch: any, setHasMore?: any) => {
    ProductService.getDelarProductList(ProductStore.getDelarProductSearchDto()).then((res) => {
        ProductStore.upadteDelarProductList(res.data.data)
        ProductStore.upadteDelarProductSearchDto(res.data.nextApiReq)
        afterDataFetch()
        if (setHasMore) {
            if (res.data.data.length < ProductStore.getDelarProductSearchDto().limit) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

const updateSearchText = (searchText: string, afterDataFetch: any) => {
    initSearchDtoAndProductList()
    ProductStore.updateSearchText(searchText)
    getDelarProduct(afterDataFetch)
}

const updateCompany = (companies: Array<ShopCompany>) => {
    ProductStore.upadteFilterCompaniesList(companies)
}

const removeFilterCompany = (company: ShopCompany) => {
    ProductStore.removeFilterCompany(company)
}

const updateAvailability = (available: number) => {
    ProductStore.updateAvailability(available)
}
const openDelarProductInfo = (delarProductID: string) => {
    ProductStore.resetDelarProductInfo()
    ProductService.getDelarProductInfoById(delarProductID).then((res) => {
        ProductStore.upadteDelarProductInfo(res.data)
    }).catch(err => {
        console.log(err)
    })
}

const initSearchDtoAndProductList = () => {
    ProductStore.reSetSearchDtoAndProductList()
}

export const ProductActor = (action: any, data: any) => {
    switch (action.type) {
        case ProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST:
            initSearchDtoAndProductList()
            break
        case ProductAction.GET_LIST:
            getDelarProduct(data.afterDataFetch, data.setHasMore)
            break
        case ProductAction.UPDATE_SEARCH_TEXT:
            updateSearchText(data.searchText, data.afterDataFetch)
            break
        case ProductAction.GET_PRODUCT_INFO:
            openDelarProductInfo(data.delarProductID)
            break
        case ProductAction.UPDATE_FILTER_COMPANY:
            updateCompany(data.companies)
            break
        case ProductAction.UPDATE_AVAILABILITY:
            updateAvailability(data.avability)
            break
        case ProductAction.REMOVE_FILTER_COMPANY:
            removeFilterCompany(data.company)
            break
        default:
            break
    }
}


