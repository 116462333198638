import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginBottom: 84,
            height: '100%',
            backgroundColor: '#f5f5f5'
        },
        img: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            height: 200,
            maxWidth: "100%",
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        title1: {    
            color: '#fff',
            position:'absolute',
            bottom:4, 
            left:16,
        },
        cardEdit1: {
            align: 'stretch',
            marginLeft:8,
            marginRight:8,
            marginTop: 8,
            fontSize: 14,
            boxShadow: 'none',
        },
        title2: {
            marginTop: -8,
            marginLeft: -8,
            
            color: "#2A312F",
            
            font: "normal normal medium 14px/18px Montserrat",
            fontSize: 14,
            fontWeight: 700,
        },
        title3: {
            
            marginLeft: -8,
            marginTop: 4,
            maxLines: 4,
            fontSize: 12,
            color: '#2A312F',
            letterSpacing: 0,
        },
        shareDiv: {
            display:"flex",
            marginTop: -12, 
            marginLeft: -16,
            marginBottom: -16,
        },
        icon: {
            marginLeft: 7,
            marginTop: 13,
            width: 20,
            height: 20,
            verticalAlign: 'middle',
        },
        title4: {
            maxWidth: 129,
            marginTop: 15,
            marginLeft: 6,
            marginRight: 'auto',
            fontSize: 12,
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        shareButton: {
            width:154, 
            height: 44,
            marginLeft:32, 
            marginRight: -8,
            borderRadius: 30,
            backgroundColor: '#0E4BB4',
            textTransform: 'capitalize',
            fontSize:12,
            boxShadow: 'none'
        },
        cardEdit2: {
            align: 'stretch',
            borderRadius: 8,
            marginLeft:8,
            marginRight:8,
            marginTop: 8,
            boxShadow: 'none'
        },
        productCardTitle: {
            marginTop: -6,
            marginLeft: -8,
            marginBottom:8,
            marginRight: 'auto',
            fontSize: 14,
            fontWeight: 'bold',
        },
        title5: {
            marginTop: -8,
            marginLeft: -8,
            marginRight: 'auto',
            fontSize: 14,
            fontWeight: 'bold',
        },
        title5_1: {
            marginTop: -8,
            marginLeft: -8,
            fontSize: 12,
            color: '#2A312F',
            fontWeight: 500,
        },
        cardEdit3: {
            width: "50%",
            marginLeft:8,
            marginTop: 5,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 3%), 0px 1px 3px 2px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 2%)',
        },
        title6: {
            flexGrow: 2,
            fontSize:24,
            opacity: 0.8,
            marginLeft: -8,
            marginBottom: -10,
            marginTop: 4,
        },
        cardEdit4: {
            width: "45%",
            marginLeft:8,
            marginTop: 5,
            boxShadow: 'none',
        },
        cardEdit5: {
            align: 'stretch',
            borderRadius: 8,
            marginLeft:0,
            marginRight:0,
            marginTop: 12,
            boxShadow: 'none',
        },
        evenItem: {
            width: '100%',
            backgroundColor: '#F3F3F3'
        },
        oddItem: {
            width: '100%',
        },
        villageName: {
            fontSize: 14,
            marginLeft: -8,
        },
        villageCardTitle: {
            marginTop: -8,
            marginLeft: -8,
            marginBottom:0,
            marginRight: 'auto',
            fontSize: 14,
            color: '#000000',
            fontWeight: 700,
        },
        expandItem: {
            width: '100%',
            backgroundColor: '#DCDCDC',
        },
        expandText: {
            fontSize: 14,
            marginLeft: -8,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            marginRight:8,
            transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            }),
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            opacity: 0.5,
        },
        zeroImageCard: {
            width: '100%',
            height: 182,
            background: 'transparent linear-gradient(180deg, #00000000 0%, #0000004D 100%) 0% 0% no-repeat padding-box'
        },
        imgCard: {
            height: 70,
            width: 200,
            margin: 'auto',
            display: 'block',
            paddingTop: 20
        },
        textThumb: {
            width: 224,
            height: 'auto',
            fontSize: 12,
            color: '#2A312F',
            fontWeight: 500,
            margin: 'auto'
        }
    }),
);