import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom:10,
            maxWidth: 720,
            margin: 'auto'
        },
        backIcon: {
            marginTop: theme.spacing(2),
            marginLeft: 14,
            fontSize: 30,
            
        },
        mainContent: {
            marginTop: theme.spacing(24),
            marginBottom: theme.spacing(36),
        },
        title1: {
            flexGrow: 1,
            align: "left",
            letterSpacing: 0,
            marginLeft: theme.spacing(2),
            fontSize: '1.7142857142857142em',
            fontWeight: 'bold',
        },
        title2: {
            flexGrow: 1,
            align: "left",
            opacity: 0.75,
            letterSpacing: 0.24,
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            fontSize: '0.8571428571428571em',
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: '0.7857142857142857em',
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        numberMargin: {
            width: '90%!important',
            height: '48px!important',
            margin: '16px!important',
        },
        nextButton: {
            width: "90%",
            margin: "auto",
            display: "flex",
            height: 48,
            textTransform: 'capitalize',
            boxShadow: 'none',
            backgroundColor: '#0e4bb4'
        },
        adornedStart: {
            height: '100%',
            background: '#E0E0E0!important',
        },
        input: {
            background: '#FFF!important',
            marginLeft: "10px!important",
            paddingLeft: "10px!important",
            fontSize: '0.7857142857142857em!important',
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            opacity: 0.5,
        },
        positionStart: {
            marginLeft: "50%"
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            maxWidth: 720,
            margin: 'auto',
            height: 79,
            position: 'fixed',
            bottom: 0
        },
    }),
);