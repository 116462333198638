import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
        },
        subRoot: {
            flexGrow: 1,
            width: "100%",
            marginTop: 12,
        },
        subRoot1: {
            flexGrow: 1,
            width: "100%",
            marginTop: 215,
        },
        icon: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            marginRight: 16,
            marginTop: 16,
            width: 15,
            height: 15,
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            fontSize: 24,
        },
        title1: {
            align: "left",
            letterSpacing: 0,
           
            marginLeft: theme.spacing(2),
            display: "flex",
            fontSize: 14,
        },
        numberContainer: {
            width: 20,
            height: 20,
            border: '1px solid #4b514f',
            borderRadius: 20,

            fontSize: 11,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        requestButton:{
            width: '95%',
            alignSelf: 'center',
            justifySelf: 'center',
            padding: 10,
            textTransform: 'capitalize',
            
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    }),
);