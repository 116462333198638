export const EditProfileAction = {
    UPDATE_NAME: "update_name",
    UPDATE_ALTERNATE_PHONE: "update_alternate_phone",
    UPDATE_INTRO: "update_intro",
    UPDATE_SHOP_NAME: "update_shop_name",
    UPDATE_PESTICIDE_LICENSE: "update_pesticide_license",
    UPDATE_FERTILIZER_LICENSE: "update_fertilizer_license",
    UPDATE_SEED_LICENSE: "update_seed_license",
    UPDATE_STREET_ADDRESS: "update_street_address",
    UPDATE_LANDMARK: "update_landmark",
    UPDATE_STATE: "update_state",
    UPDATE_DISTRICT: "update_district",
    UPDATE_SUB_DISTRICT: "update_sub_district",
    UPDATE_VILLAGE: "update_village",
    UPDATE_PINCODE: "update_pincode",
    UPDATE_DATA: "update_data",
    RE_INIT_DELAR: "re_init_delar",
    DELETE_SHOP_PHOTO: "delete_shop_photo",
    DELETE_PESTICIDE_PHOTO: "delete_pesticide_photo",
    DELETE_FERTILIZER_PHOTO: "delete_fertilizer_photo",
    DELETE_SEED_PHOTO: "delete_seed_photo",
    UPLOAD_NEW_SHOP_IMAGES: "upload_new_shop_images",
    UPLOAD_NEW_PESTICIDE_IMAGES: "upload_new_pesticide_images",
    UPLOAD_NEW_FERTILIZER_IMAGES: "upload_new_fertilizer_images",
    UPLOAD_NEW_SEED_IMAGES: "upload_new_seed_images",
    UPDATE_PESTICIDE_IMAGES_ERROR: "update_pesticide_photos_error",
    UPDATE_FERTILIZER_IMAGES_ERROR: "update_fertilizer_photos_error",
    UPDATE_SEED_IMAGES_ERROR: "update_seed_photos_error",
    UPDATE_SHOP_IMAGES_ERROR: "update_shop_images_error",
    VALIDATE_PESTICIDE_LICENCE_ERROR: "validate_pesticide_licence_error",
    VALIDATE_FERTILIZER_LICENCE_ERROR: "validate_fertilizer_licence_error",
    VALIDATE_SEED_LICENCE_ERROR: "validate_seed_licence_error"
}