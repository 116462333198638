import Axios, { AxiosResponse } from "axios";


import { Company } from "./response/company.response";
import { Category } from "./response/category.response";
import { ShopCompany, ShopCompanyRes } from "./response/shop-company.response";
import { ShopCompanyDto } from "./dto/shop-company.dto";
export const FilterService = {
    getCompany: (): Promise<AxiosResponse<Array<Company>>> => {
        return Axios.get<Array<Company>>(`/company`)
    },
    getCropCategory: (): Promise<AxiosResponse<Array<Category>>> => {
        return Axios.get<Array<Category>>(`/crop-category`)
    },
    getShopCompany: (shopCompanyDto: ShopCompanyDto): Promise<AxiosResponse<Array<ShopCompany>>> => {
        return Axios.post<any>(`/shop/company`, shopCompanyDto)
    }
}