import { BehaviorSubject, Observable } from "rxjs"
import { PRODUCT_SEARCH_PAGE_SIZE } from "../../constant/base.constant"
import { SecurityManager } from "../../security/security.manager"
import { ProductSearchDto } from "../../service/dto/product.dto"
import { Company } from "../../service/response/company.response"
import { DelarProduct, DelarProductSearchResult } from "../../service/response/product.response"
import { ShopCompany } from "../../service/response/shop-company.response"

let _productList: Array<DelarProductSearchResult> = []
let _productListSubject = new BehaviorSubject<Array<DelarProductSearchResult> | null>(null)

let _productSearchDto: ProductSearchDto = { userId: SecurityManager.getUid(), limit: PRODUCT_SEARCH_PAGE_SIZE, orderType: 1 } as ProductSearchDto
let _productSearchDtoSubject = new BehaviorSubject<ProductSearchDto | null>(null)

let _selectedProductList: Array<DelarProductSearchResult> = []
let _selectedProductListSubject = new BehaviorSubject<Array<DelarProductSearchResult> | null>(null)

let _addedProductList: Array<DelarProductSearchResult> = []
let _addedProductListSubject = new BehaviorSubject<Array<DelarProductSearchResult> | null>(null)

let _filterCompanies: Array<ShopCompany> = []
let _filterCompaniesSubject = new BehaviorSubject<Array<ShopCompany> | null>(null)

export const AddProductStore = {
    reSetSearchDtoAndProductList: () => {
        _productList = []
        _productListSubject.next([..._productList])

        _selectedProductList = []
        _selectedProductListSubject.next([..._selectedProductList])

        _addedProductList = []
        _addedProductListSubject.next([..._addedProductList])

        _filterCompanies = []
        _filterCompaniesSubject.next([..._filterCompanies])

        _productSearchDto.pageNumber = 0
        _productSearchDto.companyIds = []
        _productSearchDto.userId = SecurityManager.getUid()
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },

    reSetProductList: () => {
        _productList = []
        _productListSubject.next([..._productList])

        _productSearchDto.pageNumber = 0
        _productSearchDto.userId = SecurityManager.getUid()
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },

    //all product list
    upadteProductList: (newProductList: Array<DelarProductSearchResult>) => {
        _productList = [..._productList, ...newProductList]
        _productListSubject.next(_productList)
    },
    getProductList: (): Array<DelarProductSearchResult> | null => {
        return _productList
    },
    getProductListObservable: (): Observable<Array<DelarProductSearchResult> | null> => {
        return _productListSubject.asObservable()
    },

    // newly selected product
    addSelectedProduct: (delarProductSearchResult: DelarProductSearchResult) => {
        if (_selectedProductList.indexOf(delarProductSearchResult) != -1) {
            return
        }
        _selectedProductList.push(delarProductSearchResult)

        const selectedProductIds = _selectedProductList.map(product => {
            return product.id
        })
        _productList = _productList.map(product => {
            let newState = false
            if(selectedProductIds.indexOf(product.id) != -1) {
                newState = true
            } else {
                newState = false
            }
            if(product.itemSelected == newState) {
                return product
            } else {
                product.itemSelected = newState
                return {...product}
            }
        })
        _productListSubject.next(_productList)

        _selectedProductListSubject.next([..._selectedProductList])
    },
    deSelectedProduct: (delarProductSearchResult: DelarProductSearchResult) => {
        _selectedProductList = _selectedProductList.filter(selectedProduct => {
            return selectedProduct.id != delarProductSearchResult.id
        })

        const selectedProductIds = _selectedProductList.map(product => {
            return product.id
        })
        _productList = _productList.map(product => {
            let newState = false
            if(selectedProductIds.indexOf(product.id) != -1) {
                newState = true
            } else {
                newState = false
            }
            if(product.itemSelected == newState) {
                return product
            } else {
                product.itemSelected = newState
                return {...product}
            }
        })
        _productListSubject.next(_productList)

        _selectedProductListSubject.next([..._selectedProductList])
    },
    updateSelectProduct: (delarProductSearchResult: DelarProductSearchResult) => {
        if (_selectedProductList.indexOf(delarProductSearchResult) != -1) {
            _selectedProductList = _selectedProductList.filter(selectedProduct => {
                return selectedProduct.id != delarProductSearchResult.id
            })
            _selectedProductListSubject.next([..._selectedProductList])
        } else {
            _selectedProductList.push(delarProductSearchResult)
            _selectedProductListSubject.next([..._selectedProductList])
        }

        const selectedProductIds = _selectedProductList.map(product => {
            return product.id
        })
        _productList = _productList.map(product => {
            let newState = false
            if(selectedProductIds.indexOf(product.id) != -1) {
                newState = true
            } else {
                newState = false
            }
            if(product.itemSelected == newState) {
                return product
            } else {
                product.itemSelected = newState
                return {...product}
            }
        })
        _productListSubject.next(_productList)
    },
    getSelectedProductList: (): Array<DelarProductSearchResult> | null => {
        return _selectedProductList
    },
    getSelectedProductListObservable: (): Observable<Array<DelarProductSearchResult> | null> => {
        return _selectedProductListSubject.asObservable()
    },

    //product search dto
    upadteProductSearchDto: (newProductSearchDto: ProductSearchDto) => {
        _productSearchDto = newProductSearchDto
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },
    updateSearchText: (searchText: string) => {
        const selectedCompanyIds = _productSearchDto.companyIds
        _productSearchDto = { limit: PRODUCT_SEARCH_PAGE_SIZE, companyIds: selectedCompanyIds } as ProductSearchDto
        _productSearchDto.userId = SecurityManager.getUid()
        _productSearchDto.searchText = searchText
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },
    updateCompanies: (selectedCompanyIds: Array<string>) => {
        _productSearchDto = { limit: PRODUCT_SEARCH_PAGE_SIZE } as ProductSearchDto
        _productSearchDto.companyIds = selectedCompanyIds
        _productSearchDto.userId = SecurityManager.getUid()
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },
    getProductSearchDto: (): ProductSearchDto => {
        return _productSearchDto
    },
    getProductSearchDtoObservable: (): Observable<ProductSearchDto | null> => {
        return _productSearchDtoSubject.asObservable()
    },

    //for filter companies
    upadteFilterCompaniesList: (newCompanies: Array<ShopCompany>) => {
        _filterCompanies = newCompanies
        _productSearchDto.companyIds = _filterCompanies.map((company: Company) => {
            return company.id
        })
        _productSearchDtoSubject.next({ ..._productSearchDto })
        _filterCompaniesSubject.next([..._filterCompanies])
    },
    removeFilterCompany: (_company: ShopCompany) => {
        _filterCompanies = _filterCompanies.filter(company => {
            return company.id != _company.id
        })
        _productSearchDto.companyIds = _filterCompanies.map((company: Company) => {
            return company.id
        })
        _productSearchDtoSubject.next({ ..._productSearchDto })
        _filterCompaniesSubject.next([..._filterCompanies])
    },
    getFilterCompanies: (): Array<ShopCompany> | null => {
        return _filterCompanies
    },
    getFilterCompaniesObservable: (): Observable<Array<ShopCompany> | null> => {
        return _filterCompaniesSubject.asObservable()
    },

    //newly added product
    upadteAddedProductList: (newDelarProductList: Array<DelarProductSearchResult>) => {
        _addedProductList = newDelarProductList

        _addedProductListSubject.next([..._addedProductList])
    },
    getAddedProductList: (): Array<DelarProductSearchResult> | null => {
        return _addedProductList
    },
    getAddedProductListObservable: (): Observable<Array<DelarProductSearchResult> | null> => {
        return _addedProductListSubject.asObservable()
    }
}