import './App.css';
import { useHttpIntercepter } from './di/http.intercepter';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import SecureRoute from './security/secure.route';
import { MainComponent } from './ui/main/main.componet';
import { LanguageSelectionComponent } from './ui/on-boarding/language-selection/language-selection.component';
import { RegisterComponent } from './ui/on-boarding/register/register.component';
import { ValidateOtpComponent } from './ui/on-boarding/validate-otp/validate-otp.component';
import { IntroComponent } from './ui/on-boarding/intro/intro.component';
import { EditDetailComponent } from './ui/on-boarding/edit-detail/edit-detail.component';
import { ProductComponent } from './ui/main/product/product.component';
import { ProfileComponent } from './ui/main/profile/profile.component';
import { SettingComponent } from './ui/common/setting/setting.component';
import { AboutUsComponent } from './ui/common/about-us/about-us.component';
import { TermsPoliciesComponent } from './ui/common/terms-policies/terms-policies.component';
import { NotificationComponent } from './ui/common/notification/notification.component';
import { DelarDetailComponent } from './ui/on-boarding/delar-detail/delar-detail.component';
import { EditProfileComponent } from './ui/main/profile/edit-profile/edit-profile.component';
import { ProductInfoComponent } from './ui/main/product/product-info/product-info.component';
import { EditPesticideLicenseComponent } from './ui/main/profile/edit-profile/edit-license/edit-pesticide-license.component'
import { EditAddressComponent } from './ui/main/profile/edit-profile/edit-address/edit-address.component'
import { EditShopComponent } from './ui/main/profile/edit-profile/edit-shop/edit-shop.component'
import { UploadPictureComponent } from './ui/main/profile/edit-profile/upload-picture/upload-picture.component'
import { RequestProductComponent } from './ui/main/product/new-product/request-product.component'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditDeliverableVillage } from './ui/main/profile/edit-profile/edit-deliverable-village/edit-deliverable-village.component';
import { GalleryPickerComponent } from './ui/Image_Picker/gallery-picker.component'
import { SecurityManager } from './security/security.manager';
import { useFirebaseStorageIntercepter } from './di/upload.service';
import { AddProductComponent } from './ui/main/product/add-product/add-product.compont';
import { MultiProductPackSizeComponent } from './ui/main/product/add-product/multpile-product-selected/multpile-product-selected.component';
import { OnboardAddVillageComponent } from './ui/on-boarding/delar-detail/deliverable-village/deliverable-village-add.component';
import { NotDeliverable } from './ui/on-boarding/delar-detail/deliverable-village/not-deliverable.component';
import { AddVillageComponent } from './ui/main/add-village/add-village.component';
import { ProductPackSizeComponent } from './ui/main/product/pack-size-page/pack-size.component';
import { OrderComponent } from './ui/main/order/order.component';
import { HomeBComponent } from './ui/main/home/homeB/homeB.component';
import { EditFertilizerLicenseComponent } from './ui/main/profile/edit-profile/edit-license/edit-fertilizer-license.component';
import { EditSeedLicenseComponent } from './ui/main/profile/edit-profile/edit-license/edit-seed-license.component';
import { ShipmentComponent } from './ui/main/order/shipment/shipment.component';
import { TaxInvoiceComponent } from './ui/main/billing-views/tax-invoice.component';
import { CreditNoteBComponent } from './ui/main/billing-views/credit-note-B.component';
import { DebitNoteComponent } from './ui/main/billing-views/debit-note.component';
import { CreditNoteComponent } from './ui/main/billing-views/credit-note.component';
import { ReviewPendingComponent } from './ui/main/review-pending/review-pending.component';
import { EditInfoComponent } from './ui/main/profile/edit-profile/edit-info/edit-info.component';
import { useSessionIntercepter } from './di/session.intercepter';
import { ApplicationRejectedComponent } from './ui/on-boarding/application-rejected.component';


function App() {

  const { i18n } = useTranslation();

  useEffect(() => {
    const language = SecurityManager.getLanguage()
    if (language && language.length > 0) {
      i18n.changeLanguage(language)
    } else {
      i18n.changeLanguage("en")
    }
  }, [])

  useHttpIntercepter()
  useFirebaseStorageIntercepter()
  useSessionIntercepter()

  return (
    <div className="App" style={{color: '#2A312F'}}>
      <BrowserRouter>
        <Routes>
          <Route path="/on-board" element={<Outlet />} >
            <Route path="/" element={<LanguageSelectionComponent />} />
            <Route path="/register" element={<RegisterComponent />} />
            <Route path="/validate-otp" element={<ValidateOtpComponent />} />
            
            <Route path="/intro" element={<IntroComponent />} />
            <Route path="/detail" element={<DelarDetailComponent />} />
            <Route path="/edit-detail" element={<EditDetailComponent />} />
            <Route path="/add-village" element={<OnboardAddVillageComponent />} />
            <Route path = "/not-deliverable" element={<NotDeliverable />} />
          </Route>
          <SecureRoute path="/add-village" element={<AddVillageComponent />} />
          <Route path="/review-pending" element={<ReviewPendingComponent/>} />

          <Route path="/verification-rejected" element={<ApplicationRejectedComponent/>} />

          <SecureRoute path="/" element={<MainComponent />} >
          <Route path="" element={<HomeBComponent />} />
            <Route path="/home" element={<HomeBComponent />} />
            <Route path="/product" element={<ProductComponent />} />
            <Route path="/order" element={<OrderComponent />} />
          </SecureRoute>

          <SecureRoute path="/profile" element={<ProfileComponent />} />

          <SecureRoute path="/edit-village" element={<EditDeliverableVillage />} />
          <SecureRoute path="/product/:productId" element={<ProductInfoComponent />} />
          <SecureRoute path="/product/:productId/pack-size" element={<ProductPackSizeComponent />} />
          <SecureRoute path="/product/add" element={<AddProductComponent />} />
          <SecureRoute path="/product/add/multi-pack-size" element={<MultiProductPackSizeComponent />} />
          <SecureRoute path="/product/new-request" element={<RequestProductComponent />} />

          <SecureRoute path="/profile/edit-profile" element={<EditProfileComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-info" element={<EditInfoComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/edit-pesticide-license" element={<EditPesticideLicenseComponent />} />
          <SecureRoute path="/profile/edit-profile/edit-fertilizer-license" element={<EditFertilizerLicenseComponent />} />
          <SecureRoute path="/profile/edit-profile/edit-seed-license" element={<EditSeedLicenseComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/edit-address" element={<EditAddressComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/edit-shop" element={<EditShopComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/upload-picture" element={<UploadPictureComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/product/request-product" element={< RequestProductComponent />} /> {/* added by deborathi*/}          
          

          <SecureRoute path="/shipment/:shipmentId/:shipmentStatus" element={<ShipmentComponent />} />
          <SecureRoute path="/gallery" element={< GalleryPickerComponent />} /> {/* added by deborathi*/}
          
          <SecureRoute path="/shipment/:shipmentId/:shipmentStatus/tax-invoice" element={<TaxInvoiceComponent />} />
          <SecureRoute path="/shipment/:shipmentId/:shipmentStatus/credit-note-b" element={<CreditNoteBComponent />} />
          <SecureRoute path="/shipment/:shipmentId/:shipmentStatus/credit-note" element={<CreditNoteComponent />} />
          <SecureRoute path="/shipment/:shipmentId/:shipmentStatus/debit-note" element={<DebitNoteComponent />} />

          <SecureRoute path="/inventary" element={<Outlet />} >
            <Route path="/" element={<Outlet />} />
            <Route path="/filter" element={<Outlet />} />
          </SecureRoute>

          <SecureRoute path="/profile/:userId" element={<MainComponent />} >
            <Route path="/edit" element={<Outlet />} />
            <Route path="/edit-address" element={<Outlet />} />
            <Route path="/edit-licence" element={<Outlet />} />
            <Route path="/edit-shop-name" element={<Outlet />} />
          </SecureRoute>

          <SecureRoute path="/notification" element={<NotificationComponent />} />

          <SecureRoute path="/settings" element={<SettingComponent />} />

          <SecureRoute path="/terms-policies" element={<TermsPoliciesComponent />} />
          <SecureRoute path="/about-us" element={<AboutUsComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
