import { useEffect } from "react"
import { Session } from "../model/session.model"
import { SecurityManager } from "../security/security.manager"

export const useSessionIntercepter = () => {

    const receiveMessage = (e: any) => {
        if (e.origin !== "https://admin-khetipoint.web.app")
            return

        const session = {
            id: e.data.id,
            name: e.data.name,
            isLoggedIn: e.data.isLoggedIn,
            token: e.data.token,
            phone: e.data.phone,
            verified: e.data.verified
        } as Session

        SecurityManager.setSession(session)

        window.open("https://dealer.khetipoint.com")
    }

    useEffect(() => {

        window.addEventListener('message', receiveMessage);

    }, [])
}