import { Container } from "@material-ui/core"
import React from "react"

export const TermsPoliciesComponent = () => {

    return (
        <React.Fragment>
            <Container maxWidth="sm">
                <h1>Hello, TermsPoliciesComponent!</h1>
            </Container>
        </React.Fragment>
    )
}