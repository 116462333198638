import { Address, OrderAddress } from "../../model/address.model"
import { ShipmentProductStatus } from "./order.response"
import { ProductMini } from "./product.response"

export interface ShipmentDetail { 
    id: string
    address: OrderAddress
    delarId: string
    delarOTP: string
    createdOn: Date
    productStatusGroups: Array<ShipmentProductStatusGroup>
}

export interface ShipmentProductStatusGroup { 
    status: ShipmentProductStatus
    products: Array<DelarOrderProduct>
}

export interface DelarOrderProduct {
    id: string
    productId: string
    farmerId: string
    product: ProductMini
    pack: OrderPack
    orderId: string
    delarProductId: string
    delarId: string
    latestStatus: OrderProductStatus
    createdOn: Date
    active: boolean
}

export interface SelectableDelarOrderProduct {
    delarOrderProduct: DelarOrderProduct,
    accepted: boolean
    rejected: boolean
}

export interface OrderPack {
    size: number
    unit: number
    mrp: number
    sellingPrice: number
    discount: number
    quantity: number
}

export interface OrderProductStatus {
    value: number
    msg: string
    createdOn: Date
    shipmentId: string
}

export const toPackString = (pack: OrderPack): string => {
    let si: string
    let cgs: string
    if (pack.unit === 1) {
        si = "kg"
        cgs = "gm"
    }
    else if (pack.unit === 2) {
        si = "L"
        cgs = "ml"
    }
    else {
        si = "unit"
        cgs = "unit"
    }

    if (pack.size >= 1000) {
        return `${((pack.size) / 1000).toFixed(0)} ${si}`
    } else {
        return `${(pack.size).toFixed(0)} ${cgs}`
    }
}