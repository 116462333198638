import Axios from "axios"
import { toast } from "react-toastify"
import { Invoice } from "../model/invoice.model"
const FileDownload = require('js-file-download')

export const DownloadFile = (shipmentIdList: Array<string>, onComplete: any) => {
    const promises: any = []
    shipmentIdList.map((shipmentId: string) => {
        promises.push(Axios.get<Array<Invoice>>(`/shipment/${shipmentId}/get-invoice-list`,))
    })
    let invoiceList: Array<Invoice> = []
    Promise.all(promises).then((res) => {
        res.map((item: any) => {
            invoiceList = [...invoiceList, ...item.data]
        })

        if (invoiceList.length <= 0) {
            toast("Invoice Not Available")
            return
        }

        invoiceList.map((invoice: Invoice) => {
            Axios.get<any>(`/download-invoice?fileName=${invoice.fileName}`, {
                responseType: 'blob',
            }).then(res => {
                FileDownload(res.data, invoice.fileName)
                toast(`Invoice Downloaded ${invoice.fileName}`)
            })
        })
    }).finally(() => {
        onComplete()
    })
}