import { AddProductAction } from "../../action/product/add-product.action";
import { SecurityManager } from "../../security/security.manager";
import { DelarProductDto, DelarProductPackDto } from "../../service/dto/product.dto";
import { ProductService } from "../../service/product.service";
import { DelarProductSearchResult } from "../../service/response/product.response";
import { ShopCompany } from "../../service/response/shop-company.response";
import { AddProductStore } from "../../store/product/add-product.store";

const searchForProduct = (afterDataFetch: any, setHasMore?: any) => {
    ProductService.getProductList(AddProductStore.getProductSearchDto()).then((res) => {
        res.data.data.forEach(item => {
            item.itemSelected = false
        })
        AddProductStore.upadteProductList(res.data.data)
        AddProductStore.upadteProductSearchDto(res.data.nextApiReq)
        afterDataFetch()
        if(setHasMore) {
            if(res.data.data.length < AddProductStore.getProductSearchDto().limit) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

const selectProduct = (delarProductSearchResult: DelarProductSearchResult) => {
    AddProductStore.addSelectedProduct(delarProductSearchResult)
}

const deSelectProduct = (delarProductSearchResult: DelarProductSearchResult) => {
    AddProductStore.deSelectedProduct(delarProductSearchResult)
}

const updateSelectProduct = (delarProductSearchResult: DelarProductSearchResult) => {
    AddProductStore.updateSelectProduct(delarProductSearchResult)
}

const saveData = () => {
    const uid = SecurityManager.getUid();
    const delarProductDtoList = AddProductStore.getSelectedProductList()?.map(selectedProduct => {
        return {
            delarId: uid,
            productId: selectedProduct.id,
            type: 0,
            status: 1,
            enabled: true,
            removed: false,
            viewCount: 0,
            shareCount: 0,
            active: true
        } as DelarProductDto
    })

    ProductService.addDelarProductList(delarProductDtoList).then((res) => {
        AddProductStore.upadteAddedProductList(res.data)
    }).catch(err => {
            console.log(err)
    })
}

const updateSearchText = (searchText: string, afterDataFetch: any) => {
    initSearchDtoAndProductList()
    AddProductStore.updateSearchText(searchText)
    searchForProduct(afterDataFetch)
}

const updateCompany  = (companies: Array<ShopCompany>) => {
    AddProductStore.reSetProductList()
    AddProductStore.upadteFilterCompaniesList(companies)
}

const removeFilterCompany  = (company: ShopCompany) => {
    AddProductStore.reSetProductList()
    AddProductStore.removeFilterCompany(company)
}

const savePackData = (data: DelarProductPackDto, afterPackUpdate: any) => {
    ProductService.addDelarProductPack(data).then(res => {
        afterPackUpdate()
    }).catch(err => {
        console.log(err)
    })
}

const initSearchDtoAndProductList = () => {
    AddProductStore.reSetSearchDtoAndProductList()
}

export const AddProductActor = (action: any, data: any) => {
    switch (action.type) {
        case AddProductAction.INIT_SEARCH_DTO_AND_PRODUCT_LIST:
            initSearchDtoAndProductList()
            break;
        case AddProductAction.GET_LIST:
            searchForProduct(data.afterDataFetch, data.setHasMore)
            break;
        case AddProductAction.UPDATE_FILTER_COMPANY:
            updateCompany(data.companies)
            break;
        case AddProductAction.UPDATE_SEARCH_TEXT:
            updateSearchText(data.searchText, data.afterDataFetch)
            break;
        case AddProductAction.ADD_DELAR_PRODUCT_DTO:
            selectProduct(data.selectedProduct)
            break;
        case AddProductAction.DELETE_DELAR_PRODUCT_DTO:
            deSelectProduct(data.selectedProduct)
            break;
        case AddProductAction.UPDATE_DELAR_PRODUCT_DTO:
            updateSelectProduct(data.selectedProduct)
            break;
        case AddProductAction.SAVE_DATA:
            saveData()
            break;
        case AddProductAction.UPDATE_PACK_DATA:
            savePackData(data.delarPackDto, data.afterPackUpdate)
            break;
        case AddProductAction.REMOVE_FILTER_COMPANY:
            removeFilterCompany(data.company)
            break;
        default:
            break;
    }
}