import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        backIcon: {
            color: '#fff',
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        img: {
            position: 'relative',
            height: 360,
            display: 'block',
            overflow: 'hidden',
            width: '100%',
            zIndex: 0.8,
        },
        iconHolder: {
           position: 'absolute',
           width: 40,
           height: 40,
           borderRadius: 25, 
           boxShadow: '0px 1px 4px #00000029',
           right: 16,
           top: 16,
           zIndex: 1,
           background: '#FFF',
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center',
        },
        graphIcon: {
            height: 20,
            width: 20,
            marginLeft: 16,
            marginTop: 12,
        },
        title1: {
            marginLeft: 5,
            marginTop: 17,
            fontSize: 12,
        },
        title2: {
            marginLeft: 15,
            marginTop: -6,
            fontSize: 14,
            fontWeight: 600,
        },
        title3: {
            marginLeft: 15,
            fontSize: 14,
            marginBottom: 12,
            marginTop: -2,
        },
        title4: {
            marginTop: -2,
            marginLeft: 15,
            maxLines: 10,
            fontSize: 14,
            marginRight: 16,
        },
        packTitle: {
            marginLeft: 16,
            fontSize: 14,
            fontWeight: 600,
            marginTop: 8,
            marginBottom: 8,
        },
        listItemText: {
            marginLeft: 'auto',
            fontSize: 14,
        },
        stockText: {
            marginLeft: 16,
            fontSize: 12,
            color: "#1CCB2D",
            marginTop: 8,
            marginBottom: 8,
        },
        stockText2: {
            marginLeft: 16,
            fontSize: 12,
            color: "#E6582D",
            marginTop: 8,
            marginBottom: 8
        },
        bottomTextButton: {
            display:"flex", 
            width:"100%",
            height:56, 
            borderBottom: '1px solid #ccc',
            marginTop: -45,
            alignItems: "baseline"
        },
        bottomText: {
            marginLeft:16,
            maxLines:2,
            fontSize: 14,
        },
        manageButton: {
            width:164, 
            height: 40,
            marginLeft:21, 
            marginRight: 12,
            borderRadius: 30,
            textTransform: 'capitalize',
            fontSize:12,
            boxShadow: 'none',
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            opacity: 0.5,
        },
        packSizeText: {
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 8,
            position: "relative",
            fontSize: 12,
            fontWeight: 10,
            color: '#000000',
        }
    }),
);