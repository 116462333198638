import { Checkbox, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core"
import React from "react";
import { AddProductAction } from "../../../../action/product/add-product.action";
import { AddProductActor } from "../../../../actor/product/add-product.actor";
import { Media } from "../../../../model/media.model";
import { DelarProductSearchResult } from "../../../../service/response/product.response";
import { useStyles } from "./add-product.style";

const ProductItemViewBase: React.FC<{product: DelarProductSearchResult}> = ({product}) => {
    const classes = useStyles()

    const labelId = `${product.id}`
    const photo = new Map(Object.entries(product.photos)).values().next().value as Media

    const handleItemClick = (event: any, product: DelarProductSearchResult) => {
        if (product.added == false) {
            AddProductActor({ "type": AddProductAction.UPDATE_DELAR_PRODUCT_DTO }, { selectedProduct: product })
        }
    };

    const handleToggle = (event: any, product: DelarProductSearchResult) => {
        if (event.target.checked) {
            AddProductActor({ "type": AddProductAction.ADD_DELAR_PRODUCT_DTO }, { selectedProduct: product })
        } else {
            AddProductActor({ "type": AddProductAction.DELETE_DELAR_PRODUCT_DTO }, { selectedProduct: product })
        }
    };
    
    return (
        <React.Fragment key={product.id}>
            <ListItem button onClick={(e: any) => handleItemClick(e, product)}>
                <ListItemAvatar>
                    <img
                        alt={`Avatar ${product.name}`}
                        src={new Map(Object.entries(photo.urls)).get('main') as string}
                        className={classes.productImage}
                    />
                </ListItemAvatar>
                <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.productName}>
                    <b>{product.name}</b>
                </Typography>}
                    secondary={<Typography variant="subtitle1" className={classes.productDescription}>
                        {product.company.name}
                    </Typography>} />
                <ListItemSecondaryAction>
                    {product.added == false && (
                        <Checkbox
                            edge="end"
                            onChange={(e: any) => { handleToggle(e, product) }}
                            checked={product.itemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            style={{ marginRight: -8, }}
                        />
                    )}

                    {product.added && (
                        <Checkbox
                            edge="end"
                            checked={product.added}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            style={{ marginRight: -8, }}
                        />
                    )}
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </React.Fragment>
    );
}

export const ProductItemView = React.memo(ProductItemViewBase);