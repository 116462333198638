import Axios, { AxiosResponse } from "axios";
import { GenerateOTPDto, ValidateOTPDto } from "./dto/registration.dto"
import { GenerateOTPRes, ValidateOTPRes } from "./response/registration.response";

export const RegistrationService = {
    generateOtp: (generateOtpDto: GenerateOTPDto): Promise<AxiosResponse<GenerateOTPRes>> => {
        return Axios.post<GenerateOTPRes>(`/delar/otp/send`, generateOtpDto)
    },
    validateOtp: (validateOTPDto: ValidateOTPDto): Promise<AxiosResponse<ValidateOTPRes>> => {
        return Axios.post(`/delar/otp/validate`, validateOTPDto)
    },
    checkNetwork: () => {
        return Axios.get(`https://api.khetipoint.com/v1/ping`)
    }
}