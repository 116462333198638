import { AppBar, Avatar, FormControl, IconButton, Input, InputAdornment, InputLabel, Modal, Toolbar, Typography } from "@material-ui/core"
import { useState } from "react"
import clsx from 'clsx';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './edit-info.style'
import { useObservable } from "../../../../../useSubscriber"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../../../router/route.manager"
import LoadingComponent from "../../../../common/loading/loading.component"
import React from "react"
import { DelarStore } from "../../../../../store/delar.store"
import { Visibility, VisibilityOff } from "@material-ui/icons"

export const EditInfoComponent = (props: any) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager()
    const [loading, setLoading] = useState(false)

    const [delarInfo, _] = useObservable(DelarStore.getDelarInfo(), DelarStore.getDelarInfoObservable())

    return (

        <div className={classes.root}>
            {!loading && (
                <>
                    <AppBar position="sticky">
                        <Toolbar>
                            <ArrowBackIcon
                                onClick={() => routeManager.goBack()} className={classes.backIcon} />
                            <Typography variant="h6" className={classes.title}>
                                {t("profile_business_details")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {delarInfo?.shopAddress && (
                        <React.Fragment>
                            <div style={{ display: "flex" }} onClick={() => { routeManager.openEditAddress() }}>
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.shopAddress.landMark}, {delarInfo.shopAddress.streetLine}, {delarInfo.shopAddress.geoAddress.district.name}, {delarInfo.shopAddress.geoAddress.state.name}
                                </Typography>
                                <Avatar alt="editIcon" src={'../asset/locationIcon.svg'} className={classes.icon} />
                            </div>
                        </React.Fragment>
                    )}
                    {delarInfo?.pesticideLiscence?.licence?.number && delarInfo?.pesticideLiscence?.licence?.number.length > 0 && (
                        <React.Fragment>
                            <div style={{ display: "flex" }} onClick={() => { routeManager.openEditPesticideLicense() }}>
                                <img src={'/asset/pest-observation.svg'} alt={'pesticide icon'} style={{ marginLeft: 16, marginTop: 9, width: 17, height: 17, }} />
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#FFCC00', }} noWrap>
                                    {delarInfo.pesticideLiscence.licence.number}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}
                    {delarInfo?.fertilizerLiscence?.licence?.number && delarInfo?.fertilizerLiscence?.licence?.number.length > 0 && (
                        <React.Fragment>
                            <div style={{ display: "flex" }} onClick={() => { routeManager.openEditFertilizerLicense() }}>
                                <img src={'/asset/fertilizer icon.svg'} alt={'fertilizer icon'} style={{ marginLeft: 16, marginTop: 9, width: 17, height: 17, }} />
                                <Typography variant="subtitle1" className={classes.title3} noWrap>
                                    {delarInfo.fertilizerLiscence.licence.number}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}
                    {delarInfo?.fertilizerLiscence?.licence?.number && delarInfo?.fertilizerLiscence?.licence?.number.length > 0 && (
                        <React.Fragment>
                            <div style={{ display: "flex" }} onClick={() => { routeManager.openEditSeedLicense() }}>
                                <img src={'/asset/seed icon.svg'} alt={'seed icon'} style={{ marginLeft: 16, marginTop: 9, width: 17, height: 17, }} />
                                <Typography variant="subtitle1" className={classes.title3} style={{ color: '#1078FF', }} noWrap>
                                    {delarInfo?.seedLiscence?.licence?.number.length === 0 && t("profile_add_seed_license")}
                                    {delarInfo?.seedLiscence?.licence?.number.length !== 0 && delarInfo.seedLiscence.licence.number}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}
                </>
            )}
            <Modal open={loading} className={classes.modal}><LoadingComponent /></Modal>
        </div>
    )
}