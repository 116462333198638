import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            maxWidth:720,
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            justifyContent: 'center',
        },
        imageHolder: {
            width: "70%",
            margin: 'auto',
        },
        image: {
            width: '100%',
            height: '100%',
        },
        description: {
            width: 244,
            height: 67,
            margin: 'auto',
            textAlign: 'center',
            fontSize: '1.1428571428571428em',
            
            fontWeight: 700,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '1em',
            justifyContent: 'space-between',
            padding: 10,
        },
        button: {
            width: '45%',
            color:'#0e4bb4',
            borderColor: '#0e4bb4',
            height: 38,
            textTransform: "none",
            fontSize: '0.9285714285714286em',
            borderRadius: 30,
        },
        modal: {
            display: 'flex', 
            flexDirection: 'column', 
            verticalAlign: 'middle', 
            justifyContent: 'center',
            outline: 'none',
            backgroundColor: 'transparent',
            opacity: 0.5,
        },
    })
)