import { AppBar, Avatar, BottomNavigation, Button, Container, Divider, GridList, GridListTile, IconButton, TextField, Toolbar, Typography,} from "@material-ui/core"
import React from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './upload-picture.style'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from "react-i18next"
const imageList = [
    {
        id: 1,
        title: 'image1',
        img: '../../asset/imageSample.png',
    },
    {
        id: 2,
        title: 'image2',
        img: '../../asset/imageSample.png',
    },
    {
        id: 3,
        title: 'image3',
        img: '../../asset/imageSample.png',
    },
    {
        id: 4,
        title: 'image4',
        img: '../../asset/imageSample.png',
    },
    {
        id: 5,
        title: 'image5',
        img: '../../asset/imageSample.png',
    },
    {
        id: 6,
        title: 'image6',
        img: '../../asset/imageSample.png',
    },
    {
        id: 7,
        title: 'image7',
        img: '../../asset/imageSample.png',
    },
    {
        id: 8,
        title: 'image8',
        img: '../../asset/imageSample.png',
    },
]

export const UploadPictureComponent = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("upload_picture")}
                    </Typography>
                    <Avatar alt={'cameraIcon'} src={'/asset/Camera Icon 2.svg'} style={{alignSelf: 'right', justifySelf: 'right', maxWidth:26, maxHeight:26}} />
                </Toolbar>
            </AppBar>
            <div style={{display:"flex"}}>
                <Typography variant="subtitle1" className={classes.title1}>
                    <b>{t("gallery")}</b>
                </Typography>
                <IconButton style={{marginTop:13, }}>
                    <ExpandMoreIcon/>
                </IconButton>
            </div>
            <div style={{paddingLeft: 16, paddingRight: 16,}}>
            <GridList cellHeight={78} className={classes.gridList} cols={4}>
                {imageList.map((content) => (
                <GridListTile key={content.id} >
                    <img src={content.img} alt={content.title} style={{marginRight:12, marginBottom: 8,}} />
                </GridListTile>
                ))}
            </GridList>
            </div>
            
            <BottomNavigation
                showLabels
                className={classes.bottomNavigation}
            >
                <Button variant="contained" color="primary" className={classes.requestButton}>
                    {t("upload_picture")}
                </Button>
            </BottomNavigation>
        </div>
    )
}